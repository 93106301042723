import React, { useState, useEffect } from 'react';
import './Pricing.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [currency, setCurrency] = useState('INR');
  const [conversionRates, setConversionRates] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate function
  
 

  const handleLoginClick = () => {
    navigate('/Login1'); // Navigate to /login route
  };

  //const handleBuyClick = () => {
  //  window.location.href = 'https://paymentback-mmlm.onrender.com/';
  //};
 

  useEffect(() => {
    const fetchConversionRates = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/INR');
        setConversionRates(response.data.rates);
      } catch (error) {
        console.error("Error fetching conversion rates", error);
        setError("Unable to fetch currency conversion rates.");
      }
    };

    fetchConversionRates();
  }, []);

  const togglePricing = (pricingType) => {
    setIsMonthly(pricingType);
  };

  const plans = [
    {
      title: "Free",
      subtitle1: "Unlimited Cards",
      monthlyPrice: "30 days Free Trial",
      yearlyPrice: "30 days Free Trial",
      subtitle: "(No credit card required)",
      users: "Freelancers,Sales, Recruiters, Entrepreneurs, Startups",
      features: [
        "Full Customization",
        "Gen AI Keyword Generator",
        "Virtual Sales Engineer - AI Agent",
        "50+ sharable channels",
        "Unlimited Shares",
        "Unlimited Edits",
        "13 social media links",
        "13 tappable social media buttons",
        "16 review links",
        "16 tappable review buttons",
        "8 book a meeting links",
        "8 tappable book a meeting buttons",
        "Add YouTube channel",
        "Add Zoom",
        "Add Teams",
        "8 contact info",
        "Click to call & more",
        "8 tappable contact info buttons",
        "Click to WhatsApp",
        "1 profile image",
        "1 background image",
        "1 QR code",
        "1 sharable link"
      ]
    },
    {
      title: 'Plus',
      subtitle1: "Unlimited Cards",
      monthlyPrice: 170, // INR
      yearlyPrice: 1991, // INR
      subtitle: "(Price less than 100 units of paper/nfc business cards)",
      users: "Freelancers,Sales, Recruiters, Entrepreneurs, Startups",
      features: [
        "Full Customization",
        "Gen AI Keyword Generator",
        "Virtual Sales Engineer - AI Agent",
        "50+ sharable channels",
        "Unlimited Shares",
        "Unlimited Edits",
        "13 social media links",
        "13 tappable social media buttons",
        "16 review links",
        "16 tappable review buttons",
        "8 book a meeting links",
        "8 tappable book a meeting buttons",
        "Add YouTube channel",
        "Add Zoom",
        "Add Teams",
        "8 contact info",
        "Click to call & more",
        "8 tappable contact info buttons",
        "Click to WhatsApp",
        "1 profile image",
        "1 background image",
        "1 QR code",
        "1 sharable link"
      ]
    },
    {
      title: 'Pro',
      subtitle1: "Unlimited Cards",
      monthlyPrice: 270, // INR
      yearlyPrice: 2993, // INR
      subtitle: "(Price less than 200 units of paper/nfc business cards)",
      users: "Freelancers, Entrepreneurs, Sales, Recruiters, Lawyers, Startups, SMEs & Enterprises",
      features: [
        "Full Customization",
        "Gen AI Keyword Generator",
        "Virtual Sales Engineer - AI Agent",
        "50+ sharable channels",
        "Unlimited Shares",
        "Unlimited Edits",
        "13 social media links",
        "13 tappable social media buttons",
        "16 review links",
        "16 tappable review buttons",
        "8 book a meeting links",
        "8 tappable book a meeting buttons",
        "Add YouTube channel",
        "Add Zoom",
        "Add Teams",
        "8 contact info",
        "Click to call & more",
        "8 tappable contact info buttons",
        "Click to WhatsApp",
        "1 profile image",
        "1 background image",
        "1 QR code",
        "1 sharable link"
      ]
    },
    {
      title: 'Premium',
      subtitle1: "Unlimited Cards",
      monthlyPrice: 370, // INR
      yearlyPrice: 3990, // INR
      subtitle: "(Price less than 300 units of paper/nfc business cards.)",
      users: "Startups,Sales, Recruiters, Lawyers, SMEs & Enterprises",
      features: [
        "Full Customization",
        "Gen AI Keyword Generator",
        "Virtual Sales Engineer - AI Agent",
        "50+ sharable channels",
        "Unlimited Shares",
        "Unlimited Edits",
        "13 social media links",
        "13 tappable social media buttons",
        "16 review links",
        "16 tappable review buttons",
        "8 book a meeting links",
        "8 tappable book a meeting buttons",
        "Add YouTube channel",
        "Add Zoom",
        "Add Teams",
        "8 contact info",
        "Click to call & more",
        "8 tappable contact info buttons",
        "Click to WhatsApp",
        "1 profile image",
        "1 background image",
        "1 QR code",
        "1 sharable link"
      ]
    }
  ];

  const convertPrice = (price, currency) => {
    if (currency === 'INR') return price.toFixed(2); // No conversion needed for INR
    if (!conversionRates[currency]) return price.toFixed(2); // If no rate available, return INR price

    const convertedPrice = (price * conversionRates[currency]).toFixed(2);
    return convertedPrice;
  };

  const shouldHighlightFeature = (feature, planTitle) => {
    const highlightFeatures = {
      Free: ["Virtual Sales Engineer - AI Agent"],
      Plus: [
        "Virtual Sales Engineer - AI Agent",
        "8 tappable book a meeting buttons",
        "Add Zoom",
        "Add Teams"
      ],
      Pro: ["Virtual Sales Engineer - AI Agent"]
    };

    return highlightFeatures[planTitle]?.includes(feature) || false;
  };

  return (
    <div className="pricing-container">
      <div className="pricing-toggle">
        <div
          className={`toggle-option ${isMonthly ? 'active' : ''}`}
          onClick={() => togglePricing(true)}
          role="button"
          tabIndex={0}
          aria-label="Switch to monthly pricing"
        >
          Monthly
        </div>
        <div
          className={`toggle-option ${!isMonthly ? 'active' : ''}`}
          onClick={() => togglePricing(false)}
          role="button"
          tabIndex={0}
          aria-label="Switch to yearly pricing"
        >
          Yearly
        </div>
      </div>

      <div className="currency-selector">
        <label htmlFor="currency">Select Currency: </label>
        <select
          id="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <option value="INR">INR</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="JPY">JPY</option>
          <option value="SEK">SEK</option>
          <option value="KWD">KWD</option>
          <option value="AED">AED</option>
          <option value="AUD">AUD</option>
          <option value="SGD">SGD</option>
        </select>
      </div>

      <h1 className="pricing-heading">Our Pricing Plans</h1>

      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="pricing-cards">
          {plans.map((plan) => (
            <div key={plan.title} className="pricing-card">
              <h2>{plan.title}</h2>
              <p className="plan-users">{plan.users}</p>
              <div className="subtitle-container">
                <p className="subtitle1">{plan.subtitle1}</p> {/* Display subtitle1 */}
                {plan.title === "Free" ? (
                  <button className="login-button" onClick={handleLoginClick}>
                    Login
                  </button>
                ) : plan.title === "Plus" ? (
                  <button className="buy-button"  >
                      {/* onClick={handleBuyClick} */}
                    Buy
                  </button>
                ) : (
                  <button className="coming-soon-button">
                    Coming Soon
                  </button>
                )}

              </div>
              <div className="pricing-details">
                {plan.title === "Free" ? (
                  <p className="price">{plan.monthlyPrice}</p>
                ) : (
                  <>
                    <p className="price">
                      {currency} {isMonthly ? convertPrice(plan.monthlyPrice, currency) : convertPrice(plan.yearlyPrice, currency)}
                    </p>
                    <p className="billing-period">
                      {isMonthly ? '/ month / user' : '/ year / user'}
                    </p>
                  </>
                )}
              </div>
              <p className="subtitle">{plan.subtitle}</p>
              <ul>
                {plan.features.map((feature, index) => (
                  <li
                    key={index}
                    className={`feature-item ${shouldHighlightFeature(feature, plan.title) ? 'highlighted-feature2' : ''}`}
                  >
                    <span className="checkmark">✓</span> {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      
    </div>
  );
};

export default Pricing;




























