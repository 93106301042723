import React ,{useEffect } from 'react';
import { getcontactDataURL } from '../../../../../../../routes.mjs';
import './background6.scss'; // Import the CSS file
import { useParams } from 'react-router-dom';

const QrImage1 = ({ username: propUsername, qrImage, isVisible }) => {
    const { username: paramUsername } = useParams(); // Extract username from URL params

       // Choose username from props if available, otherwise from params
       const username = propUsername || paramUsername;

       useEffect(() => {
        const fetchData = async () => {
          try {
            const contactData = await getFunction(username);
            localStorage.setItem('contactData', JSON.stringify({
                name: contactData.name,
                email2: contactData.email2,
                phone: contactData.phone,
                title: contactData.title,
                company: contactData.company,
                bio: contactData.bio,
                whatsapp: contactData.whatsapp,
                web: contactData.web,
                address: contactData.address
              }));
          } catch (error) {
            // Handle errors
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData(); // Call the async function immediately
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); // Empty dependency array means this effect runs once after the initial render
      

  const handleDownloadVCF = async() => {

    const contactData = localStorage.getItem('contactData');
    const contactDataJSON = JSON.parse(contactData);
    // Generate the content for the .vcf file
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
FN:${contactDataJSON.name}
TITLE:${contactDataJSON.title}
ORG:${contactDataJSON.company}
ADR:${contactDataJSON.address}
URL:${contactDataJSON.web}
TEL:${contactDataJSON.phone}
TEL;TYPE=WHATSAPP:${contactDataJSON.whatsapp}
EMAIL:${contactDataJSON.email2}
NOTE:${contactDataJSON.bio}
END:VCARD`;


    // Create a Blob with the .vcf content
    const blob = new Blob([vcfContent], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);

    // Create an anchor element and trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${contactDataJSON.name}.vcf`; // Set the filename
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
};
  


  const getFunction = async (username) => {
    try {
        if (!username) {
            console.log("User is not logged in.");
            return;
        }

        console.log(`Logged in user: ${username}`);

        // Construct the URL with the username as a parameter
        const url = `${getcontactDataURL}?username=${encodeURIComponent(username)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'contact': 'true'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        const name = jsonResponse.name;
        const email2 = jsonResponse.email2;
        const phone = jsonResponse.phone;
        const bio = jsonResponse.bio;
        const company = jsonResponse.company;
        const title = jsonResponse.title;
        const web = jsonResponse.web;
        const whatsapp = jsonResponse.whatsapp;
        const address = jsonResponse.address;
       
        
        return { name, email2, phone , bio, company, title, web, whatsapp, address};
    } catch (error) {
        console.error('An error occurred:', error);
        // Handle the error here
        return null; // Or throw the error further
    }
};

    return (
        <div   style={{ marginTop:'80px' }}>
            

            {/* Conditionally render the qrImage image */}
            {!isVisible &&  (
                <button
                src={qrImage}
                className='buttonsave'
                onClick={handleDownloadVCF}
               
            >
                Save Contact
            </button>
            )}

            

      </div>
    );
};

export default QrImage1;