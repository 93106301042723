import React, { useEffect,  useState  } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import styled  from 'styled-components';
//import PreviewWebsiteClientView from './pricecalc/price';
import PreviewWebsiteClient from './products/productsoft/smartconsole/smartconsoleweb/myprofile/channels/mobileview/allproducts1';
import Bookme from './book/book1';
import Smartscan2 from './products/productsoft/smartscan/product1-smartscan/description/smartscan1';
//import MobileViewAdmin1 from './products/productsoft/smartconsole/smartconsoleweb/myprofile/channels/mobileview/mobileviewadmin1';
import Pricing from './Pricing/Pricing';
import Features from './Features/Features';
import Usecases from './Usecases/Usecases';
import Signup from './signup2/Signup1';
import Login from './login2/Login1';
import ResetPasswordPage from './login2/Reset';
import Newpass from './login2/Newpass';
import SmartConsole1 from './products/productsoft/smartconsole/smartconsoleweb/smartconsole1';
import { UserProvider} from './usercontext';
import './Styles/AnimatedLetters.css';
//import { StyleSheetManager } from 'styled-components';
import {createGlobalStyle, keyframes } from 'styled-components';
import { baseURLweb, baseURL } from './routes.mjs';
import { imageDb } from './firebaseconfig';  // Adjust path if necessary
import { ref, getDownloadURL } from 'firebase/storage';
import './App.css'; // Import your CSS file for styling
import contactboard from './17.png';
import socialboard from './18.png';
import reviewboard from './19.png';
import bookameetingboard from './20.png';
import keywordsboard from './21.png';
import contentboard from './22.png';
import loginboard from './login.png';

//const shouldForwardProp = (prop) => !['isVisible'].includes(prop);




// GlobalStyle to remove horizontal scrollbar
const GlobalStyle = createGlobalStyle`
  body {
 
    overflow-y: auto; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Disable horizontal scrollbar */
  }
`;

const Navbar = styled.nav`
  background-color: transparent;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Ensure the navbar appears on top of the video */
`;


const NavList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;

  /* Remove underline from anchor elements */
  a {
    text-decoration: none;
  }
`;

const NavItem = styled.li`
  margin-left: 1cm;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;


const RightNavList = styled(NavList)`
  margin-left: auto;
  white-space: nowrap;

`;

const RightNavItem = styled(NavItem)`
  margin-left: 3vw;
`;

const MobileMenuIcon = styled.div`
   display: none;
   cursor: pointer;
  
  span {
    background-color: rgba(0, 0, 0, 0.3); /* White with 80% transparency */
    padding: 2px; /* Adjust the padding as needed */
     border-radius: 5px;  /* Set border-radius to 0 for a square shape */
    display: inline-block; /* Ensure that border-radius is applied */

     height: 30px; /* Set the desired height */
     width: 20px; /* Set the desired width */
    
  }
  @media screen and (max-width: 600px) {
    display: block;
 }
 `;

const MobileMenu = styled.div`
  position: absolute;
  top: 60px; /* Adjust as needed based on your design */
  right: 20px;
  
  background-color: rgba(255, 255, 255, 0.9); /* 50% opacity white */
  border-radius:12px;
  padding: 10px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 100;
  `;



// const ContentWrapper = styled.div`
//   position: relative;
//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 99.5%;
//     background-color: rgba(0, 0, 0, 0.15); /* Adjust the alpha value for transparency */
//     z-index: 1; /* Place the filter above the video but below other content */
//   }
// `;

  

const BlueButton = styled.button`
  position: relative;
  overflow: hidden;
 background-color: rgba(255, 255, 255, 0.1); /* 50% opacity white */

  color: white;
  border: 3px solid white;
  padding: 10px 30px;
  margin-right: 6px;
  top: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  border-color:white;
  white-space: nowrap;
  transition: color 0.3s ease, background-color 0.3s ease;

  
`;



const BlueButton3 = styled.button`
  position: relative;
  overflow: hidden;
 background-color: rgba(255, 255, 255, 0.1); /* 50% opacity white */

  color: white;
  border: 3px solid white;
  padding: 10px 30px;
 left: 56%;
  top: 41%;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  border-color:white;
  white-space: nowrap;
  transition: color 0.3s ease, background-color 0.3s ease;

  
`;


const BlueButton2 = styled.button`
  position: relative;
  overflow: hidden;
  background-color: white;
  color: black;
  border: 3px solid white;
  padding: 10px 40px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  border-color:black;
  white-space: nowrap;
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }


  
  
`;


//homecontent 



const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 25% 75%;
  }
  20% {
    background-position: 50% 25%;
  }
  25% {
    background-position: 75% 50%;
  }
  10% {
    background-position: 100% 100%;
  }
`;










 const ContentContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 text-align: center;
 color: white;
 
 width: 100%;
 box-sizing: border-box;

margin-bottom:5vh;
 `;


 const CardContainer1 = styled.div`
  
 align-items: center;
 justify-content: center;
 min-height: calc(130vh - 5cm);
  background: linear-gradient(-65deg, #fa84ea,#c317fc,#fa4db2,#ff82f3);
 background-size: 150% 150%;
 animation: ${gradientAnimation} 35s ease infinite;
display: flex;
flex-direction: column;

@media (min-width: 600px) {
  flex-direction: row;
  justify-content: space-between; // Ensure right content stays on the right
}


`;
 



 const CardContainerwhite = styled.div`
  
 align-items: center;
 justify-content: center;
 min-height: calc(130vh - 5cm);
 background: white;
 background-size: 150% 150%;
 
display: flex;
flex-direction: column;

@media (min-width: 600px) {
  flex-direction: row;
  justify-content: space-between; // Ensure right content stays on the right
}


`;



 

 
 const LeftContent = styled.div`
 flex: 1;
 @media (min-width: 600px) {
   margin-right: 20px;
   margin-bottom: 0;
 }
 `;
 
 const RightContent = styled.div`
 flex: 1;
 margin-top: 10px; // Add margin-top to create space between LeftContent and RightContent
 
 @media (max-width: 600px) {
   margin-top: 0; // Remove margin-top for smaller screens
 }
 `;
 


const HomeContent = () => {

 


  


   useEffect(() => {
    // Create a reference to the file
    const storageRef = ref(imageDb, 'Files/Webvideo/keywords.mp4');

    // Get the download URL
    getDownloadURL(storageRef).then((url) => {
      console.log("File URL: ", url);
      const videoElement = document.getElementById('myVideo');
      if (videoElement) {
        videoElement.src = url;
      }
    }).catch((error) => {
      console.error("Error fetching the file: ", error);
    });
  }, []);


  useEffect(() => {
    // Create a reference to the file
    const storageRef = ref(imageDb, 'Files/Webvideo/tour.mp4');

    // Get the download URL
    getDownloadURL(storageRef).then((url) => {
      console.log("File URL: ", url);
      const videoElement = document.getElementById('myTour');
      if (videoElement) {
        videoElement.src = url;
      }
    }).catch((error) => {
      console.error("Error fetching the file: ", error);
    });
  }, []);
  

  const username = 'JudeMathew'; // Assign JudeMathew to a variable

  useEffect(() => {
    const fetchData = async (username) => {
      try {
        const response = await fetch(`${baseURL}/updatelocal?username=${username}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'backcolor': 'true'
          }
        });
        const data = await response.json();
  
   
  
          localStorage.setItem('contactData', JSON.stringify({
            name: data.name,
            email2: data.email2,
            phone: data.phone,
            title: data.title,
            company: data.company,
            bio: data.bio,
            whatsapp: data.whatsapp,
            web: data.web,
            address: data.address
          }));
        
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };
  
    fetchData(username); // Fetch data on mount or when username changes
  }, [username]); // Ensure useEffect runs only when username changes


  // Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  padding: '20px',
  backgroundColor: '#2c3e50',
};



const footerStyle = {
  color: 'white',
  letterSpacing: '1px',
  fontSize: '1rem',
  marginTop: '40px',
  textAlign: 'center',
};

// Modal Component
const Modal = ({ title, content, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="modal">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{title}</h2>
        <div>{content}</div>
      </div>
    </div>
  );
};

const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (title) => {
    setModalTitle(title);

    switch (title) {
      case 'Terms and Conditions':
        setModalContent(
          <>
           
            <h4>Introduction</h4>
            <p>These Terms of Use (“Terms”), constitute a legally binding agreement between you (or the corporation, entity, organization or authority that you represent) and Fastighets Cybr Systems Pvt Ltd. Your use of this website, https://www.smartcardai.com, along with all associated websites, domains and subdomains of SmartCard AI (“Website”), IS GOVERNED BY THE FOLLOWING TERMS, AS UPDATED FROM TIME TO TIME.

We offer a wide range of products and items (“Item(s)”) and services (“Services”) for sale on the Website, and sometimes additional terms may apply, in which case you will be notified of such additional terms.

Please also review our Privacy Policy to understand how we collect, use, disclose, and secure personal information, as well as any rights that you may have.

By using this Website, you agree to these Terms. Please read them carefully.</p>
            <h4>ACCESS AND USE</h4>
            <p>You are responsible and liable for (a) your use of the Website, including unauthorized conduct and any conduct that would violate the requirements of these Terms; and (b) any use of the Website through your account, whether authorized or unauthorized. In addition, you acknowledge the following:

You may have to pay fees to access certain features or Services of the Website, and such fees may not be refundable, whether you purchase Items or not;

You will not access the Website in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the Website, or to copy any ideas, features, functions or graphics of the Website;

You will not engage in web scraping or data scraping on or related to the Website, including collection of information through any software that simulates human activity or any bot or web crawler;

In your use of the Website, you will comply with all applicable laws, including privacy and IP laws;

You will not use the Website for any unlawful purpose or in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use and enjoyment of the Website. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Website; and

You will not transmit to the Website any content that you do not have a right to transmit under any law or under a contractual relationship, any content that infringes upon the IP rights of any party, any unsolicited or unauthorized advertising, promotional materials, spam, or any other form of solicitation.


Violation of any of the above provisions may result in the immediate termination of your account and/or access to the Website. SmartCard AI reserves the right to investigate and take appropriate legal action against anyone who, in SmartCard AI's sole discretion, violates these Terms, including reporting you to law enforcement authorities.</p>
          
          
<h4>THIRD-PARTY LINKS</h4>
<p>This Website may include links to third-party websites (“Linked Sites”) that are not affiliated with SmartCard AI. These Linked Sites are provided for your convenience only. SmartCard AI has no control over the content of Linked Sites and is not responsible for the materials or information found on these sites, or your engagement with those sites. You should carefully review the terms of use and privacy policies of any third-party websites you visit.

You acknowledge and agree that SmartCard AI provides access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. SmartCard AI shall have no liability whatsoever arising from or relating to your use of optional third-party tools.

Any use by you of optional tools offered through the Website is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).

SmartCard AI may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</p>          
          
           
<h4>MODIFICATIONS TO THE WEBSITE</h4>
<p>SmartCard AI has the right to make changes or remove the Website, as well as any of the Items or content provided on it, without advance notice. If our changes materially affect the Services we provide you, we will update these Terms. If the Website is unavailable for any reason, SmartCard AI shall not be liable for any resulting effects on you. Occasionally, SmartCard AI may prevent certain people from accessing parts of the Website or the entire Website.</p>          


    <h4>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h4>
<p>SmartCard AI is not responsible if information made available on this Website is not accurate, complete or current. The material on this Website is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this Website is at your own risk.

The Website may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. SmartCard AI reserves the right to modify the contents of this site at any time, but SmartCard AI has no obligation to update any information on this Website. You agree that it is your responsibility to monitor changes to the Website.</p>          


     <h4>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h4>
<p>If, at SmartCard AI’s request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, “Comments”), you agree that SmartCard AI may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any Comments that you forward to SmartCard AI. SmartCard AI is and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay compensation for any Comments; or (3) to respond to any Comments.

SmartCard AI may, but have no obligation to, monitor, edit or remove content that we determine in SmartCard AI’s sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.

You agree that your Comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your Comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any Comments. You are solely responsible for any Comments you make and their accuracy. SmartCard AI takes no responsibility and assume no liability for any Comments posted by you or any third-party.</p>          
         
      
     <h4>INTELLECTUAL PROPERTY (IP)</h4>
<p>You understand and agree that the Website and its entire content, features, and functionalities, including all information, software, code, data text, displays, graphics, photographs, images, video, audio, music, broadcast, design, presentation, website layout, selection, and arrangement are owned by SmartCard AI, its licensors, or other content or products providers or vendors of such material, and are protected in all forms by IP laws including copyright, trademark, patent, trade secret, and any other proprietary rights. Accordingly:

SmartCard AI retains all rights, title, and interest in and to the Website, including all software used to provide the Website and all graphics, user interfaces, logos, and trademarks reproduced through the Website. SmartCard AI’ name, the SmartCard AI trademark, the SmartCard AI logo, and all related names, logos, product and service names, designs, images, and slogans are trademarks (“Marks”) of SmartCard AI or its affiliates or licensors.

You must not use the Marks without the prior written permission of SmartCard AI.

These Terms do not grant you any IP license or rights in or to the Website, the Marks, or any of its components, except to the limited extent that such rights are necessary for your use of the Website as specifically authorized by these Terms.

SmartCard AI may not own IP rights in other names, trademarks, logos, product and service names, designs, images, slogans and vendor’s materials that may appear on the Website, but in such case, SmartCard AI has obtained permission from such third-parties to use those materials and images on the Website. YOU MUST NOT USE ANY OF THIS PROPERTY WITHOUT THE EXPRESS WRITTEN PERMISSION OF SMARTCARD AI. If you do, it shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws, and could result in legal action against you.


You will not upload and/or transmit content onto the Website for which you would be infringing on IP rights or do anything that violates someone else's IP rights. SmartCard AI is not responsible or liable for any IP rights infringement caused by you. In using the Website, you agree that you possess all the necessary IP rights for processing, uploading or transmitting content to the Website.</p>          
         
<h4>YOUR ACCOUNT</h4>
<p> To use certain features or Services of the Website, you may have to create an account. You agree that (a) all required registration information you submit for account creation is truthful and accurate; and (b) you will maintain the accuracy of such information. You may delete your account at any time, for any reason, by sending us a mail at support@smartcardai.com.

It is your responsibility to keep your account information confidential. If you don’t, you may be held responsible for any activity that occurs under your account, including any purchases. You must notify Smartcard AI immediately if you suspect any unauthorized use of your account or any other security breach. IF YOU FAIL TO MAINTAIN THE CONFIDENTIALITY OF YOUR ACCOUNT, YOU MAY BE HELD LIABLE FOR ANY LOSSES INCURRED BY SMARTCARD AI OR OTHER USERS OF THE WEBSITE.

SmartCard AI reserves the right to disable your account access at any time should you use the Services and the Website for any Prohibited Uses.

If you are merely surfing or browsing through the Website and have not yet created an account, your use of the Website is still subject to these Terms.</p>          
         
<h4>PRICING AND PRICE CORRECTIONS</h4>
<p> SmartCard AI has the right to change the prices of displayed Items on the Website at any time and to fix any accidental pricing errors without prior notice. Any such changes will be communicated by posting the new prices on the Website. ADDITIONALLY, SMARTCARD AI RESERVES THE RIGHT TO INTRODUCE ADDITIONAL FEES AT ANY GIVEN TIME, INCLUDING MEMBERSHIP FEES OR SUBSCRIPTION FEES, TO ACCESS CERTAIN FEATURES AND SERVICES OF THE WEBSITE.

The retail price of each Item shown on the Website excludes  applicable taxes, and any other applicable fees, unless otherwise stated. SmartCard AI may offer promotions or special pricing on Items for a limited period.

If paying with an international credit card, the final purchase price may be affected by exchange rates and foreign conversion charges and fees from the bank or credit card issuer.

Any personal information collected by the financing provider(s) is not subject to SmartCard AI' Terms or Privacy Policy once you leave the Website.</p>          


  <h4>PAYMENT METHODS AND TAXES</h4>
<p> SmartCard AI accepts a variety of payment methods, such as credit cards or debit cards.

In accordance with local applicable laws, any online order will be taxed according to the applicable tax rates </p>          
                            
<h4>DISCLAIMERS AND LIMITATIONS OF LIABILITY</h4>
<p>We disclaim all warranties of any kind regarding the Items and Services, including any warranties of merchantability and fitness for particular purpose.

WE ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF YOUR USE OR INABILITY TO USE THE WEBSITE, OR ANY INFORMATION CONTAINED ON THE WEBSITE, INCLUDING USER-GENERATED CONTENT, WHETHER BASED UPON WARRANTY, CONTRACT, TORT, OR OTHERWISE.

Subject to any agreements between yourself and SmartCard AI, we shall not be responsible for delay and/or non-provision of the Services or Items or unavailability of the Website due to events beyond our control such as Internet failures, equipment failures, natural disasters, governmental actions, and pandemics.

You expressly agree that your use of, or inability to use, the service is at your sole risk. The Service and all products and services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.  </p>          


    <h4>PROHIBITED USES</h4>
<p>You are not permitted to use, or encourage, promote, facilitate, instruct or induce others to use, the Services or the Website for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to others; or to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others (the “Prohibited Uses”).

The following are examples of Prohibited Uses that are prohibited on the Website or when using the Services:

seeking, offering, promoting, or endorsing any services, content, or activities that are unlawful or infringe upon any applicable laws;

use the Website for any fraudulent or unlawful purpose including, but not limited to, any purpose resulting in violations of laws, regulations, and orders governing consumer protection, unfair competition, anti-discrimination or false advertising;

fraudulent, unlawful, or misleading uses or content;

making or demanding bribes or other payments without the intention of providing services in exchange for the payment;

conduct or actions that could jeopardize the integrity of or circumvent the Website, Services or SmartCard AI’s proprietary information;

attempting to or actually reverse engineering, modifying, adapting, translating, preparing derivative works from, decompiling, interfering with the operation of, or otherwise attempting to derive source code from any part of the Website or Services unless expressly permitted by applicable law or SmartCard AI; or

accessing or using the Website or Services to build a similar service or application, identify or solicit our users, or publish any performance or any benchmark test or analysis relating to the Website.

introducing software or automated agents or scripts into the Website in order to produce multiple accounts, generate automated searches, requests or queries, or to strip or mine content or data from the Website. </p>          


               <h4>APPLICABLE LAW</h4>
<p>SmartCard AI is located in India . When you access the Website, you do so on your own initiative and are responsible for compliance with local laws.

You agree to comply with all applicable laws, statutes, ordinances, and regulations regarding your use of and your purchase of Items and/or services through SmartCard AI. Any breach of these Terms (or dispute related to them) shall be governed by the laws of India  without regard to any conflict of law provisions. We may report any breaches relating to these Terms to the appropriate authorities. You agree that the courts located in Kerala, India will resolve all disputes related to these Terms, despite any inconvenience to you. </p>          


                    <h4>CHANGES</h4>
<p>We reserve the right to alter or amend these Terms at any time, solely at our discretion. Should any changes or modifications be made to these Terms, we will provide notice of such changes, such as by posting a notice on our Website and/or updating the “Last Update” date above. Your continued usage of the Website following the posting of changes or modifications will confirm your acceptance of such changes or modifications. If you disagree with the amended Terms, you must discontinue using our Website.

Any updated versions of the Terms and/or Privacy Policy will replace all prior versions, notices or statements regarding the Website. If requested, you agree to sign a non-electronic version of these Terms. </p>          


                   <h4>CONTACT US</h4>
<p>If you have any questions about these Terms, please contact us at support@smartcardai.com:



By mail: Fastighets Cybr Systems Private Limited, Poickattusery, Chegamand P.O, Kerala, India. P.O : 683578  

By email: support@smartcardai.com

Should you become aware of misuse of the Website including libellous or defamatory conduct, you must report it to us at support@smartcardai.com.

All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to support@smartcardai.com.

The English text of these Terms constitutes the official text. In the event of any discrepancy between the English text and a translation into a foreign language, the English text shall prevail.</p>          
              
          </>




        );
        break;

      case 'Privacy Policy':
        setModalContent(
          <>
           
            <h4>Overview</h4>
            <p>We at SmartCard AI. Know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy.

By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.

Remember that your use of SmartCard AI’s Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in our Terms of Use.</p>
            <h4>What does this Privacy Policy cover?</h4>
            <p>This Privacy Policy covers our treatment of personally identifiable information (“Personal Information”) that we gather when you are accessing or using our Services, but not the practices of companies we don’t own or control, or people that we don’t manage. We may gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to fulfill your requests for certain products and services, and to analyze how you use the Services. In certain cases, we may also share some Personal Information with third parties, but only as described below.

An organization may enter into an agreement with SmartCard AI to use Services (“Organization”). If you use our Services under such Organization, this Privacy Policy shall extend to you, as well as the Organization.</p>
     
<h4>Age & Eligibility</h4>
            <p>If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian. The Services is not intended for use by children under 16 years of age. If we learn that we have collected personal information through the Services from a child under 16 without the consent of the child’s parent or guardian as required by law, we will delete it.</p>
   
            <h4>Changes to our Privacy Policy?</h4>
            <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Services. If required by law we will also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Services. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Services after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>     
     

            <h4>What Information does SmartCard AI Collect?</h4>
            <p>Information You Provide to Us: We receive and store any information you knowingly provide to us. For example, this includes information you provide when you create your SmartCard AI account, share your SmartCard AI account with another user, or usage information, such as information about how you use the Services and interact with us. It also includes (i) contact information you elect to provide to us such as your email address and your first and last name, (ii) feedback and correspondence, such as information you provide in your responses to surveys, to report a problem with our Services, or otherwise correspond with us, (iii) marketing information, such as your preferences for receiving marketing communications and details about how you engage with them, and (iv) profile information you elect to provide us for the purposes of using the Services, such as your first name, last name, email address, job title, pictures and profile image of yourself, videos, logos, any branding information, phone number, social media profiles, your location (your “User Profile”). You are not required to provide any other Personal Information to use the basic features of our Services, but you may be required to provide additional Personal Information to access additional features or offerings, such as your phone number.

Information Collected from Other Sources: We may obtain information from other sources, such as third parties in connection with your use of our Services. We may combine this with information previously collected. In these cases, our Privacy Policy governs the handling of the combined Personal Information.

Information Collected Automatically: Whenever you interact with certain parts of our Services such as our landing page located at https://smarcardai.com or any of our webpages (collectively with the Website, the “Site”), we automatically receive and record information on our server logs from your browser or device, which may include your IP address, geolocation data, device identification, “cookie” information, the type of browser and/or device you’re using to access our Services, and the page or feature you requested. We typically collect this information through a variety of tracking technologies, including cookies, embedded scripts, web beacons, file information and similar technology (collectively, “tracking technologies”). “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Site are visited and by how many people. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features. If you click on a link to a third-party website or service, such third party may also transmit cookies to you. This Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices. Please be aware that cookies placed by third parties may continue to track your activities online even after you have left our Services.

We may use this data to customize content for you that we think you might like, based on your usage patterns. We may also use it to improve the Services. For example, this data can tell us how often users use a particular feature of the Services, and we can use that knowledge to make the Services interesting to as many users as possible.

Do Not Track Policy: Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. Do Not Track signals are set on a browser-by-browser basis, so you must set them on every browser you use if you do not wish to be tracked.

We do not track our users over time and across third-party websites to provide targeted advertising and therefore we do not respond to Do Not Track requests. However, some third-party sites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you, and these third parties may not honor Do Not Track requests. Remember that this is just our Do Not Track policy, and we cannot and do not make any promises about how third parties react when you set this signal on your browser, including any third-party sites we may link to. You should always review the privacy policy and specifically, the Do Not Track policy, of a third-party website to understand how they will respond to Do Not Track requests.</p>     
     


<h4>How does SmartCard AI Use the Personal Information it Receives?</h4>
            <p>As a general policy, we aim to protect your privacy and Personal Information by collecting, using or sharing as little Personal Information as possible in order to provide our Services unless you explicitly agree in writing to additional collection, use or sharing.

To the extent we do use Personal Information, we use such information to provide and improve the Services, optimize our technology, refine the experience of our users, and innovate ways to forward our mission.

Namely, we use the information we collect to:

Administer the Services, enable you to use its features, and improve your overall user experience;

Send information including confirmations, technical notices, updates, security alerts, and support and administrative messages;

Respond to comments and questions and provide customer service;

Analyze how users use the Services to help us optimize the Services and know if there are problems with the Services;

Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity;

With your consent, to link or combine user information with other Personal Information;

To provide and deliver products and services requested by customers; and

As disclosed below under the section of this Privacy Policy entitled “Will SmartCard AI Share Any of the Personal Information it Receives?”</p>     
     

     

<h4>Will SmartCard AI Share Any of the Personal Information it Receives?</h4>
            <p>Notwithstanding anything in this Privacy Policy, if you create a User Profile under an Organization, any Personal Information you provide be accessible by any individual with administrator access to such Organization (the “Admin”). The Admin may, at any time, access your User Profile and make any changes or, even, erase your User Profile.

We do not share or sell the Personal Information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We may share your Personal Information with third parties as described below:

Consent: We may share Personal Information with your explicit consent.

Affiliated Businesses: In certain situations, businesses or third-party websites we are affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us). You can recognize when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service. We have no control over the policies and practices of third-party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all of these businesses’ or websites’ policies.

Our Agents: We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you or to us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us.

Business Transfers: We may choose to buy or sell assets and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions of selling SmartCard AI or buying another company. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.

Third-Party Services: We may use third-party services, such as Google Analytics, to grow our business, to improve and develop our Services, to monitor and analyze use of our Services, to aid our technical administration, to increase the functionality and user-friendliness of our Services, and to verify that users have the authorization needed for us to process their requests. These services may collect and retain some information about you, such as the IP address assigned to you on the date you use the Services, but not your name or other personally-identifying information. We may combine the information generated through the use of these services with your Personal Information but never in a way that will identify you to any other user or third party. Although these services may plant a persistent cookie on your web browser to identify you as a unique user the next time you use the Services, the cookie cannot be used by anyone but any of the listed third-party services. 



</p>     


<h4>Will SmartCard AI Share Any of the Personal Information it Receives?</h4>
            <p>Notwithstanding anything in this Privacy Policy, if you create a User Profile under an Organization, any Personal Information you provide be accessible by any individual with administrator access to such Organization (the “Admin”). The Admin may, at any time, access your User Profile and make any changes or, even, erase your User Profile.

We do not share or sell the Personal Information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We may share your Personal Information with third parties as described below:

Consent: We may share Personal Information with your explicit consent.

Affiliated Businesses: In certain situations, businesses or third-party websites we are affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us). You can recognize when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service. We have no control over the policies and practices of third-party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all of these businesses’ or websites’ policies.

Our Agents: We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you or to us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us.

Business Transfers: We may choose to buy or sell assets and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions of selling SmartCard AI or buying another company. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.

Third-Party Services: We may use third-party services, such as Google Analytics, to grow our business, to improve and develop our Services, to monitor and analyze use of our Services, to aid our technical administration, to increase the functionality and user-friendliness of our Services, and to verify that users have the authorization needed for us to process their requests. These services may collect and retain some information about you, such as the IP address assigned to you on the date you use the Services, but not your name or other personally-identifying information. We may combine the information generated through the use of these services with your Personal Information but never in a way that will identify you to any other user or third party. Although these services may plant a persistent cookie on your web browser to identify you as a unique user the next time you use the Services, the cookie cannot be used by anyone but any of the listed third-party services. 

Google gemini ( AI API), Firebase (Database), Oracle (Cloud infrastucture), Render.com (cloud) 


Protection of SmartCard AI and Others: We reserve the right to access, read, preserve, and disclose any information that we believe is necessary to comply with law or court order; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of SmartCard AI, our employees, our users, or others.

When we share Personal Information, we will not share such Personal Information in a manner that can be used to identify you individually or in a manner that provides more Personal Information than is publicly available (if applicable) unless otherwise explicitly agreed by you or instructed by you, and unless we are legally required to do so.
</p>     

<h4>Is Personal Information about me secure?</h4>
            <p>We endeavour to protect the privacy of the Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security. The safety and security of your Personal Information also depends on you. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time. We further protect your Personal Information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. We also seek to protect Personal Information by refraining from collecting Personal Information where possible. However, these measures do not guarantee that your Personal Information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Services, you acknowledge that you understand and agree to assume these risks.</p>  

            <h4>What are my choices pertaining to my Personal Information I provide to SmartCard AI?</h4>
            <p>You have a number of choices when it comes to the Personal Information you provide to SmartCard AI:

Access or update your information. If you have registered for an account with us through the Website, you may review and update certain account information by logging into the account.

Opt-out of marketing communications. You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Even if you choose to opt-out of marketing-related emails, you may continue to receive necessary service-related and other non-marketing emails.

Declining to provide information. We need to collect Personal Information to provide certain services. If you choose not to provide the information we identify as required or mandatory, we may not be able to provide you those services.



When you choose to share your User Profile to any individual, you allow such individual to interact and visit your User Profile (the “Profile Visitor”), and give access to such Profile Visitor to your Personal Information. SmartCard AI has no control over who you wish to give access to your Personal Information and you agree that you do so with knowledge of what Personal Information you choose to share.</p>  
     
<h4>EU RESIDENTS</h4>
            <p>If you are a resident of the European Economic Area (“EEA”) or Switzerland, you may have additional rights under the General Data Protection Regulation (the “GDPR”) and other applicable law with respect to your Personal Data, as outlined below.

For the purpose of this section, we use the terms “Personal Data” and “processing” as they are defined in the GDPR, but “Personal Data” generally means information that can be used to individually identify a person, and “processing” generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. SmartCard AI will be the controller of your Personal Data processed in connection with the Services.

If there are any conflicts between this section and any other provision of this Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at support@smartcardai.com.



We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.</p>  
     
     
<h4>What Personal Data Do We Collect from You?</h4>
            <p>We collect Personal Data about you when you provide such information directly to us, when third parties such as our business partners or service providers provide us with Personal Data about you, or when Personal Data about you is automatically collected in connection with your use of our Services.

Information we collect directly from you: We receive Personal Data directly from you when you provide us with such Personal Data, including without limitation, Personal Information as described above under “Information You Provide to Us” and “Information Collected from Other Sources”, and any other information you may elect to submit in your communications with us while using our Services. You are not required to submit Personal Data solely in connection with your use of your account through our Services, but certain of our other Services may require submission of Personal Data to fully exploit those Services.



Information we automatically collect when you use our Services: Some Personal Data is automatically collected when you use our Services, including without limitation, the information described above under “Information Collected Automatically.”</p>  
     
<h4>How Do We Use Your Personal Data?</h4>
            <p>We process Personal Data to operate, improve, understand and personalize our Services. See “How does SmartCard AI Use the Personal Information it Receives?” for more information on how we use your Personal Data.

We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing include consent, contractual necessity and our “legitimate interests” or the legitimate interest of others, as further described below.

Contractual Necessity: We process certain Personal Data as a matter of “contractual necessity”, meaning that we need to process the data to perform under our Terms of Use with you, which enables us to provide you with the Services. When we process data due to contractual necessity, failure to provide such Personal Data will result in your inability to use some or all portions of the Services that require such data.

Legitimate Interest: We may also process your Personal Data where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests. We consider and balance any potential impacts on you (both positive and negative) and your rights before we process your Personal Data for our legitimate interests. We do not use your Personal Data for activities where our interests are overridden by any adverse impact on you (unless we have your consent or are otherwise required or permitted to by law). Examples of these legitimate interests include operation and improvement of our business, products and Services, provision of customer support, protection from fraud or security threats, compliance with legal obligations or completion of corporate transactions.

Consent: In some cases, we process Personal Data based on the consent you expressly grant to us at the time we collect such data. When we process Personal Data based on your consent, it will be expressly indicated to you at the point and time of collection.

Other Processing Grounds: From time to time we may also need to process Personal Data to comply with a legal obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the public interest.</p>  


<h4>How and With Whom Do We Share Your Data?</h4>
            <p>We share Personal Data with our agents, third-party service providers who work on our behalf and provide us with services related to the purposes described in this Privacy Policy or our Terms of Use, potential business partners in connection with business transfers described in this Privacy Policy, or for legal purposes. Please see “Will SmartCard AI Share Any of the Personal Information it Receives?” for more information.</p>  
      

            <h4>How Long Do We Retain Your Personal Data?</h4>
            <p>We retain Personal Data about you for as long as necessary to provide you Services. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we may retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>  
      

            <h4>What Security Measures Do We Use?</h4>
            <p>We seek to protect Personal Data using appropriate technical and organizational measures based on the type of Personal Data and applicable processing activity, and, where possible, we refrain from collecting Personal Data. We secure the Personal Data you provide in a controlled, secure environment that is protected from unauthorized access. SmartCard AI regularly audits its system for any possible security vulnerabilities to safeguard Personal Data. SmartCard AI protects the security of your information during transmission by using Transport Layer Security (TSL/SSL), which encrypts all information that you input. </p>  
      

            <h4>What Rights Do You Have Regarding Your Personal Data?</h4>
            <p>You have certain rights with respect to your Personal Data, including those set forth below. For more information about these rights, or to submit a request, please email support@smartcardai.com. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need to you to provide us with additional information, which may include Personal Data, if necessary, to verify your identity and the nature of your request.

Right to Access: You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. You can also access certain of your Personal Data by visiting the Platform or Website.

Right to Rectification: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can correct some of this information directly by editing them on the Platform or Website.

Right to Erasure: You can request that we erase some or all your Personal Data from our systems.

Right to Withdrawal of Consent: If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.

Right to Portability: You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.

Right to Objection: You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes.

Restriction of Processing: You can ask us to restrict further processing of your Personal Data.

Right to Right to File Complaint: You have the right to lodge a complaint about SmartCard AI’s practices with respect to your Personal Data with the supervisory authority of your country or European Union Member State. </p>  
      

<h4>Transfers of Personal Data</h4>
            <p>Certain of the Services are hosted and operated in part in USA through SmartCard AI and its service providers, and if you do not reside in the US, laws in the US may differ from the laws where you reside. By using the Services and providing your information, you acknowledge that any Personal Data about you, regardless of whether provided by you or obtained from a third party, may be provided to SmartCard AI in India and may be hosted on US servers. You hereby consent to and authorize SmartCard AI to transfer, store and process your Personal Data to and in Canada, and possibly other countries. We will take all steps reasonably necessary to ensure that your information is treated securely and in accordance with this Privacy Policy. One such step we may take to ensure the security of your Personal Data in the event that a transfer may not be subject to the same protection in the EEA or Switzerland, is to enter into specific contractual clauses approved by the European Commission which ensure your personal information is given the same protection it has in Europe.</p>  
      

            <h4>What If You Have Questions Regarding Your Personal Data?</h4>
            <p>If you have any questions about this section or our data practices generally, please contact us using the following information:email us at support@smartcardai.com</p>  
      

            <h4>Right to Request Deletion of Information</h4>
            <p>You have the right to request in certain circumstances that we delete any Personal Information that we have collected directly from you. You may submit such a request as described below. To protect our users’ Personal Information, we are required to verify your identity before we can act on your request. We may have a reason under the law why we do not have to comply with your request, or why we may comply with it in a more limited way than you anticipated. If we do, we will explain that to you in our response.</p>  
      

            <h4>How to Submit a Request </h4>
            <p>You may submit a request to exercise your rights to know/access or delete your Personal Information by sending us an email at support@smartcardai.com.</p>  
      

            <h4>Right to Opt-Out of Sale of Personal Information to Third Parties</h4>
            <p>You have the right to opt-out of any sale of your Personal Information by us to third parties. We do not sell information to third parties.</p>  
      
            <h4>Right to Information Regarding Participation in Data Sharing for Financial Incentives</h4>
            <p>You have the right to be free from discrimination based on your exercise of your CCPA rights.  We do not discriminate against anyone who chooses to exercise their CCPA rights.</p>  
      
          </>
        );
        break;

      case 'About Us':
        setModalContent(
          <>
           <h4>Our Vision</h4>
           <p>Enhancing business processes with AI apps means leveraging cutting-edge technology to streamline operations, boost efficiency, and drive innovation. By integrating AI applications, companies can automate routine tasks, gain deeper insights from data, and improve decision-making. This not only accelerates workflows but also enhances overall productivity and allows teams to focus on more strategic activities, ultimately leading to a more agile and competitive business environment.</p>
            <h4>Our Mission</h4>
            <p>
            The mission to build minimalistic AI apps is centered on creating streamlined, user-centric solutions that prioritize simplicity and functionality. By focusing on essential features and intuitive design, these apps aim to deliver powerful AI capabilities without unnecessary complexity. This approach ensures that users can quickly adopt and integrate AI technology into their workflows, reducing friction and maximizing impact. The goal is to democratize AI by making it accessible and effective for a wide range of users, fostering innovation while maintaining clarity and ease of use. </p>
            <h4>Contact US</h4>
            <p>email : support@smartcardai.com</p>
            <p>Address : Fastighets Cybr Systems Private Limited, Poickattusery, Chegamand (P.O), Kerala, India-683578</p>
          </>
        );
        break;

        case 'Contact Us':
          setModalContent(
            <>
             <h4>Contact US</h4>
            <p>email : support@smartcardai.com</p>
            <p>Address : Fastighets Cybr Systems Private Limited, Poickattusery, Chegamand (P.O), Kerala, India-683578</p>
         </>
          );
          break;

          case 'Cancel':
            setModalContent(
              <>
               <h4> Automatic Cancellation</h4>
              <p>Subscription Cancellation: Your subscription will automatically terminate at the end of the current billing period. You will not be charged for the next billing cycle once the cancellation has been processed.</p>
              <h4> Refund Policy</h4>
              <p>No Refund

Policy: All subscription fees are non-refundable. Once a payment has been processed, it is non-refundable, even if you cancel your subscription before the end of the current billing period.</p>
              
           </>
            );
            break;

      default:
        setModalContent('');
    }

    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalTitle('');
    setModalContent('');
  };


  return (
    
    <div style={{ overflowX:'hidden'}}>
    <GlobalStyle />
    
 {/* Card 3 */}

 <CardContainer1 style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
 
 
        
   <RightContent style={{ padding: '5vw', marginTop: '7vh' , marginBottom: '5vh'}}>
      
        <div style={{  display: 'flex' }}>
      <div className="custom-scrollbar" style={{
        backgroundColor: ' rgba(255, 255, 255, 0.2)',
        
        Width: '10vw',
        Height: '10vh',
        padding: '20px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>

<BlueButton3  as={Link} to="/Login1">
              Login 
            </BlueButton3>
        <div style={{ textAlign: 'justify' , color:'white'}}>
        <img
          src="./assets/Bizcardai/web1.png"
          alt="Card Background"
          style={{ width: '25%', height: '55%'}}
        />


        </div>
        
      </div>
     
    </div>
  
    </RightContent>    


   
    
    {/* Left side content */}

   


    <LeftContent style={{ padding: '5vw', marginTop: '-2vh' , marginBottom: '5vh'}}>
    <div className="video-container2">
    <h2 style={{ fontWeight: '300' , color:'white' , marginLeft:'0.4vw'}}> Demo</h2>
    <iframe
      src= {`${baseURLweb}/MyBusinesscard/JudeMathew`}
      className="responsive-video2"
      frameBorder="0"
      allowFullScreen
      title="Business Card Video"
    ></iframe>

<h3 style={{ fontWeight: '300' ,color:'white' , marginLeft:'4.2vw'}}> All in one shareable link</h3>
    </div>
  </LeftContent>
  
   
</CardContainer1>



<style jsx>{`
  .video-container2 {
    width: 70%;
     height: 90vh;
    margin-left: 10vw; /* Adjust this value to leave space on the left side */
    margin-top:5vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 80%;
    border-radius: 1vw;
    
  }

  
  @media (max-width: 1600px) {
.video-container2 {
    width: 69%;
     height: 105vh;
    margin-left: 8vw; /* Adjust this value to leave space on the left side */
    margin-top:1vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 1vw;
    
  }
  }

  @media (max-width: 1200px) {
.video-container2 {
    width: 95%;
     height: 105vh;
    margin-left: 8vw; /* Adjust this value to leave space on the left side */
    margin-top:3vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 1vw;
    
  }
  }

   @media (max-width: 1100px) {
.video-container2 {
    width: 95%;
     height: 65vh;
    margin-left: 8vw; /* Adjust this value to leave space on the left side */
    margin-top:3vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 1vw;
    
  }
  }

   

  @media (max-width: 900px) {
   .video-container2 {
    width: 85%;
     height: 75vh;
    margin-left: 13vw; /* Adjust this value to leave space on the left side */
    margin-top:8vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 2vw;
    
  }
  }

  
  @media (max-width: 850px) {
   .video-container2 {
    width: 85%;
     height: 55vh;
    margin-left: 8vw; /* Adjust this value to leave space on the left side */
    margin-top:8vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 2vw;
    
  }
  }

  @media (max-width: 600px) {
   .video-container2 {
    width: 75%;
     height: 50vh;
    margin-left: 13vw; /* Adjust this value to leave space on the left side */
    margin-top:-8vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 1vw;
    
  }
  }

   @media (max-width: 550px) {
   .video-container2 {
    width: 85%;
     height: 80vh;
    margin-left: 13vw; /* Adjust this value to leave space on the left side */
    margin-top:-8vh;
  }

  .responsive-video2 {
    width: 80%;
    
    height: 70%;
    border-radius: 3vw;
    
  }
  }
`}</style>



      
      {/* Card 1 */}
      <CardContainerwhite>
  <ContentContainer>
    <div className="container">

    <h1 className="animated-text" style={{ fontSize: '3rem' }}>
      
      
      
        <span className="non-animated" style={{ color: 'black', marginRight: '20px' }}>
      Digital Business Cards Powered By
        </span>
        Generative AI 

        <img
          src="./assets/Bizcardai/star.png"
          alt="Card Background"
          style={{ width: '10vw', height: '10vw'}}
        />
        
      </h1>
    <BlueButton2 as={Link} to="/Login1">
              Login 
            </BlueButton2>
    

    
      
  
    </div>

   

        </ContentContainer>
      </CardContainerwhite>




     {/* Card 3 */}

     <CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={keywordsboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'3rem' }}>Generative AI-Powered Relevant Keyword Generator</h2>
        <h2 style={{ fontWeight: '310' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'2rem' }}>Boost your contact's visibility and keep them at the top of your client's contact list.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
        backgroundColor: 'rgba(76, 207, 183, 0.9)',
        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white', fontSize:'1.2rem'}}> Our Auto-Fix Keywords feature uses advanced Generative AI to automatically refine and enhance your keyword selection, ensuring optimal relevance and impact. By intelligently analyzing context and trends, it provides high-quality keywords that drive better search engine performance and audience engagement.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>
</CardContainerwhite>

{/* Card 3 */}

<CardContainerwhite>
 {/* Left side content */}
 <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
        <h2 style={{ fontWeight: '400' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'3rem' }}>Unified App with Content Management System</h2>
        <h2 style={{ fontWeight: '310' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'2rem' }}>Seamlessly Manage Your Virtual Business Card Content and Card. All in One Tab!</h2>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className="custom-scrollbar" style={{
       backgroundColor: 'rgba(76, 207, 183, 0.9)',
      width: '80%',
      maxHeight: '50vh',
      padding: '20px',
      overflowY: 'scroll',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      color: 'white',
      textAlign: 'justify'
    }}>
      <p>
        Introducing the SmartCard AI Virtual Business Card Builder, your ultimate digital business card solution. Seamlessly create personalized digital business cards with ease and efficiency. Customize every detail to reflect your professional identity and make a lasting impression.
      </p>
      <p>
        Key Features:
        <ul>
          
          <li><strong>Tappable Buttons:</strong> Interactive buttons for email, phone, WhatsApp, location, and website. Email autofills the recipient, WhatsApp opens a chat with the card holder, location opens in Google Maps, and phone opens the dial pad with your number are few tappable features.</li>
          <li><strong>vCard Generation:</strong> Save your digital business card to local contacts or cloud providers like Google Cloud for easy access and management.</li>
          <li><strong>Share Functionality:</strong> Share your digital business card via various platforms including Slack, Outlook, HubSpot, Google Drive, WhatsApp, and more.</li>
          <li><strong>Embedded Interface:</strong> If needed, busines card interface can be embedded on your website proving an enhanced cusotmer experiece. </li>
          <li><strong>Contact Information:</strong> Easily add your WhatsApp number, location, email address, and website URL to provide comprehensive contact details in one convenient place.</li>
          <li><strong>Personalization:</strong> Upload your profile image, choose a background image or color, and add your title and company name to tailor your digital business card to perfection.</li>
          <li><strong>QR Code Generation:</strong> Instantly generate a QR code linked to your digital card, making it easy for others to save and share your contact details effortlessly.</li>
          <li><strong>Dynamic Link Generation:</strong> Generate a unique link for your digital business card, ideal for sharing across digital platforms and ensuring accessibility for all recipients.</li>
       
        </ul>
      </p>
      <p>
      SmartCard AI Virtual Business Card Builder simplifies the process of creating and sharing digital business cards. Whether you're networking at events, connecting with clients, or expanding your professional network, QR App Builder ensures that your contact information is easily accessible and visually appealing.
      </p>
      <p>
        Why Choose SmartCard AI Virtual Business Card Builder?
        <ul>
        <li>Virtual Business Card Management: One app for creation, preview, and sharing. Simplify administration and updates.</li>
          <li>Convenience: Simplify your networking efforts with easy-to-create and share digital business cards.</li>
          <li>Customization: Personalize every aspect of your digital card to match your professional brand.</li>
          <li>Accessibility: Ensure your contact information is readily available through QR codes and dynamic links.</li>
          <li>Professionalism: Impress clients and contacts with visually appealing and informative digital cards.</li>
        </ul>
      </p>
      <p>
        Empower your networking efforts with SmartCard AI Virtual Business Card Builder,where customization meets convenience in the digital age. Start creating your standout digital business card today!
      </p>
    </div>
  </div>
        </LeftContent>


   {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
  <img src={contentboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
  </RightContent>
        {/* Left side content */}

        
       



      
  </CardContainerwhite>




 {/* Contact Board */}


 

<CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={contactboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'3rem' }}>Contact</h2>
        <h2 style={{ fontWeight: '300' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'2rem' }}>Connect Instantly: Click to Call, Chat, Email, and Navigate.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
          backgroundColor: 'rgba(76, 207, 183, 0.9)',
        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white', fontSize:'1.2rem'}}> Clickable contact information on virtual business cards is essential for optimizing modern communication and networking. By enabling users to call directly, send WhatsApp messages, email instantly, or navigate to a physical location with just a click, these features streamline the process of connecting with others. This convenience not only saves time but also facilitates more effective and immediate interactions, making it easier for potential clients, partners, and contacts to engage with your business. Ultimately, clickable contact details enhance user experience and foster stronger, more efficient relationships.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>
</CardContainerwhite>


{/* Social Board*/}


 

<CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'3rem' }}>Social</h2>
        <h2 style={{ fontWeight: '310' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'2rem' }}>Showcase Your Social Media Presence.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
       backgroundColor: 'rgba(76, 207, 183, 0.9)',

        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white' , fontSize:'1.2rem'}}> Showcase your social media presence effortlessly with our Virtual Business Card. Seamlessly integrate your YouTube channel, LinkedIn profile, Facebook page, and more, all in one sleek, digital hub. Enhance your professional image and make it easy for connections to find and follow you across multiple platforms. Elevate your networking game with a comprehensive, interactive business card that represents your brand across the digital landscape.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={socialboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   
</CardContainerwhite>


{/* Review Board */}


 

<CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={reviewboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'3rem' }}>Review</h2>
        <h2 style={{ fontWeight: '300' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'2rem' }}>Ultimate hub for honest reviews.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
         backgroundColor: 'rgba(76, 207, 183, 0.9)',
        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p
        style={{
           // Adjust as needed
          color: 'white', // Adjust text color as needed
         fontSize:'1.2rem'
        }}
      > Introducing the Ultimate Hub for honest reviews on your Virtual Business Card. Where all your trusted feedback comes together in one place. Seamlessly integrate top review platforms like Trustpilot, G2, Google Reviews, and more to showcase your credibility and build trust with potential clients and partners. This centralized hub not only highlights your positive feedback but also enhances your professional image by providing a comprehensive view of your reputation. Elevate your transparency and credibility with a Virtual Business Card that brings all your valuable reviews together in one convenient, accessible location.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>
</CardContainerwhite>


{/* Book a Meeting Board*/}


 

<CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'3rem' }}>Book a Meeting</h2>
        <h2 style={{ fontWeight: '310' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'2rem' }}>Add your favorite Calendars, your way.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
         backgroundColor: 'rgba(76, 207, 183, 0.9)',

        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white' , fontSize:'1.2rem'}}> Seamlessly integrating tools like Teams, Google Calendar, Zoom, and more into virtual business cards is pivotal for efficient and personalized planning. This integration allows users to effortlessly schedule meetings, coordinate events, and manage appointments directly from the card, streamlining the workflow and minimizing the hassle of juggling multiple platforms. By embedding these tools, you enable recipients to connect, collaborate, and coordinate with just a click, enhancing productivity and ensuring that all interactions are smooth and organized. This level of integration not only reflects a modern and tech-savvy approach but also makes planning and communication more intuitive and effective.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={bookameetingboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   
</CardContainerwhite>


   

<style jsx>{`
  .video-container {
    width: 100%;
    margin-left: 15vw; /* Adjust this value to leave space on the left side */
  }

  .responsive-video {
    width: 50%;
    height: auto;
    border-radius: 1vw;
    
  }

  @media (max-width: 1200px) {
    .video-container {
       margin-left: 12vw; /* Adjust this value to leave space on the left side */
    }

      .responsive-video {
    width: 68%;
    height: auto;
    border-radius: 1vw;
    
  }
  }

  @media (max-width: 900px) {
    .video-container {
      margin-left:10vw; /* Adjust this value to leave space on the left side */
    }
      .responsive-video {
    width: 70%;
    height: auto;
    border-radius: 1vw;
    
  }
  }

  @media (max-width: 600px) {
    .video-container {
      margin-left: 13vw; /* Adjust this value to leave space on the left side */
    }
  .responsive-video {
    width: 70%;
    height: auto;
    border-radius: 4vw;
    
  }
  }
`}</style>



     


   


      



      






   


       
          
            
        
     

     


      {/* Card 3 */}

      <CardContainerwhite style={{ paddingTop: '2px',marginBottom: ' 20px'  }}>
        {/* Left side content */}

        
 
       
       
        
        {/* Right side content */}
        <RightContent style={{ padding: '50px' ,marginTop:'60px' }}>
          <h2 style={{ marginBottom: '10px' }}>
            <span style={{ color: '#989a98', fontSize: '2.5rem', fontWeight: '400', marginRight: '6px' }}></span>
            <span style={{
              color: 'black',
              fontSize: '3rem',
              fontWeight: '400',
              letterSpacing: '2px',
              textAlign: 'left',
              display: 'block',  // Ensures the span takes up the full width
            }}>
              Quick Business Card Configuration
            </span>

          </h2>


          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 400, marginLeft: '60px',marginTop: '80px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(19, 31, 28, 0.9)',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>

              <span style={{ color: 'white',  fontSize: '1.2rem', fontWeight: '300' }}>1</span>



            </span>
            Sign Up as a User
          </p>



          

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 400, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
          <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(19, 31, 28, 0.9)',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '300' }}>2</span>



            </span>
            Sign In to Your Account
          </p>




          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 400, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
          <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(19, 31, 28, 0.9)',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '300' }}>3</span>



            </span>
            
            Add your Contacts, Social, Calendar, Portfolio and more 
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 400, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
          <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(19, 31, 28, 0.9)',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '300' }}>4</span>

            </span>
            
            Get the ball rolling!
          </p>

        </RightContent>


        
          {/* Add your image here */}
          <LeftContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
            <img src={loginboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />


        </LeftContent>
      </CardContainerwhite>

        
      <div style={containerStyle}>
      

      <button onClick={() => handleOpenModal('Terms and Conditions')} style={{ color: 'white', background: 'none', border: 'none' }}>
        Terms and Conditions
      </button>
      <button onClick={() => handleOpenModal('Privacy Policy')} style={{ color: 'white', background: 'none', border: 'none' }}>
        Privacy Policy
      </button>
      <button onClick={() => handleOpenModal('About Us')} style={{ color: 'white', background: 'none', border: 'none' }}>
        About Us
      </button>

      <button onClick={() => handleOpenModal('Contact Us')} style={{ color: 'white', background: 'none', border: 'none' }}>
        Contact Us
      </button>
      <button onClick={() => handleOpenModal('Cancel')} style={{ color: 'white', background: 'none', border: 'none' }}>
        Cancellation & Refund Policy 
      </button>
      
      <Modal
        title={modalTitle}
        content={modalContent}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />

      <p style={footerStyle}>
        © SmartCard AI
      </p>

     
    </div>
    </div>
  );
  
};

const App = () => {
  

  const location = useLocation();
 
  

  const isHome = location.pathname === '/';
  //const isLoginRoute = location.pathname === '/smartconsole1';
  const [isMobile, setIsMobile] = useState(window.innerWidth < 810);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
      // Close the mobile menu when the screen is resized
      setIsMobileMenuVisible(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuVisible(false);
  };

const toggleMobileMenu = () => {
   //Toggle the mobile menu visibility
   setIsMobileMenuVisible((prev) => !prev);
  };

 

  const handleNavLinkClick = () => {
    // Close the mobile menu when a navigation link is clicked
    closeMobileMenu();
    
  };

  
  // Conditionally render the Navbar if not on the login or reset password routes
const shouldRenderNavbar = location.pathname === '/' ;
    

  return (
    
    <div style={{ overflowX:'hidden'}}>
    <GlobalStyle />
    
     <Navbar>
      {shouldRenderNavbar && (
        <NavList >
           <NavItem isVisible={!isMobile} >
          <Link to="/" style={{ color: isHome ? 'white' : 'black' }} onClick={handleNavLinkClick}>
          {/* <img src="/assets/webicon/SmartCard5.png" alt="Logo" style={{ width: '110px', height: '103px' }} />*/}
            </Link>
          </NavItem>
          
        </NavList>
         )}
        </Navbar>

       
        
        {shouldRenderNavbar && (
        <Navbar>

        <RightNavList>

  

         {/* <RightNavItem isVisible={!isMobile}>
            <Link to="/news" style={{ color: isHome ? 'white' : 'black' }}onClick={handleNavLinkClick}>
              News
            </Link>
          </RightNavItem>*/}

<RightNavItem isVisible={!isMobile}>
              <Link to="/Usecases" style={{ color: isHome ? 'white' : 'black' }} onClick={handleNavLinkClick}>
                Usecases
              </Link>

            </RightNavItem>


<RightNavItem isVisible={!isMobile}>
              <Link to="/Features" style={{ color: isHome ? 'white' : 'black' }} onClick={handleNavLinkClick}>
                Features
              </Link>

            </RightNavItem>

          
            <RightNavItem isVisible={!isMobile}>
              <Link to="/Pricing" style={{ color: isHome ? 'white' : 'black' }} onClick={handleNavLinkClick}>
                Pricing
              </Link>

            </RightNavItem>
          <RightNavItem isVisible={!isMobile}>
        
            <BlueButton as={Link} to="/Login1">
              Login 
            </BlueButton>

            
        
           {/* <BlueButton as={Link} to="/Signup1">
              Sign Up
            </BlueButton>*/}
          </RightNavItem>

          
        </RightNavList>
         

         
         <MobileMenuIcon onClick={toggleMobileMenu} >
          <span style={{ color: 'white', fontSize: '24px' }}>☰</span>
        </MobileMenuIcon>
        <MobileMenu isVisible={isMobileMenuVisible} >


         
          <Link to="/Login1" onClick={handleNavLinkClick} style={{textDecoration: 'none' , color: 'black', display: 'block', marginBottom: '10px' }}>
            Login
          </Link> 

          <Link to="/Pricing" onClick={handleNavLinkClick} style={{textDecoration: 'none' , color: 'black', display: 'block', marginBottom: '10px' }}>
            Pricing
          </Link>

          <Link to="/Usecases" onClick={handleNavLinkClick} style={{textDecoration: 'none' , color: 'black', display: 'block', marginBottom: '10px' }}>
            Usecases
          </Link> 

          <Link to="/Features" onClick={handleNavLinkClick} style={{textDecoration: 'none' , color: 'black', display: 'block', marginBottom: '10px' }}>
            Features
          </Link> 
         
        </MobileMenu>
         
        
      </Navbar>
      
      )}
      <UserProvider>
     
      <Routes>
      
        <Route
          path="/"
          element={
            <HomeContent />
          }
        />
        <Route
          path="/work"
          
        />
        
        <Route path="/MyBusinesscard/:username" element={<PreviewWebsiteClient />} />
        <Route path="/Usecases" element ={<Usecases/>}/>
        <Route path="/Features" element ={<Features/>}/>
        <Route path="/Pricing" element ={<Pricing/>}/>
        <Route path="/Bookme" element ={<Bookme />}/>
        <Route path="/smartscan1" element ={<Smartscan2 />} />
        <Route path="/Signup1" element ={<Signup />} />
        <Route path="/Login1" element ={<Login />} />
        <Route path="/Reset1" element ={<ResetPasswordPage />} />
        <Route path="/Newpass1" element ={<Newpass />} />
        <Route path="/smartconsole1" element={<SmartConsole1 />} />
        
        
        {/* Add other routes as needed */}
        
      </Routes>
      </UserProvider>
    
    </div>
       
  );
};
export default App;