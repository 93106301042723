import React from 'react';
import styled from 'styled-components';

import contactboard from './../17.png';
import socialboard from './../18.png';
import reviewboard from './../19.png';
import bookameetingboard from './../20.png';
import keywordsboard from './../21.png';
import contentboard from './../22.png';

// Define styled components
const CardContainerwhite = styled.div`
  align-items: center;
  justify-content: center;
  min-height: calc(130vh - 5cm);
  background: white;
  background-size: 150% 150%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between; // Ensure right content stays on the right
  }
`;

const LeftContent = styled.div`
  flex: 1;
  @media (min-width: 600px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const RightContent = styled.div`
  flex: 1;
  margin-top: 10px; // Add margin-top to create space between LeftContent and RightContent

  @media (max-width: 600px) {
    margin-top: 0; // Remove margin-top for smaller screens
  }
`;

const Features = () => {
  return (
    <>


       {/* Card 3 */}
   <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={contentboard} alt="Keywords Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>

        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
        <h2 style={{ fontWeight: '400' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'3rem' }}>Unified App with Content Management System</h2>
        <h2 style={{ fontWeight: '310' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'2rem' }}>Seamlessly Manage Your Virtual Business Card Content and Card. All in One Tab!</h2>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className="custom-scrollbar" style={{
           backgroundColor: 'rgba(76, 207, 183, 0.9)',
      width: '80%',
      maxHeight: '50vh',
      padding: '20px',
      overflowY: 'scroll',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      color: 'white',
      textAlign: 'justify'
    }}>
      <p>
        Introducing the SmartCard AI Virtual Business Card Builder, your ultimate digital business card solution. Seamlessly create personalized digital business cards with ease and efficiency. Customize every detail to reflect your professional identity and make a lasting impression.
      </p>
      <p>
        Key Features:
        <ul>
          
          <li><strong>Tappable Buttons:</strong> Interactive buttons for email, phone, WhatsApp, location, and website. Email autofills the recipient, WhatsApp opens a chat with the card holder, location opens in Google Maps, and phone opens the dial pad with your number are few tappable features.</li>
          <li><strong>vCard Generation:</strong> Save your digital business card to local contacts or cloud providers like Google Cloud for easy access and management.</li>
          <li><strong>Share Functionality:</strong> Share your digital business card via various platforms including Slack, Outlook, HubSpot, Google Drive, WhatsApp, and more.</li>
          <li><strong>Embedded Interface:</strong> If needed, busines card interface can be embedded on your website proving an enhanced cusotmer experiece. </li>
          <li><strong>Contact Information:</strong> Easily add your WhatsApp number, location, email address, and website URL to provide comprehensive contact details in one convenient place.</li>
          <li><strong>Personalization:</strong> Upload your profile image, choose a background image or color, and add your title and company name to tailor your digital business card to perfection.</li>
          <li><strong>QR Code Generation:</strong> Instantly generate a QR code linked to your digital card, making it easy for others to save and share your contact details effortlessly.</li>
          <li><strong>Dynamic Link Generation:</strong> Generate a unique link for your digital business card, ideal for sharing across digital platforms and ensuring accessibility for all recipients.</li>
       
        </ul>
      </p>
      <p>
      SmartCard AI Virtual Business Card Builder simplifies the process of creating and sharing digital business cards. Whether you're networking at events, connecting with clients, or expanding your professional network, QR App Builder ensures that your contact information is easily accessible and visually appealing.
      </p>
      <p>
        Why Choose SmartCard AI Virtual Business Card Builder?
        <ul>
        <li>Virtual Business Card Management: One app for creation, preview, and sharing. Simplify administration and updates.</li>
          <li>Convenience: Simplify your networking efforts with easy-to-create and share digital business cards.</li>
          <li>Customization: Personalize every aspect of your digital card to match your professional brand.</li>
          <li>Accessibility: Ensure your contact information is readily available through QR codes and dynamic links.</li>
          <li>Professionalism: Impress clients and contacts with visually appealing and informative digital cards.</li>
        </ul>
      </p>
      <p>
        Empower your networking efforts with SmartCard AI Virtual Business Card Builder,where customization meets convenience in the digital age. Start creating your standout digital business card today!
      </p>
    </div>
  </div>
        </LeftContent>
      </CardContainerwhite>
      
      {/* Contact Board */}
      <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={contactboard} alt="Contact Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>

        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '3rem' }}>
            Contact
          </h2>
          <h2 style={{ fontWeight: '300', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '2rem' }}>
            Connect Instantly: Click to Call, Chat, Email, and Navigate.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                 backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
                  Clickable contact information on virtual business cards is essential for optimizing modern communication and networking. By enabling users to call directly, send WhatsApp messages, email instantly, or navigate to a physical location with just a click, these features streamline the process of connecting with others. This convenience not only saves time but also facilitates more effective and immediate interactions, making it easier for potential clients, partners, and contacts to engage with your business. Ultimately, clickable contact details enhance user experience and foster stronger, more efficient relationships.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>
      </CardContainerwhite>

      {/* Social Board */}
      <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '3rem' }}>
            Social
          </h2>
          <h2 style={{ fontWeight: '310', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '2rem' }}>
            Showcase Your Social Media Presence.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
                  Showcase your social media presence effortlessly with our Virtual Business Card. Seamlessly integrate your YouTube channel, LinkedIn profile, Facebook page, and more, all in one sleek, digital hub. Enhance your professional image and make it easy for connections to find and follow you across multiple platforms. Elevate your networking game with a comprehensive, interactive business card that represents your brand across the digital landscape.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>

        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={socialboard} alt="Social Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>
      </CardContainerwhite>

      {/* Review Board */}
      <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={reviewboard} alt="Review Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>

        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '3rem' }}>
            Review
          </h2>
          <h2 style={{ fontWeight: '300', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '2rem' }}>
            Ultimate hub for honest reviews.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                 backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
                  Our review section is designed to showcase genuine customer feedback and ratings, providing prospective clients with a transparent view of your business. Positive reviews build credibility and trust, while detailed testimonials highlight your strengths and unique value propositions. By integrating honest reviews into your virtual business card, you enhance your reputation and offer a more compelling case for potential clients to engage with your services.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>
      </CardContainerwhite>


      {/* Card 3 */}
      <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={keywordsboard} alt="Keywords Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>

        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '3rem' }}>
            Generative AI-Powered Relevant Keyword Generator
          </h2>
          <h2 style={{ fontWeight: '310', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '2rem' }}>
            Boost your contact's visibility and keep them at the top of your client's contact list.
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                 backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
                  Our Auto-Fix Keywords feature uses advanced Generative AI to automatically refine and enhance your keyword selection, ensuring optimal relevance and impact. By intelligently analyzing context and trends, it provides high-quality keywords that drive better search engine performance and audience engagement.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>
      </CardContainerwhite>


      
<CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>
       

       {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'3rem' }}>Book a Meeting</h2>
        <h2 style={{ fontWeight: '310' ,marginRight:'3vw',textAlign: 'right', color: 'black', fontSize:'2rem' }}>Add your favorite Calendars, your way.</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
          backgroundColor: 'rgba(76, 207, 183, 0.9)',

        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white' , fontSize:'1.2rem'}}> Seamlessly integrating tools like Teams, Google Calendar, Zoom, and more into virtual business cards is pivotal for efficient and personalized planning. This integration allows users to effortlessly schedule meetings, coordinate events, and manage appointments directly from the card, streamlining the workflow and minimizing the hassle of juggling multiple platforms. By embedding these tools, you enable recipients to connect, collaborate, and coordinate with just a click, enhancing productivity and ensuring that all interactions are smooth and organized. This level of integration not only reflects a modern and tech-savvy approach but also makes planning and communication more intuitive and effective.</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>

       {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={bookameetingboard} alt="" style={{ marginLeft:'-11%',width: '110%', height: 'auto' }} />
   
  </RightContent>


   
</CardContainerwhite>





    </>
  );
};

export default Features;
