
import './details2.scss'; // Import the CSS file
//import MobileViewAdmin1 from '../mobileview/mobileviewadmin1.js';
//import { getcontactDataURL } from '../../../../../../../routes.mjs';




const Details2 = ({ username , contactName, contacttitle, contactcompany}) => {


   

    return (
      

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                 
                 
                   <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >



                   
                 {/*  name */}

                <div className='name'>
                    {contactName ? contactName : 'Full Name'}
                    <span className='circle'>
                        <span>&#10003;</span>
                    </span>
                </div>


               {/*  email */}

                <div className='title'>
                    {contacttitle ? contacttitle : 'Job Title'} at {contactcompany ? contactcompany : 'Company Name'}
                </div>


        
                   
                          
                    
                    </div>
                </div>
            
    );
};

export default Details2;



