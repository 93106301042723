//routes.mjs

// Define the base URL
export const baseURL = 'https://smartcardback.onrender.com'; 

export const baseURLweb = 'https://www.smartcardai.com';

//export const baseURLweb = 'https://testcommission-83ab7.web.app';

//export const baseURL = 'https://testsmartcardback.onrender.com';

export const basepaymentURL = 'https://paymentback-mmlm.onrender.com'; 

//export const baseURL = 'http://localhost:3001'; 

//export const baseURLweb = 'http://localhost:3000';


//ngrok
//export const baseURL = 'https://testsmartcardback.onrender.com'; 

//export const baseURLweb = 'https://vg9149hg-3000.inc1.devtunnels.ms';


// Endpoint for signup
export const signupEndpoint = '/signup';
export const signupURL = `${baseURL}${signupEndpoint}`;

// Endpoint for auth
export const authEndpoint = '/auth';
export const authURL = `${baseURL}${authEndpoint}`;

// Endpoint for login
export const loginEndpoint = '/login';
export const loginURL = `${baseURL}${loginEndpoint}`;

// Endpoint for payment
export const postpaymentEndpoint = '/initiatePayment';
export const postpaymentURL = `${basepaymentURL}${postpaymentEndpoint}`;

// Endpoint for verify
export const verifyEndpoint = '/verify';
export const verifyURL = `${baseURL}${verifyEndpoint}`;

// Endpoint for google subscribe
export const googlesubscribeEndpoint = '/googlesubscribe';
export const googlesubscribeURL = `${baseURL}${googlesubscribeEndpoint}`;

// Endpoint for verifycode
export const verifycodeEndpoint = '/verify/verifycode';
export const verifycodeURL = `${baseURL}${verifycodeEndpoint}`;

// Endpoint for facebookLink
export const facebookLinkEndpoint = '/myprofile/facebookLink';
export const facebookLinkURL = `${baseURL}${facebookLinkEndpoint}`;

// Endpoint for facebookLink
export const getfacebookLinkEndpoint = '/myprofile/getnowfacebookLink';
export const getfacebookLinkURL = `${baseURL}${getfacebookLinkEndpoint}`;

// Endpoint for facebookLink
export const delfacebookLinkEndpoint = '/myprofile/delfacebookLink';
export const delfacebookLinkURL = `${baseURL}${delfacebookLinkEndpoint}`;

// Endpoint for contactData
export const contactDataEndpoint = '/myprofile/contactData';
export const contactDataURL = `${baseURL}${contactDataEndpoint}`;

// Endpoint for contactData
export const getcontactDataEndpoint = '/myprofile/getcontactData';
export const getcontactDataURL = `${baseURL}${getcontactDataEndpoint}`;

// Endpoint for contactData
export const socialDataEndpoint = '/social/socialData';
export const socialDataURL = `${baseURL}${socialDataEndpoint}`;

// Endpoint for contactData
export const getsocialDataEndpoint = '/social/getsocialData';
export const getsocialDataURL = `${baseURL}${getsocialDataEndpoint}`;

// Endpoint for contactData
export const revDataEndpoint = '/rev/revData';
export const revDataURL = `${baseURL}${revDataEndpoint}`;

// Endpoint for contactData
export const getrevDataEndpoint = '/rev/getrevData';
export const getrevDataURL = `${baseURL}${getrevDataEndpoint}`;

// Endpoint for contactData
export const calendarDataEndpoint = '/calendar/calendarData';
export const calendarDataURL = `${baseURL}${calendarDataEndpoint}`;

// Endpoint for contactData
export const getcalendarDataEndpoint = '/calendar/getcalendarData';
export const getcalendarDataURL = `${baseURL}${getcalendarDataEndpoint}`;



// Endpoint for backcolor
export const getbackcolorEndpoint = '/myprofile/getbackcolor';
export const getbackcolorURL = `${baseURL}${getbackcolorEndpoint}`;

// Endpoint for backcolor
export const postbackcolorEndpoint = '/myprofile/backcolor';
export const postbackcolorURL = `${baseURL}${postbackcolorEndpoint}`;


// Endpoint for userid
export const getuseridEndpoint = '/myprofile/getuserid';
export const getuseridURL = `${baseURL}${getuseridEndpoint}`;


