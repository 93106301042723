import React, { useState } from 'react';

const YourFormComponent = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [formData, setFormData] = useState({
    projectDescription: '',
    name: '',
    email: '',
    referralSource: '',
    ndaRequired: false,
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setSubmitStatus('Form submitted successfully!');
      } else {
        setSubmitStatus('Failed to submit form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('An unexpected error occurred. Please try again.');
    }
  };
  
  

  return (
    <div>
      <div style={{ position: 'relative', height: '90px', padding: '20px', marginBottom: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Input 1 */}
        <input
          type="text"
          placeholder="Project Description"
          style={{
            color: 'black',
            letterSpacing: '1px',
            fontSize: '.9rem',
            marginLeft: '60px',
            fontWeight: 200,
            padding: '10px',
            width: 'calc(100% - 130px)',
            height: '130px',
            border: '1px solid transparent',
            outline: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#f2f2f2',
          }}
          onFocus={(e) => (e.target.style.border = '2px solid blue')}
          onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        />
      </div>

      {/* Input 2 */}
      <div style={{ position: 'relative', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <label htmlFor="yourSelectId"></label>
        <select
          onChange={handleSelectChange}
          value={selectedValue}
          id="yourSelectId"
          style={{
            color: 'black',
            letterSpacing: '1px',
            fontSize: '.9rem',
            fontWeight: 200,
            marginLeft: '15px',
            padding: '10px',
            width: 'calc(100% - 110px)',
            height: '50px',
            border: '1px solid transparent',
            outline: 'none',
            backgroundColor: '#f2f2f2',
          }}
          onFocus={(e) => (e.target.style.border = '2px solid blue')}
          onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        >
          <option value="" disabled hidden>
            Project Description
          </option>
          <option value="Less than 50k" data-text="Less than 50k">
            Less than 50k
          </option>
          <option value="100k-250k" data-text="100k-250k">
            100k-250k
          </option>
          <option value="250k-500k" data-text="250k-500k">
            250k-500k
          </option>
          <option value="500k+" data-text="500k and above">
            500k and above
          </option>
        </select>
        <div
          style={{
            position: 'absolute',
            top: '12px',
            left: '70px',
            color: 'black',
            fontSize: '.9rem',
            fontWeight: 200,
            display: selectedValue ? 'none' : 'block',
          }}
        ></div>
      </div>

           {/* Input 3 */}
           <div style={{ position: 'relative', marginBottom: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Name"
          style={{
            color: 'black',
            letterSpacing: '1px',
            fontSize: '.9rem',
            marginLeft: '60px',
            fontWeight: 200,
            padding: '10px',
            width: 'calc(100% - 130px)',  // 40px is the total padding (20px left + 20px right)
            height: '30px',
            border: '1px solid transparent',
            outline: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#f2f2f2'
          }}
          onFocus={(e) => (e.target.style.border = '2px solid blue')}
          onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        />
      </div>

      {/* Input 4 */}
      <div style={{ position: 'relative', marginBottom: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Email"
          style={{
            color: 'black',
            letterSpacing: '1px',
            fontSize: '.9rem',
            marginLeft: '60px',
            fontWeight: 200,
            padding: '10px',
            width: 'calc(100% - 130px)',  // 40px is the total padding (20px left + 20px right)
            height: '30px',
            border: '1px solid transparent',
            outline: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#f2f2f2'
          }}
          onFocus={(e) => (e.target.style.border = '2px solid blue')}
          onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        />
      </div>
      {/* Input 5 */}
      <div style={{ position: 'relative', marginBottom: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="How did you hear about us?"
          style={{
            color: 'black',
            letterSpacing: '1px',
            fontSize: '.9rem',
            marginLeft: '60px',
            fontWeight: 200,
            padding: '10px',
            width: 'calc(100% - 130px)',  // 40px is the total padding (20px left + 20px right)
            height: 'auto', // Allow height to adjust based on content
            border: '1px solid transparent',
            outline: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#f2f2f2'
          }}
          onFocus={(e) => (e.target.style.border = '2px solid blue')}
          onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        />
      </div>
      <div>
        <input type="checkbox" id="myCheckbox" style={{ marginLeft: '60px' }} onChange={(e) => handleChange('ndaRequired', e.target.checked)} />
        <label htmlFor="myCheckbox">An NDA is required for this project.</label>
      </div>
    

      {/* Submit Button */}
      <div style={{ textAlign: 'center' }}>
        <button style={{ padding: '10px 20px', fontSize: '1rem' }} onClick={handleSubmit}>
          Submit
        </button>
        {submitStatus && <div style={{ marginTop: '10px', color: submitStatus.includes('failed') ? 'red' : 'green' }}>{submitStatus}</div>}
      </div>
    </div>
  );
};

export default YourFormComponent;

