import Picture from './Phone.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import React from 'react';

const Phone1 = ({ username, phoneImage, flexdeck, flexdeckimage, flexlabel }) => {
 

  
  // Function to handle the image click
  const handleImageClick = () => {
    const phoneData = localStorage.getItem('contactData');
    const phoneDataJSON = JSON.parse(phoneData);
    // Open the dial pad with the phone number
    window.location.href = `tel:${phoneDataJSON.phone}`;
  };

  return (
    <div>
      {/* Conditionally render the icon based on the isVisible prop */}
      {phoneImage && (
        <div className={flexdeck} onClick={handleImageClick}>
          <img
            src={Picture}
            alt="Icon"
            className={flexdeckimage}
          />
          <div className={flexlabel}>Phone</div>
        </div>
      )}
    </div>
  );
};

export default Phone1;
