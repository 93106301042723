import React, { useState } from 'react';
import logo512 from './logo512.png';
import { useLocation } from 'react-router-dom';
import { collection, doc, updateDoc, query, where, getDocs } from "firebase/firestore";
import { db } from '../firebaseconfig.js'; 
import { useNavigate } from 'react-router-dom';
import { useUser } from '../usercontext';

const Newpass = () => {
    const navigate = useNavigate();
    const { setUserName } = useUser();
    const location = useLocation();
    const email = new URLSearchParams(location.search).get('email');
    const [formData, setFormData] = useState({
        password: '',
        repeatPassword: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const fetchUserID = async (username, newPassword) => {
        const usersRef = collection(db, 'Users');
        const q = query(usersRef, where("username", "==", username));
        console.log('usernamev', username);
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // User document already exists, no need to create a new one
            const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per user
            const userData = userDoc.data();
            
            // Update the password field
            const userRef = doc(db, 'Users', userDoc.id);
            await updateDoc(userRef, {
                password: newPassword
            });
            sessionStorage.setItem('username', userData.username);
            setUserName(userData.username); // Set the username here
            navigate('/smartconsole1');
        } 
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form validation
        if (formData.password !== formData.repeatPassword) {
            alert("Passwords do not match");
            return;
        }

        // Call the asynchronous function
        fetchUserID(email, formData.password);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '300px', padding: '20px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '5px', textAlign: 'center' }}>
                <div>
                    <img src={logo512} alt="Logo" style={{ width: '90%' }} />
                    
                </div>
                <p style={{ marginBottom: '20px', fontWeight: 'bold', textAlign: 'left' }}>Reset Password</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Enter new password"
                        style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
                    />
                    <input
                        type="password"
                        name="repeatPassword"
                        value={formData.repeatPassword}
                        onChange={handleChange}
                        placeholder="Repeat new password"
                        style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
                    />
                    <button type="submit" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '60px', marginTop: '10px', padding: '5px', fontSize: '20px', backgroundColor: 'black', color: 'white', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Newpass;
