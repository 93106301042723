import Picture from './Email.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import React from 'react';

const Email1 = ({ username, emailImage,  flexdeck, flexdeckimage, flexlabel }) => {
  

  const handleImageClick = () => {
    const emailData = localStorage.getItem('contactData');
    const emailDataJSON = JSON.parse(emailData);
  
// Use mailto protocol if navigator.share is not supported
const link = `mailto:${emailDataJSON.email2}`;
window.location.href = link;
};

    
  return (
    <div>
      {/* Conditionally render the icon based on the isVisible prop */}
      { emailImage && (
         <div className={flexdeck} onClick={handleImageClick}>
        
          <img
            src={Picture}
            alt="Icon"
            className={flexdeckimage}
          />
          <div className={flexlabel}>Email</div>
        </div>
      )}
    </div>
  );
};

export default Email1;