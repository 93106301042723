
//allproducts1.js
import React, { useState, useEffect } from 'react';
import Backgroundimage1 from '../backgroundimage/backgroundimage1';
import ProfileImage1 from '../profileimage/profileimage1';
import ContactCard1 from '../contacts/contactcard1';
import Email1 from '../contacts/email';
import Phone1 from '../contacts/phone';
import Map1 from '../contacts/map';
import Whatsapp1 from '../contacts/whatsapp';
import Web1 from '../contacts/web';


import SocialCard1 from '../social/socialcard1.js';
import RevCard1 from '../rev/revcard1.js';
import CalendarCard1 from '../calendar/calendarcard1.js';

import Facebook1 from '../social/facebook';
import Instagram1 from '../social/instagram.js';
import Linkedln1 from '../social/linkedln.js';
import Snapchat1 from '../social/snapchat.js';
import Youtube1 from '../social/youtube.js';
import Twitter1 from '../social/twitter.js';
import Pinterest1 from '../social/pinterest.js';
import Twitch1 from '../social/twitch.js';
import Telegram1 from '../social/telegram.js';
import Discord1 from '../social/discord.js';
import Tumblr1 from '../social/tumblr.js';
import Reddit1 from '../social/reddit.js';
import Clubhouse1 from '../social/clubhouse.js';


import Airbnb1 from '../rev/airbnb.js';
import Amazonreview1 from '../rev/amazonreview.js';
import Trustpilot1 from '../rev/trustpilot.js';
import Ambitionbox1 from '../rev/ambitionbox.js';
import Booking1 from '../rev/booking.js';
import Cnet1 from '../rev/cnet.js';
import Facebookreview1 from '../rev/facebookreview.js';
import G21 from '../rev/g2.js';
import Glassdoor1 from '../rev/glassdoor.js';
import Goibibo1 from '../rev/goibibo.js';
import Googlereview1 from '../rev/googlereview.js';
import Indeed1 from '../rev/indeed.js';
import Makemytrip1 from '../rev/makemytrip.js';
import Oyo1 from '../rev/oyo.js';
import Techcrunch1 from '../rev/techcrunch.js';
import Yelp1 from '../rev/yelp.js';

import Applecalendar1 from '../calendar/applecalendar.js';
import Cal1 from '../calendar/cal.js';
import Calendly1 from '../calendar/calendly.js';
import Googlecalendar1 from '../calendar/googlecalendar.js';
import Outlookcalendar1 from '../calendar/outlookcalendar.js';
import Teams1 from '../calendar/teams.js';
import Zohocalendar1 from '../calendar/zohocalendar.js';
import Zoom1 from '../calendar/zoom.js';


//import Details1 from '../details/details1';
import Details2 from '../details/details2';
import QrImage1 from '../QRcode/qrcode1';
import QrImage2 from '../QRcodeProfile/qrcodeprofile1.js';
import './styles1.scss'; // Import the CSS file
import './Mainpopup22.scss'; // Import the CSS file
import './Subpopup2.css'; // Import the CSS file
import './share.css'; // Import the CSS file
import '../contacts/background2.scss';
import { baseURL, baseURLweb } from '../../../../../../../routes.mjs';
import sendImage from './send.png';
import { useParams } from 'react-router-dom';





function BottomNavigationBar({ username, handleShareButton }) {
  const [marginLeft, setMarginLeft] = useState('42vw'); // Default value for marginLeft

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 3000) {
        setMarginLeft('32vw'); // Adjust for 4K screens (above 3000px)
      } else if (window.innerWidth > 1440) {
        setMarginLeft('9vw'); // Adjust for laptops (1441px - 3000px)
      } else if (window.innerWidth > 1026) {
        setMarginLeft('13vw'); // Adjust for tablets (1027px - 1440px)
      } else if (window.innerWidth > 425) {
        setMarginLeft('42vw'); // Adjust for larger tablets (426px - 1026px)
      } else if (window.innerWidth >= 375) {
        setMarginLeft('40vw'); // Adjust for large mobile (375px - 425px)
      } else {
        setMarginLeft('42vw'); // Default to 42vw for smaller screens
      }
    };

    // Initial setup
    handleResize();

    // Event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="bottomNav">


      <div style={{ marginLeft }}>
        <button onClick={handleShareButton} className="navbutton"  >
          <img src={sendImage} alt="Home" style={{ width: '3vh', height: '3vh' }} />
          <span className="buttonlabel">Share</span>
        </button>
      </div>



    </div>
  );
}

const PreviewWebsiteClient = ({ qrImage, qrprofileImage }) => {

  const { username } = useParams();

  const [showContents, setShowContents] = useState(false);
  const [showsocialContents, setsocialShowContents] = useState(false);
  const [showrevContents, setrevShowContents] = useState(false);
  const [showcalendarContents, setcalendarShowContents] = useState(false);

  const [backgroundImage] = useState(null);
  const [profileImage] = useState(null);

  const [contactcardImage, setContactCardImage] = useState(false);
  const [phoneImage, setphoneImage] = useState(false);
  const [whatsappImage, setwhatsappImage] = useState(false);
  const [webImage, setwebImage] = useState(false);
  const [emailImage, setemailImage] = useState(false);
  const [mapImage, setmapImage] = useState(false);


  const [socialcardImage, setSocialCardImage] = useState(false);
  const [facebookImage, setfacebookImage] = useState(false);
  const [linkedlnImage, setlinkedlnImage] = useState(false);
  const [instagramImage, setinstagramImage] = useState(false);
  const [snapchatImage, setsnapchatImage] = useState(false);
  const [youtubeImage, setyoutubeImage] = useState(false);
  const [twitterImage, settwitterImage] = useState(false);
  const [pinterestImage, setpinterestImage] = useState(false);
  const [twitchImage, settwitchImage] = useState(false);
  const [telegramImage, settelegramImage] = useState(false);
  const [discordImage, setdiscordImage] = useState(false);
  const [tumblrImage, settumblrImage] = useState(false);
  const [redditImage, setredditImage] = useState(false);
  const [clubhouseImage, setclubhouseImage] = useState(false);

  const [revcardImage, setRevCardImage] = useState(false);
  const [trustpilotImage, settrustpilotImage] = useState(false);
  const [airbnbImage, setairbnbImage] = useState(false);
  const [amazonreviewImage, setamazonreviewImage] = useState(false);
  const [ambitionboxImage, setambitionboxImage] = useState(false);
  const [bookingImage, setbookingImage] = useState(false);
  const [cnetImage, setcnetImage] = useState(false);
  const [facebookreviewImage, setfacebookreviewImage] = useState(false);
  const [g2Image, setg2Image] = useState(false);
  const [glassdoorImage, setglassdoorImage] = useState(false);
  const [goibiboImage, setgoibiboImage] = useState(false);
  const [googlereviewImage, setgooglereviewImage] = useState(false);
  const [indeedImage, setindeedImage] = useState(false);
  const [makemytripImage, setmakemytripImage] = useState(false);
  const [oyoImage, setoyoImage] = useState(false);
  const [techcrunchImage, settechcrunchImage] = useState(false);
  const [yelpImage, setyelpImage] = useState(false);

  const [calendarcardImage, setCalendarCardImage] = useState(false);
  const [applecalendarImage, setapplecalendarImage] = useState(false);
  const [calImage, setcalImage] = useState(false);
  const [calendlyImage, setcalendlyImage] = useState(false);
  const [googlecalendarImage, setgooglecalendarImage] = useState(false);
  const [outlookcalendarImage, setoutlookcalendarImage] = useState(false);
  const [teamsImage, setteamsImage] = useState(false);
  const [zohocalendarImage, setzohocalendarImage] = useState(false);
  const [zoomImage, setzoomImage] = useState(false);



  const [ backgroundcolorData, setbackgroundcolorData ] = useState('#8240d4');

  const [contactName, setContactName] = useState('');
  const [contacttitle, setContactTitle] = useState('');
  const [contactcompany, setContactCompany] = useState('');


  const handleShareButton = () => {
    const shareData = {
      title: ``,
      text: ``,
      url: `${baseURLweb}/MyBusinesscard/${username}`,
    };

    if (navigator.share) {
      try {
        navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
        // If navigator.share is not available, provide an alternative sharing method
        openShareWindow(shareData);
      }
    } else {
      // If navigator.share is not available, provide an alternative sharing method
      openShareWindow(shareData);
    }
  };

  const openShareWindow = (shareData) => {
    const { title, url } = shareData;
    const shareUrl = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };

  useEffect(() => {
    const fetchData = async (username) => {
      try {
        const response = await fetch(`${baseURL}/updatelocal?username=${username}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'backcolor': 'true'
          }
        });
        const data = await response.json();


        setbackgroundcolorData(data.backcolor);

        localStorage.setItem('revData', JSON.stringify({
          googlereview: data.googlereview,
          trustpilot: data.trustpilot,
          ambitionbox: data.ambitionbox,
          g2: data.g2,
          glassdoor: data.glassdoor,
          amazonreview: data.amazonreview,
          indeed: data.indeed,
          booking: data.booking,
          goibibo: data.goibibo,
          makemytrip: data.makemytrip,
          facebookreview: data.facebookreview,
          cnet: data.cnet,
          yelp: data.yelp,
          techcrunch: data.techcrunch,
          airbnb: data.airbnb,
          oyo: data.oyo

        }));

        localStorage.setItem('contactData', JSON.stringify({
          name: data.name,
          email2: data.email2,
          phone: data.phone,
          title: data.title,
          company: data.company,
          bio: data.bio,
          whatsapp: data.whatsapp,
          web: data.web,
          address: data.address
        }));

        localStorage.setItem('socialData', JSON.stringify({
          facebook: data.facebook,
          instagram: data.instagram,
          linkedln: data.linkedln,
          snapchat: data.snapchat,
          youtube: data.youtube,
          twitter: data.twitter,
          pinterest: data.pinterest,
          twitch: data.twitch,
          telegram: data.telegram,
          discord: data.discord,
          tumblr: data.tumblr,
          reddit: data.reddit,
          clubhouse: data.clubhouse
        }));

        localStorage.setItem('calendarData', JSON.stringify({
          teams: data.teams,
          zohocalendar: data.zohocalendar,
          googlecalendar: data.googlecalendar,
          outlookcalendar: data.outlookcalendar,
          calendly: data.calendly,
          cal: data.cal,
          applecalendar: data.applecalendar,
          zoom: data.zoom,

        }));


        // Check if contactData is not present in localStorage
        const socialData = JSON.parse(localStorage.getItem('socialData'));

        // Check if contactData is not present in localStorage
        const contactData = JSON.parse(localStorage.getItem('contactData'));

        // Check if contactData is not present in localStorage
        const revData = JSON.parse(localStorage.getItem('revData'));

        // Check if contactData is not present in localStorage
        const calendarData = JSON.parse(localStorage.getItem('calendarData'));









        // Check if contactData exists and is an object
        if (typeof revData === 'object') {
          // Access the phone number
          const googlereview = revData.googlereview;
          const trustpilot = revData.trustpilot;
          const ambitionbox = revData.ambitionbox;
          const g2 = revData.g2;
          const glassdoor = revData.glassdoor;
          const amazonreview = revData.amazonreview;
          const indeed = revData.indeed;
          const booking = revData.booking;
          const goibibo = revData.goibibo;
          const makemytrip = revData.makemytrip;
          const facebookreview = revData.facebookreview;
          const cnet = revData.cnet;
          const yelp = revData.yelp;
          const techcrunch = revData.techcrunch;
          const airbnb = revData.airbnb;
          const oyo = revData.oyo;




          if (!revcardImage) {
            setRevCardImage(true);
          }

          if (googlereview !== null && googlereview !== " " && googlereview !== '' && googlereview !== undefined) {
            if (!googlereviewImage) {
              setgooglereviewImage(true);
            }
          } else {
            if (googlereviewImage) {
              setgooglereviewImage(false);
            }
          }

          if (trustpilot !== null && trustpilot !== " " && trustpilot !== '' && trustpilot !== undefined) {
            if (!trustpilotImage) {
              settrustpilotImage(true);
            }
          } else {
            if (trustpilotImage) {
              settrustpilotImage(false);
            }
          }

          if (ambitionbox !== null && ambitionbox !== " " && ambitionbox !== '' && ambitionbox !== undefined) {
            if (!ambitionboxImage) {
              setambitionboxImage(true);
            }
          } else {
            if (ambitionboxImage) {
              setambitionboxImage(false);
            }
          }

          if (g2 !== null && g2 !== " " && g2 !== '' && g2 !== undefined) {
            if (!g2Image) {
              setg2Image(true);
            }
          } else {
            if (g2Image) {
              setg2Image(false);
            }
          }

          if (glassdoor !== null && glassdoor !== " " && glassdoor !== '' && glassdoor !== undefined) {
            if (!glassdoorImage) {
              setglassdoorImage(true);
            }
          } else {
            if (glassdoorImage) {
              setglassdoorImage(false);
            }
          }

          if (amazonreview !== null && amazonreview !== " " && amazonreview !== '' && amazonreview !== undefined) {
            if (!amazonreviewImage) {
              setamazonreviewImage(true);
            }
          } else {
            if (amazonreviewImage) {
              setamazonreviewImage(false);
            }
          }

          if (indeed !== null && indeed !== " " && indeed !== '' && indeed !== undefined) {
            if (!indeedImage) {
              setindeedImage(true);
            }
          } else {
            if (indeedImage) {
              setindeedImage(false);
            }
          }

          if (booking !== null && booking !== " " && booking !== '' && booking !== undefined) {
            if (!bookingImage) {
              setbookingImage(true);
            }
          } else {
            if (bookingImage) {
              setbookingImage(false);
            }
          }

          if (goibibo !== null && goibibo !== " " && goibibo !== '' && goibibo !== undefined) {
            if (!goibiboImage) {
              setgoibiboImage(true);
            }
          } else {
            if (goibiboImage) {
              setgoibiboImage(false);
            }
          }

          if (makemytrip !== null && makemytrip !== " " && makemytrip !== '' && makemytrip !== undefined) {
            if (!makemytripImage) {
              setmakemytripImage(true);
            }
          } else {
            if (makemytripImage) {
              setmakemytripImage(false);
            }
          }

          if (facebookreview !== null && facebookreview !== " " && facebookreview !== '' && facebookreview !== undefined) {
            if (!facebookreviewImage) {
              setfacebookreviewImage(true);
            }
          } else {
            if (facebookreviewImage) {
              setfacebookreviewImage(false);
            }
          }

          if (cnet !== null && cnet !== " " && cnet !== '' && cnet !== undefined) {
            if (!cnetImage) {
              setcnetImage(true);
            }
          } else {
            if (cnetImage) {
              setcnetImage(false);
            }
          }

          if (yelp !== null && yelp !== " " && yelp !== '' && yelp !== undefined) {
            if (!yelpImage) {
              setyelpImage(true);
            }
          } else {
            if (yelpImage) {
              setyelpImage(false);
            }
          }

          if (techcrunch !== null && techcrunch !== " " && techcrunch !== '' && techcrunch !== undefined) {
            if (!techcrunchImage) {
              settechcrunchImage(true);
            }
          } else {
            if (techcrunchImage) {
              settechcrunchImage(false);
            }
          }

          if (airbnb !== null && airbnb !== " " && airbnb !== '' && airbnb !== undefined) {
            if (!airbnbImage) {
              setairbnbImage(true);
            }
          } else {
            if (airbnbImage) {
              setairbnbImage(false);
            }
          }

          if (oyo !== null && oyo !== " " && oyo !== '' && oyo !== undefined) {
            if (!oyoImage) {
              setoyoImage(true);
            }
          } else {
            if (oyoImage) {
              setoyoImage(false);
            }
          }


        } else {
          if (revcardImage) {
            setRevCardImage(false);
          }
          console.log('contactData is not available or not an object');
        }



        // Check if contactData exists and is an object
        if (typeof calendarData === 'object') {
          // Access the phone number
          const teams = calendarData.teams;
          const zohocalendar = calendarData.zohocalendar;
          const googlecalendar = calendarData.googlecalendar;
          const outlookcalendar = calendarData.outlookcalendar;
          const calendly = calendarData.calendly;
          const cal = calendarData.cal;
          const applecalendar = calendarData.applecalendar;
          const zoom = calendarData.zoom;



          if (!calendarcardImage) {
            setCalendarCardImage(true);
          }
          if (zohocalendar !== null && zohocalendar !== " " && zohocalendar !== '' && zohocalendar !== undefined) {
            if (!zohocalendarImage) {
              setzohocalendarImage(true);
            }
          } else {
            if (zohocalendarImage) {
              setzohocalendarImage(false);
            }
          }

          if (teams !== null && teams !== " " && teams !== '' && teams !== undefined) {
            if (!teamsImage) {
              setteamsImage(true);
            }
          } else {
            if (teamsImage) {
              setteamsImage(false);
            }
          }

          if (googlecalendar !== null && googlecalendar !== " " && googlecalendar !== '' && googlecalendar !== undefined) {
            if (!googlecalendarImage) {
              setgooglecalendarImage(true);
            }
          } else {
            if (googlecalendarImage) {
              setgooglecalendarImage(false);
            }
          }

          if (outlookcalendar !== null && outlookcalendar !== " " && outlookcalendar !== '' && outlookcalendar !== undefined) {
            if (!outlookcalendarImage) {
              setoutlookcalendarImage(true);
            }
          } else {
            if (outlookcalendarImage) {
              setoutlookcalendarImage(false);
            }
          }

          if (calendly !== null && calendly !== " " && calendly !== '' && calendly !== undefined) {
            if (!calendlyImage) {
              setcalendlyImage(true);
            }
          } else {
            if (calendlyImage) {
              setcalendlyImage(false);
            }
          }

          if (cal !== null && cal !== " " && cal !== '' && cal !== undefined) {
            if (!calImage) {
              setcalImage(true);
            }
          } else {
            if (calImage) {
              setcalImage(false);
            }
          }

          if (applecalendar !== null && applecalendar !== " " && applecalendar !== '' && applecalendar !== undefined) {
            if (!applecalendarImage) {
              setapplecalendarImage(true);
            }
          } else {
            if (applecalendarImage) {
              setapplecalendarImage(false);
            }
          }

          if (zoom !== null && zoom !== " " && zoom !== '' && zoom !== undefined) {
            if (!zoomImage) {
              setzoomImage(true);
            }
          } else {
            if (zoomImage) {
              setzoomImage(false);
            }
          }


        } else {
          if (calendarcardImage) {
            setCalendarCardImage(false);
          }
          console.log('contactData is not available or not an object');
        }


        // Check if contactData exists and is an object
        if (typeof socialData === 'object') {
          // Access the phone number
          const facebook = socialData.facebook;
          const instagram = socialData.instagram;
          const linkedln = socialData.linkedln;
          const snapchat = socialData.snapchat;
          const youtube = socialData.youtube;
          const twitter = socialData.twitter;
          const pinterest = socialData.pinterest;
          const twitch = socialData.twitch;
          const telegram = socialData.telegram;
          const discord = socialData.discord;
          const tumblr = socialData.tumblr;
          const reddit = socialData.reddit;
          const clubhouse = socialData.clubhouse;



          if (!socialcardImage) {
            setSocialCardImage(true);
          }

          if (facebook !== null && facebook !== " " && facebook !== '' && facebook !== undefined) {
            if (!facebookImage) {
              setfacebookImage(true);
            }
          } else {
            if (facebookImage) {
              setfacebookImage(false);

            }
          }

          if (instagram !== null && instagram !== " " && instagram !== '' && instagram !== undefined) {
            if (!instagramImage) {
              setinstagramImage(true);
            }
          } else {
            if (instagramImage) {
              setinstagramImage(false);

            }
          }


          if (linkedln !== null && linkedln !== " " && linkedln !== '' && linkedln !== undefined) {
            if (!linkedlnImage) {
              setlinkedlnImage(true);
            }
          } else {
            if (linkedlnImage) {
              setlinkedlnImage(false);

            }
          }

          if (snapchat !== null && snapchat !== " " && snapchat !== '' && snapchat !== undefined) {
            if (!snapchatImage) {
              setsnapchatImage(true);
            }
          } else {
            if (snapchatImage) {
              setsnapchatImage(false);

            }
          }

          if (youtube !== null && youtube !== " " && youtube !== '' && youtube !== undefined) {
            if (!youtubeImage) {
              setyoutubeImage(true);
            }
          } else {
            if (youtubeImage) {
              setyoutubeImage(false);

            }
          }

          if (twitter !== null && twitter !== " " && twitter !== '' && twitter !== undefined) {
            if (!twitterImage) {
              settwitterImage(true);
            }
          } else {
            if (twitterImage) {
              settwitterImage(false);

            }
          }

          if (pinterest !== null && pinterest !== " " && pinterest !== '' && pinterest !== undefined) {
            if (!pinterestImage) {
              setpinterestImage(true);
            }
          } else {
            if (pinterestImage) {
              setpinterestImage(false);

            }
          }

          if (twitch !== null && twitch !== " " && twitch !== '' && twitch !== undefined) {
            if (!twitchImage) {
              settwitchImage(true);
            }
          } else {
            if (twitchImage) {
              settwitchImage(false);

            }
          }

          if (telegram !== null && telegram !== " " && telegram !== '' && telegram !== undefined) {
            if (!telegramImage) {
              settelegramImage(true);
            }
          } else {
            if (telegramImage) {
              settelegramImage(false);

            }
          }

          if (discord !== null && discord !== " " && discord !== '' && discord !== undefined) {
            if (!discordImage) {
              setdiscordImage(true);
            }
          } else {
            if (discordImage) {
              setdiscordImage(false);

            }
          }

          if (tumblr !== null && tumblr !== " " && tumblr !== '' && tumblr !== undefined) {
            if (!tumblrImage) {
              settumblrImage(true);
            }
          } else {
            if (tumblrImage) {
              settumblrImage(false);

            }
          }

          if (reddit !== null && reddit !== " " && reddit !== '' && reddit !== undefined) {
            if (!redditImage) {
              setredditImage(true);
            }
          } else {
            if (redditImage) {
              setredditImage(false);

            }
          }

          if (clubhouse !== null && clubhouse !== " " && clubhouse !== '' && clubhouse !== undefined) {
            if (!clubhouseImage) {
              setclubhouseImage(true);
            }
          } else {
            if (clubhouseImage) {
              setclubhouseImage(false);

            }
          }

        } else {
          if (socialcardImage) {
            setSocialCardImage(false);
          }
          console.log('contactData is not available or not an object');
        }





        // Check if contactData exists and is an object
        if (contactData && typeof contactData === 'object') {
          // Access the phone number
          const phone = contactData.phone;
          const web = contactData.web;
          const whatsapp = contactData.whatsapp;
          const email = contactData.email2;
          const map = contactData.address;
          setContactName(contactData.name);
          setContactTitle(contactData.title);
          setContactCompany(contactData.company);


          if (!contactcardImage) {
            setContactCardImage(true);
          }

          if (phone !== null && phone !== '' && phone !== undefined) {
            if (!phoneImage) {
              setphoneImage(true);
            }
          } else {
            if (phoneImage) {
              setphoneImage(false);
            }
          }

          if (email !== null && email !== '' && email !== undefined) {
            if (!emailImage) {
              setemailImage(true);
            }
          } else {
            if (emailImage) {
              setemailImage(false);
            }
          }

          if (web !== null && web !== '' && web !== undefined) {
            if (!webImage) {
              setwebImage(true);
            }
          } else {
            if (webImage) {
              setwebImage(false);
            }
          }

          if (map !== null && map !== '' && map !== undefined) {
            if (!mapImage) {
              setmapImage(true);
            }
          } else {
            if (mapImage) {
              setmapImage(false);
            }
          }

          if (whatsapp !== null && whatsapp !== '' && whatsapp !== undefined) {
            if (!whatsappImage) {
              setwhatsappImage(true);
            }
          } else {
            if (whatsappImage) {
              setwhatsappImage(false);
            }
          }
        } else {
          if (contactcardImage) {
            setContactCardImage(false);
          }
        }

      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };

    fetchData(username); // Fetch data on mount or when username changes
  }, [username, contactcardImage, emailImage, mapImage, phoneImage, webImage, whatsappImage, facebookImage, socialcardImage, instagramImage, linkedlnImage,
    snapchatImage, youtubeImage, twitterImage, pinterestImage, twitchImage, telegramImage, discordImage, tumblrImage, redditImage, clubhouseImage,
    teamsImage, zohocalendarImage, googlecalendarImage, outlookcalendarImage, calendlyImage, calImage, applecalendarImage, zoomImage, calendarcardImage,
    revcardImage, googlereviewImage, trustpilotImage, ambitionboxImage, g2Image, glassdoorImage, amazonreviewImage, indeedImage, bookingImage, goibiboImage, makemytripImage, facebookreviewImage, cnetImage, yelpImage, techcrunchImage, airbnbImage, oyoImage
  ]); // Ensure useEffect runs only when username changes









  const [loading, setLoading] = useState(true);

  // Simulate data fetching
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Data is now loaded
    }, 2300); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);





  return (
<div>
    {loading ? (
      <div className="loading-screen2">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    ) : (
    <div className="mobile-view-container2">



        
      <div className="taskbar2" >

        <div onClick={() => window.open("http://www.smartcardai.com", "_blank")} style={{
          cursor: 'pointer'
        }}>
          <img src="/logo512.png" alt="Logo" style={{ height: '4vh', marginTop: '1.2vh', marginLeft: '0.2vw' }} />
        </div>


      </div >

      <BottomNavigationBar username={username} handleShareButton={handleShareButton} />




      <div className="mobile-view-container" style={{ backgroundColor: "#e6fcf8" }}>




        <div >
          <Backgroundimage1 backgroundImage={backgroundImage} username={username} />
        </div>
        <div className="deckcontactbutton1">
          <QrImage1 qrImage={qrImage} username={username} />
        </div>
        <div className="deck1">
          <ProfileImage1 profileImage={profileImage} username={username} />
        </div>
        <div className="deck4">
          <QrImage2 qrprofileImage={qrprofileImage} username={username} />
        </div>

        <div className="deck5" style={{ backgroundColor: backgroundcolorData || '#9403fc' }} >

          <Details2 username={username} contactName={contactName} contacttitle={contacttitle} contactcompany={contactcompany} />

        </div>





        <div className="iconsposition"   >

          <div >

            <div className="mainpopupsection-header22" >
              {contactcardImage && (<h3 className="h3">CONTACT</h3>)}
            </div>

            <div className="grid-container">

              {contactcardImage && (<div className="grid-item">
                <ContactCard1 showContents={showContents} setShowContents={setShowContents} contactcardImage={contactcardImage} username={username} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" popup51="popup51" popupcontent51="popupcontent51" />
              </div>)}
              {phoneImage && (<div className="grid-item">
                <Phone1 phoneImage={phoneImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}
              {whatsappImage && (<div className="grid-item">
                <Whatsapp1 whatsappImage={whatsappImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {emailImage && (<div className="grid-item">
                <Email1 emailImage={emailImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}


            </div>


            <div className="grid-container">

              {webImage && (<div className="grid-item">
                <Web1 webImage={webImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}


              {mapImage && (<div className="grid-item">
                <Map1 mapImage={mapImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

            </div>


            <div className="mainpopupsection-header22" >
              {socialcardImage && (<h3 className="h3">SOCIAL</h3>)}
            </div>

            <div className="grid-container">

              {socialcardImage && (<div className="grid-item">
                <SocialCard1 showsocialContents={showsocialContents} setsocialShowContents={setsocialShowContents} socialcardImage={socialcardImage} username={username} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" popup51="popup51" popupcontent51="popupcontent51" />
              </div>)}


              {facebookImage && (<div className="grid-item">
                <Facebook1 facebookImage={facebookImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {instagramImage && (<div className="grid-item">
                <Instagram1 instagramImage={instagramImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {linkedlnImage && (<div className="grid-item">
                <Linkedln1 linkedlnImage={linkedlnImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}


            </div>

            <div className="grid-container">

              {snapchatImage && (<div className="grid-item">
                <Snapchat1 snapchatImage={snapchatImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {youtubeImage && (<div className="grid-item">
                <Youtube1 youtubeImage={youtubeImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {twitterImage && (<div className="grid-item">
                <Twitter1 twitterImage={twitterImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {pinterestImage && (<div className="grid-item">
                <Pinterest1 pinterestImage={pinterestImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

            </div>

            <div className="grid-container">

              {twitchImage && (<div className="grid-item">
                <Twitch1 twitchImage={twitchImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {telegramImage && (<div className="grid-item">
                <Telegram1 telegramImage={telegramImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {discordImage && (<div className="grid-item">
                <Discord1 discordImage={discordImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {tumblrImage && (<div className="grid-item">
                <Tumblr1 tumblrImage={tumblrImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

            </div>


            <div className="grid-container">

              {redditImage && (<div className="grid-item">
                <Reddit1 redditImage={redditImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}

              {clubhouseImage && (<div className="grid-item">
                <Clubhouse1 clubhouseImage={clubhouseImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
              </div>)}


            </div>


            <div className="mainpopupsection-header22" >
              {revcardImage && (<h3 className="h3">REVIEW</h3>)}
            </div>

            <div className="grid-container">

              {revcardImage && (<div className="grid-item">
                <RevCard1 showrevContents={showrevContents} setrevShowContents={setrevShowContents} revcardImage={revcardImage} username={username} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" popup51="popup51" popupcontent51="popupcontent51" />
              </div>)}


              {googlereviewImage && (
                <div className="grid-item">
                  <Googlereview1 googlereviewImage={googlereviewImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {trustpilotImage && (
                <div className="grid-item">
                  <Trustpilot1 trustpilotImage={trustpilotImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}



              {g2Image && (
                <div className="grid-item">
                  <G21 g2Image={g2Image} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

            </div>

            <div className="grid-container">
              


              {glassdoorImage && (
                <div className="grid-item">
                  <Glassdoor1 glassdoorImage={glassdoorImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {amazonreviewImage && (
                <div className="grid-item">
                  <Amazonreview1 amazonreviewImage={amazonreviewImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {indeedImage && (
                <div className="grid-item">
                  <Indeed1 indeedImage={indeedImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {yelpImage && (
                <div className="grid-item">
                  <Yelp1 yelpImage={yelpImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

            </div>

            <div className="grid-container">

              {bookingImage && (
                <div className="grid-item">
                  <Booking1 bookingImage={bookingImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {goibiboImage && (
                <div className="grid-item">
                  <Goibibo1 goibiboImage={goibiboImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

           

              {cnetImage && (
                <div className="grid-item">
                  <Cnet1 cnetImage={cnetImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              

{facebookreviewImage && (
                <div className="grid-item">
                  <Facebookreview1 facebookreviewImage={facebookreviewImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}


            </div>

            

            <div className="grid-container">

            {airbnbImage && (
                <div className="grid-item">
                  <Airbnb1 airbnbImage={airbnbImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {makemytripImage && (
                <div className="grid-item">
                  <Makemytrip1 makemytripImage={makemytripImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

            

             
             
{oyoImage && (
                <div className="grid-item">
                  <Oyo1 oyoImage={oyoImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}


            </div>


            <div className="grid-container">

            {techcrunchImage && (
                <div className="grid-item">
                  <Techcrunch1 techcrunchImage={techcrunchImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}


              {ambitionboxImage && (
                <div className="grid-item">
                  <Ambitionbox1 ambitionboxImage={ambitionboxImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}



            </div>


            <div className="mainpopupsection-header22" >
              {calendarcardImage && (<h3 className="h3">BOOK A MEETING</h3>)}
            </div>

            <div className="grid-container">

              {calendarcardImage && (<div className="grid-item">
                <CalendarCard1 showcalendarContents={showcalendarContents} setcalendarShowContents={setcalendarShowContents} calendarcardImage={calendarcardImage} username={username} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" popup51="popup51" popupcontent51="popupcontent51" />
              </div>)}


              {zohocalendarImage && (
                <div className="grid-item">
                  <Zohocalendar1 zohocalendarImage={zohocalendarImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {googlecalendarImage && (
                <div className="grid-item">
                  <Googlecalendar1 googlecalendarImage={googlecalendarImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {teamsImage && (
                <div className="grid-item">
                  <Teams1 teamsImage={teamsImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}


            </div>

            <div className="grid-container">
              {outlookcalendarImage && (
                <div className="grid-item">
                  <Outlookcalendar1 outlookcalendarImage={outlookcalendarImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {calendlyImage && (
                <div className="grid-item">
                  <Calendly1 calendlyImage={calendlyImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {calImage && (
                <div className="grid-item">
                  <Cal1 calImage={calImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

              {applecalendarImage && (
                <div className="grid-item">
                  <Applecalendar1 applecalendarImage={applecalendarImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

            </div>

            <div className="grid-container">

              {zoomImage && (
                <div className="grid-item">
                  <Zoom1 zoomImage={zoomImage} flexdeck="flexdeck" flexdeckimage="flexdeckimage" flexlabel="flexlabel" />
                </div>
              )}

            </div>



          </div>
         
        
        </div>
     
      </div>
      </div>
      )}
    </div>
  


  );
};

export default PreviewWebsiteClient;
