// myprofile2.js

import React, { useState, Suspense }  from 'react';
//import Instagram1 from './channels/Instagram/instagram1';
//import Facebook1 from './channels/facebook/facebook1';
import Backgroundimage1 from './channels/backgroundimage/backgroundimage1';
import BackgroundColor1 from './channels/backgroundcolor/backgroundcolor1';
import ProfileImage1 from './channels/profileimage/profileimage1';
import ContactCard1 from './channels/contacts/contactcard1';
import MobileViewAdmin1 from './channels/mobileview/mobileviewadmin1';
import './MyProfile2.css'; // Import the CSS file
import './MyProfile22.css'; // Import the CSS file



const Loading = () => <div>Loading...</div>;




const MyProfile2 = ({ username}) => {



  const [selectedColor, setSelectedColor] = useState();

  const [backgroundImage, setBackgroundImage] = useState(null); // State to store background image
  const [profileImage, setProfileImage] = useState(null); // State to store background image
  const [contactcardImage, setContactCardImage] = useState(false); // State to store contact card image
  const [qrImage, setQrImage] = useState(true);
  const [qrprofileImage, setProfileQrImage] = useState(true);
  const [isMainPopupVisible, setIsMainPopupVisible] = useState(false);
 
 

  const handleMainPopUpClose =() => {
    setIsMainPopupVisible(false);
  };


  const handleBackgroundImageChange = (event) => {
    const file = event.target.files[0];
    setBackgroundImage(URL.createObjectURL(file));
  };


  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    // Perform validation if needed
    setProfileImage(URL.createObjectURL(file));
  };


  const handleContactAdd = (contactData) => {
    setContactCardImage(contactData); 
};



   // Function to handle color change
   const handleColorChange = (color) => {
    setSelectedColor(color);
    
  };


  


  return (
    
    

    <div style={{ overflowY:'hidden' , overflowX: 'hidden'}}>
      
     


      <MobileViewAdmin1
        username={username}
        selectedColor={selectedColor}
        backgroundImage={backgroundImage}
        profileImage={profileImage}
        contactcardImage={contactcardImage}
        handleContactAdd={handleContactAdd}
        setContactCardImage={setContactCardImage} 
        qrImage={qrImage}
        setQrImage ={setQrImage}
        qrprofileImage={qrprofileImage}
        setProfileQrImage={setProfileQrImage}  
      />



      {isMainPopupVisible && (
        <div className="popup22">
        <button className="close-button" onClick={handleMainPopUpClose}>x</button>
        
    

         <div className="popup-content22" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          
         
             
            <div className="popupsection-header22">
            <h2>Styles</h2>
            </div>


            <Suspense fallback={<Loading />}>
            <div style={{  display: 'flex', alignItems: 'center', width:'70%' , marginLeft:'10px'}}>
            
              <Backgroundimage1
                username={username}
                handleBackgroundImageChange={handleBackgroundImageChange}
                isVisible={isMainPopupVisible}
              />
              

              <ProfileImage1
                username={username}
                handleProfileImageChange={handleProfileImageChange}
                isVisible={isMainPopupVisible}
              />

              <BackgroundColor1
                username={username}
                handleColorChange={handleColorChange}
                isVisible={isMainPopupVisible}
              />

            </div>

            </Suspense>


            <div className="popupsection-header">
              <h2>Contacts</h2>
            </div>

            <Suspense fallback={<Loading />}>
            <div style={{ display: 'flex', alignItems: 'center', width:'70%' , marginLeft:'40px'}}>


              <ContactCard1
                username={username}
                handleContactAdd={handleContactAdd}
                
                setContactCardImage={setContactCardImage} 
                isVisible={isMainPopupVisible}
              />

            </div>
            </Suspense>

          </div>
        </div>
      )}
    </div>
   
  );
};

export default MyProfile2;