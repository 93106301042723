import Picture from './Map.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import React from 'react';

const Map1 = ({ username, mapImage,  flexdeck, flexdeckimage, flexlabel }) => {



  // Function to handle the image click
  const handleImageClick = () => {
    
    const contactData = localStorage.getItem('contactData');
  const contactDataJSON = JSON.parse(contactData);
 
    // Open Google Maps with the address from state
    window.open(`https://www.google.com/maps/place/?q=${encodeURIComponent(contactDataJSON.address)}`, '_blank');
    
   
  };

 
 
 
    
  return (
    <div>
      {/* Conditionally render the icon based on the isVisible prop */}
      { mapImage && (
         <div className={flexdeck} onClick={handleImageClick}>
          <img
            src={Picture}
            alt="Icon"
            className={flexdeckimage}
          />
          <div className={flexlabel}>Map</div>
        </div>
      )}
    </div>
  );
};

export default Map1;