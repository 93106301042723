import React, { useState, useEffect, useRef } from 'react';
import Picture from './paint.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import { postbackcolorURL, getbackcolorURL} from '../../../../../../../routes.mjs';

const BackgroundColor1 = ({ username, flexpopdeckimage, flexpoplabel, flexpoptopimage,flexpoptoplabel }) => {
    const [showContents, setShowContents] = useState(false);
    const [selectedColor, setSelectedColor] = useState('black'); // Initialize with default color
    const colorPickerRef = useRef(null); // Reference for color picker
    

    const handleColorChange = (color) => {
        setSelectedColor(color);
      };
    const handleIconClick = () => {
        setShowContents(true);
    };

    const handleCloseButtonClick = () => {
        setShowContents(false);
    };

    const handleColorPickerChange = (e) => {
        const newColor = e.target.value;
        setSelectedColor(newColor);
        handleColorChange(newColor); // Call handleColorChange to update color elsewhere in the app
        localStorage.setItem('backgroundcolor', JSON.stringify(newColor));
        setShowContents(true); // Show content when color changes
    };

    const handleSelectChange = (e) => {
        setSelectedColor(e.target.value);
        handleColorChange(e.target.value); // Call handleColorChange to update color elsewhere in the app
        localStorage.setItem('backgroundcolor', JSON.stringify(e.target.value));
        setShowContents(true); // Show content when color changes
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

   

    useEffect(() => {
        if (showContents) {
            const currentColorPickerRef = colorPickerRef.current; // Store the current value of the ref
            currentColorPickerRef.addEventListener('click', stopPropagation); // Add event listener to stop propagation for color picker
            return () => {
                currentColorPickerRef.removeEventListener('click', stopPropagation); // Remove event listener when component unmounts
            };
        }
    }, [showContents]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${getbackcolorURL}?username=${username}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'backcolor': 'true'
              }
            });
            const data = await response.json();
            setSelectedColor(data.backcolor);
          } catch (error) {
            console.error('An error occurred while fetching background color:', error);
          }
        };
    
        fetchData();
      }, [username]);
    
      useEffect(() => {
        const addFunction = async (username, color) => {
          try {
            if (!username) {
              console.log("User is not logged in.");
              return;
            }
      
            const backcolor = color;
      
            const response = await fetch(postbackcolorURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'backcolor': 'true'
              },
              body: JSON.stringify({ username, backcolor }),
            });
      
            await response.json();
          } catch (error) {
            console.error('An error occurred:', error);
          }
        };
      
        if (selectedColor) {
          addFunction(username, selectedColor);
        }
      }, [username, selectedColor]);
  

    return (
        <div>
          <div style={{ display: 'flex'}}>
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
               <img
                   src={Picture}
                   alt="Icon"
                   className={flexpopdeckimage}
                   onClick={handleIconClick}
               />
               <div className={flexpoplabel}>Color</div>
               </div>
           </div>
            {showContents && (
                <div className="popupcolor2">
                    <div style={{ display: 'flex', marginLeft: '44.5vw', marginTop: '4vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>

                            <img
                                src={Picture}
                                alt="Icon"
                                //className={flexpopdeckimage}
                                className="flexpoptopbackimage"

                            />

                            <div className="flexpoptopbacklabel">Color</div>
                        </div>
                    </div>
                    

                    <div className="popupcolor-content2">
                   
                    <div className="inputcolor">
                    <div style={{ display: 'flex', flexDirection:'row'}}>
                        <label className="colorPicker">Pick a Color: </label>
                        <input
                            type="color"
                            id="colorPicker"
                            value={selectedColor}
                            className="box"
                            onChange={handleColorPickerChange}
                            ref={colorPickerRef}
                        />
                         </div>
                         
                        <div style={{paddingTop:'3vh', display: 'flex', flexDirection:'row'}}>
                        <label className="colorSelect">Select Color: </label>
                        <select id="colorSelect" className="box" value={selectedColor} onChange={handleSelectChange}>
                            <option  className="droplabel" value="white">White</option>
                            <option  className="droplabel" value="yellow">Yellow</option>
                            <option  className="droplabel" value="blue">Blue</option>
                        </select>
                        </div>
                        </div>
                        <button  className="popupcolorclose-button42" onClick={handleCloseButtonClick}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BackgroundColor1;






