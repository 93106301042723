// firebaseconfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"; // Import getFirestore function

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_AhT8wRd8Xk4X1jbEBldk3qC4TOIUrXk",
  authDomain: "commission-83ab7.firebaseapp.com",
  projectId: "commission-83ab7",
  storageBucket: "commission-83ab7.appspot.com",
  messagingSenderId: "399705235160",
  appId: "1:399705235160:web:a9b84e44580c83036cc1ad",
  measurementId: "G-571LFY1CE9"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase storage
const imageDb = getStorage(app);

// Initialize Firestore database
const db = getFirestore(app);

export { imageDb};  // Export imageDb and db
export { db };
export default app;