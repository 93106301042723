import React, { useState, useEffect, useCallback } from 'react';
import { signupURL } from '../routes.mjs';
import logo512 from './logo512.png';
import { collection, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebaseconfig.js'; 
import goog from './goog.png';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../usercontext';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { setUserName } = useUser();

  // Effect to clear error and success message state when the form data changes
  useEffect(() => {
    setError('');
    setSuccessMessage('');
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleResetPassword = () => {
    // Navigate to the Reset.js page
    navigate('/Reset1');
   };
 
   const handleSignin = () => {
     // Define the action to create account here
 
     navigate('/Login1');
   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Set loading state
    setLoading(true);

    try {
      const response = await fetch(signupURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        // Clear the error state after successful signup
        setError('');
        // Set the success message
        setSuccessMessage(data.message);

        // Fetch user ID after successful signup
        fetchUserID(formData.username);
      } else {
        setError(data.error || 'Registration failed');
      }
    } catch (error) {
      console.error('Error during registration', error);
      setError('Something went wrong');
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    const googleClientId = '614102256594-lpbqkqjfshlmvk283403dkdi6mbh9la0.apps.googleusercontent.com';
    const googleAuthEndpoint = 'https://accounts.google.com/o/oauth2/auth';
    const redirectUri = 'https://www.smartcardai.com/Login1'; // Update with your redirect URI
    const authUrl = `${googleAuthEndpoint}?client_id=${googleClientId}&redirect_uri=${redirectUri}&response_type=code&scope=email%20profile`;
    window.location.assign(authUrl);
  };

  const fetchUserID = useCallback(async (username) => {
    try {
      const usersRef = collection(db, 'Users');
      const q = query(usersRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // User document already exists, no need to create a new one
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per user
        const userData = userDoc.data();
        sessionStorage.setItem('username', userData.username);
        setUserName(userData.username); // Set the username here
        navigate('/smartconsole1');
      } else {
        // If document doesn't exist, create a new document
        const newUUID = uuidv4();
        const newUserRef = doc(db, 'Users', newUUID);
        await setDoc(newUserRef, { username: username });
        sessionStorage.setItem('username', username);
        setUserName(username); // Set the username here
        // Now navigate to '/smartconsole1'
        navigate('/smartconsole1');
      }
    } catch (error) {
      console.error('Error fetching user ID:', error);
    }
  }, [setUserName, navigate]);

  useEffect(() => {
    const fetchGoogleUser = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
              code: code,
              client_id: '614102256594-lpbqkqjfshlmvk283403dkdi6mbh9la0.apps.googleusercontent.com',
              client_secret: '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',
              redirect_uri: 'https://www.smartcardai.com/Login1',
              grant_type: 'authorization_code'
            })
          });

          if (!response.ok) {
            throw new Error(`Failed to exchange code for token: ${response.status} ${response.statusText}`);
          }

          const data = await response.json();
          const accessToken = data.access_token;

          const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          if (!userInfoResponse.ok) {
            throw new Error(`Failed to fetch user info: ${userInfoResponse.status} ${userInfoResponse.statusText}`);
          }

          const userInfo = await userInfoResponse.json();
          const userEmail = userInfo.email;

          const username = userEmail;
          setUserName(username);
          sessionStorage.setItem('username', username);
          fetchUserID(username);

        } catch (error) {
          console.error('Error during Google login:', error.message);
        }
      }
    };

    fetchGoogleUser();
  }, [ navigate, setUserName, fetchUserID]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '300px', padding: '20px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '5px', textAlign: 'center' }}>
        <div>
          <img src={logo512} alt="Logo" style={{ width: '90%' }} />
          
        </div>
        <p style={{ marginBottom: '20px', fontSize: '22px', textAlign: 'center' }}>Signup</p>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your username"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          <button type="submit" disabled={loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '60px', marginTop: '10px', padding: '5px', fontSize: '20px', backgroundColor: 'black', color: 'white', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        <button onClick={handleGoogleLogin} style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', padding: '10px',height: '60px',fontSize: '16px', backgroundColor: 'transparent', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
        <img src={goog} alt="Google Logo" style={{ width: '80px', marginLeft: '-15px', marginRight : '15px' }} />
          Sign up with Google
        </button>

        <div style={{ marginTop: '10px' }}>
          <button onClick={handleResetPassword} style={{ marginRight: '40px', marginLeft:'-15px', backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Reset Password</button>
          <button onClick={handleSignin} style={{ backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Login In</button>
        </div>
      </div>
    </div>
  );
};

export default Signup;

