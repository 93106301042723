import React, { useState} from 'react';
import Picture from './Socialcard1.png'; // Import your picture
//import './background5.css'; // Import the CSS file
import './background2.scss'; // Import the CSS file
import { revDataURL } from '../../../../../../../routes.mjs';
import { getrevDataURL } from '../../../../../../../routes.mjs';
//import Details1 from '../details/details1.js';
//import KeywordGenerator from './KeywordGenerator';

const RevCard1 = ({ username,  revcardImage, handleRevAdd, isVisible, flexdeck , flexdeckimage, flexlabel, flexpopdeckimage, flexpoplabel, flexpoptopimage, flexpoptoplabel,  popupcontent51, popup51, showrevContents,setrevShowContents  }) => {
   


    const [googlereview, setGooglereview] = useState('');
const [trustpilot, setTrustpilot] = useState('');
const [ambitionbox, setAmbitionbox] = useState('');
const [g2, setG2] = useState('');
const [glassdoor, setGlassdoor] = useState('');
const [amazonreview, setAmazonreview] = useState('');
const [indeed, setIndeed] = useState('');
const [booking, setBooking] = useState('');
const [goibibo, setGoibibo] = useState('');
const [makemytrip, setMakemytrip] = useState('');
const [facebookreview, setFacebookreview] = useState('');
const [cnet, setCnet] = useState('');
const [yelp, setYelp] = useState('');
const [techcrunch, setTechcrunch] = useState('');
const [airbnb, setAirbnb] = useState('');
const [oyo, setOyo] = useState('');


    const getFunction = async (username) => {
        try {
            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);

            // Construct the URL with the username as a parameter
            const url = `${getrevDataURL}?username=${encodeURIComponent(username)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                    
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }


         

            const jsonResponse = await response.json();


            const googlereview = jsonResponse.googlereview;
            const trustpilot = jsonResponse.trustpilot;
            const ambitionbox = jsonResponse.ambitionbox;
            const g2 = jsonResponse.g2;
            const glassdoor = jsonResponse.glassdoor;
            const amazonreview = jsonResponse.amazonreview;
            const indeed = jsonResponse.indeed;
            const booking = jsonResponse.booking;
            const goibibo = jsonResponse.goibibo;
            const makemytrip = jsonResponse.makemytrip;
            const facebookreview = jsonResponse.facebookreview;
            const cnet = jsonResponse.cnet;
            const yelp = jsonResponse.yelp;
            const techcrunch = jsonResponse.techcrunch;
            const airbnb = jsonResponse.airbnb;
            const oyo = jsonResponse.oyo;

            
            //console.log("name:", name);
            //console.log("email2:", email2);
            //console.log("phone:", phone);
            return {  googlereview, trustpilot, ambitionbox, g2, glassdoor, amazonreview, indeed, booking, goibibo, makemytrip, facebookreview, cnet, yelp,techcrunch,airbnb,oyo}; 
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
            return null; // Or throw the error further
        }
    };

    
   
    const handleIconClick = async () => {
      
           
           
        setrevShowContents(true);

       
       
        const socialData = localStorage.getItem('revData');
        if (socialData) {
            const socialDataJSON = JSON.parse(socialData);
            setGooglereview(socialDataJSON.googlereview);
            setTrustpilot(socialDataJSON.trustpilot);
            setAmbitionbox(socialDataJSON.ambitionbox);
            setG2(socialDataJSON.g2);
            setGlassdoor(socialDataJSON.glassdoor);
            setAmazonreview(socialDataJSON.amazonreview);
            setIndeed(socialDataJSON.indeed);
            setBooking(socialDataJSON.booking);
            setGoibibo(socialDataJSON.goibibo);
            setMakemytrip(socialDataJSON.makemytrip);
            setFacebookreview(socialDataJSON.facebookreview);
            setCnet(socialDataJSON.cnet);
            setYelp(socialDataJSON.yelp);
            setTechcrunch(socialDataJSON.techcrunch);
            setAirbnb(socialDataJSON.airbnb);
            setOyo(socialDataJSON.oyo);
            
        }
    };

    const handleCloseButtonClick = () => {
        setrevShowContents(false);
    };


    const handleAddButtonClick = async () => {
        try {
            // Call addFunction with the provided data
            await addFunction(username,  googlereview, trustpilot, ambitionbox, g2, glassdoor, amazonreview, indeed, booking, goibibo, makemytrip, facebookreview, cnet, yelp,techcrunch,airbnb,oyo);
            
            // After addFunction completes successfully, update local storage
            const updatedSocialData = await getFunction(username);
           
            localStorage.setItem('revData', JSON.stringify(updatedSocialData));
    
            // Call handleSocialAdd with the updated social data
            handleRevAdd(updatedSocialData);
    
            // Set UI state to hide some contents
            setrevShowContents(false);
    
        } catch (error) {
            console.error("Error adding or updating social data: ", error);
        }
    };
    


    
    
   
    const handleDeleteButtonClick = async () => {
       
        
   
  
        
        addFunction(username, " ", " ", " ", " ", " ", " ", " "," "," "," ", " ", " ", " " )
        .then(async () => {
           
           // Update local storage after addFunction has completed
           const updatedSocialData = await getFunction(username);
           localStorage.setItem('revData', JSON.stringify(updatedSocialData));
        })
        .catch((error) => {
            console.error("Error updating local storage: ", error);
        });


        setrevShowContents(false);
    };
    
    

    const addFunction = async (username, googlereview, trustpilot, ambitionbox, g2, glassdoor, amazonreview, indeed, booking, goibibo, makemytrip, facebookreview, cnet, yelp, techcrunch, airbnb, oyo) => {
        try {
            // Check if username is provided
            if (!username) {
                console.error("Username is required.");
                return;
            }
    
            // Check if any field is undefined or null and set a default value or handle it
            const fields = { username, googlereview, trustpilot, ambitionbox, g2, glassdoor, amazonreview, indeed, booking, goibibo, makemytrip, facebookreview, cnet, yelp, techcrunch, airbnb, oyo };
            
            // Replace undefined or null fields with a default value, e.g., empty string
            for (const key in fields) {
                if (fields[key] === undefined || fields[key] === null) {
                    fields[key] = ''; // or any default value you prefer
                }
            }
    
            const response = await fetch(revDataURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fields),
            });
    
            const responseData = await response.json();
            console.log('Response from server:', responseData);
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
        }
    };
    
    

    
    
    
    return (
        <div>
            {/* Conditionally render the icon based on the isVisible prop */}
            {isVisible  && (
               <div style={{ display: 'flex'}}>
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                   <img
                       src={Picture}
                       alt="Icon"
                       className=  {flexpopdeckimage}
                       onClick={handleIconClick}
                   />
                   <div className={flexpoplabel}>Review</div>
               </div>
           </div>
           
            )}

            {!isVisible && revcardImage && (
                <div className=  {flexdeck}>
                <img
                    src={Picture}
                    alt="Icon"
                    className=  {flexdeckimage}

                    onClick={handleIconClick}
                />
                <div className={flexlabel}  >Review</div>

            </div>
            )}




            {/* Conditionally render the input fields and add button based on visibility */}
            {isVisible && showrevContents && (
                 <div className="popup212">
                  
                   
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className="flexpoptopcontactimage"

                            />
                            <div className="flexpoptopcontactlabel">Review</div>
                        </div>
                

                        <div className="popup-content2">
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={googlereview} 
                                onChange={(e) => setGooglereview(e.target.value)}
                            />
                            <label className="custom-placeholder">Google Review</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={trustpilot}
                                onChange={(e) => setTrustpilot(e.target.value)}
                            />
                            <label className="custom-placeholder">Trustpilot</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={ambitionbox}
                                onChange={(e) => setAmbitionbox(e.target.value)}
                            />
                            <label className="custom-placeholder">Ambitionbox</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={g2}
                                onChange={(e) => setG2(e.target.value)}
                            />
                            <label className="custom-placeholder">G2</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={glassdoor}
                                onChange={(e) => setGlassdoor(e.target.value)}
                            />
                            <label className="custom-placeholder">Glassdoor</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={amazonreview}
                                onChange={(e) => setAmazonreview(e.target.value)}
                            />
                            <label className="custom-placeholder">Amazon Review</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={indeed}
                                onChange={(e) => setIndeed(e.target.value)}
                            />
                            <label className="custom-placeholder">Indeed</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={booking}
                                onChange={(e) => setBooking(e.target.value)}
                            />
                            <label className="custom-placeholder">Booking</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={goibibo}
                                onChange={(e) => setGoibibo(e.target.value)}
                            />
                            <label className="custom-placeholder">Goibibo</label>
                                           
                        </div>

                       

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={makemytrip}
                                onChange={(e) => setMakemytrip(e.target.value)}
                            />
                            <label className="custom-placeholder">Make My Trip</label>
                                           
                        </div>

                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={facebookreview}
                                onChange={(e) => setFacebookreview(e.target.value)}
                            />
                            <label className="custom-placeholder">Facebook Review</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={cnet}
                                onChange={(e) => setCnet(e.target.value)}
                            />
                            <label className="custom-placeholder">Cnet</label>
                                           
                        </div>


                    

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={yelp}
                                onChange={(e) => setYelp(e.target.value)}
                            />
                            <label className="custom-placeholder">Yelp</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={techcrunch}
                                onChange={(e) => setTechcrunch(e.target.value)}
                            />
                            <label className="custom-placeholder">Tech Crunch</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={airbnb}
                                onChange={(e) => setAirbnb(e.target.value)}
                            />
                            <label className="custom-placeholder">Airbnb</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={oyo}
                                onChange={(e) => setOyo(e.target.value)}
                            />
                            <label className="custom-placeholder">Oyo</label>
                                           
                        </div>

                     
                        </div> 
                        <div className="actionbar">
                           <button className="popupadd-button2" onClick={handleAddButtonClick}>Add</button>
                           <button className="popupdelete-button2" onClick={handleDeleteButtonClick}>Delete</button>
                           <button className="popupclose-button2" onClick={handleCloseButtonClick}>Close</button>
                        </div>
                    </div>
            )}

            { !isVisible && showrevContents && (


                 
                <div className={popup51} >
                      <div className='contactnotposi'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <img
                                    src={Picture}
                                    alt="Icon"
                                    className='contactbuttonnot'
                                    onClick={handleIconClick}
                                />
                                <div   className='noticonlabel'>Review</div>
                            </div>
                        </div>
               
                    <div className="popupcontent53" >
                      
                        
                        <div >
                       
                       
                       

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={googlereview} 
                                onChange={(e) => setGooglereview(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Google Review</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={trustpilot}
                                onChange={(e) => setTrustpilot(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Trustpilot</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={ambitionbox}
                                onChange={(e) => setAmbitionbox(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Ambitionbox</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={g2}
                                onChange={(e) => setG2(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">G2</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={glassdoor}
                                onChange={(e) => setGlassdoor(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Glassdoor</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={amazonreview}
                                onChange={(e) => setAmazonreview(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Amazon Review</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={indeed}
                                onChange={(e) => setIndeed(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Indeed</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={booking}
                                onChange={(e) => setBooking(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Booking</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={goibibo}
                                onChange={(e) => setGoibibo(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Goibibo</label>
                                           
                        </div>

                       

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={makemytrip}
                                onChange={(e) => setMakemytrip(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Make My Trip</label>
                                           
                        </div>

                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={facebookreview}
                                onChange={(e) => setFacebookreview(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Facebook Review</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={cnet}
                                onChange={(e) => setCnet(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Cnet</label>
                                           
                        </div>


                    

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={yelp}
                                onChange={(e) => setYelp(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Yelp</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={techcrunch}
                                onChange={(e) => setTechcrunch(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Tech Crunch</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={airbnb}
                                onChange={(e) => setAirbnb(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Airbnb</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={oyo}
                                onChange={(e) => setOyo(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Oyo</label>
                                           
                        </div>
                     
                        
                        </div>
                        {/*<button onClick={() => handleAddButtonClick(name, email2, phone)}>Add</button>
                        <button onClick={handleDeleteButtonClick}>Delete</button>*/}
                        
                    </div>
                    <button className="popupclose-button51" onClick={handleCloseButtonClick}>Close</button>
                </div>
              
            )}
  
        </div>
    );
};

export default RevCard1;