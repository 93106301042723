// usercontext.js
// usercontext.js
import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [username, setUserName] = useState('');
  const [loggedIn, setLoggedIn] = useState(true);


  const login = (inputUsername) => {
    // Assuming you have a logic to check username/password against a database or some other source
    // Here, for simplicity, I'm setting the username and password directly
    setUserName(inputUsername);
   
    
    setLoggedIn(false);
  };

  const logout = () => {
    setLoggedIn(true);
    setUserName('');
   
  };

  return (
    <UserContext.Provider value={{ username, setUserName,loggedIn, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};