import React, { useState} from 'react';
import Picture from './calendar.png'; // Import your picture
//import './background5.css'; // Import the CSS file
import './background2.scss'; // Import the CSS file
import { calendarDataURL } from '../../../../../../../routes.mjs';
import { getcalendarDataURL } from '../../../../../../../routes.mjs';
//import Details1 from '../details/details1.js';
//import KeywordGenerator from './KeywordGenerator';

const CalendarCard1 = ({ username,  calendarcardImage, handleCalendarAdd, isVisible, flexdeck , flexdeckimage, flexlabel, flexpopdeckimage, flexpoplabel, flexpoptopimage, flexpoptoplabel,  popupcontent51, popup51, showcalendarContents,setcalendarShowContents  }) => {
   

    const [teams, setTeams] = useState('');
    const [zohocalendar, setZohocalendar] = useState('');
    const [googlecalendar, setGooglecalendar] = useState('');
    const [outlookcalendar, setOutlookcalendar] = useState('');
    const [calendly, setCalendly] = useState('');
    const [cal, setCal] = useState('');
    const [applecalendar, setApplecalendar] = useState('');
    const [zoom, setZoom] = useState('');
    

    const getFunction = async (username) => {
        try {
            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);

            // Construct the URL with the username as a parameter
            const url = `${getcalendarDataURL}?username=${encodeURIComponent(username)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                    
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }


         

            const jsonResponse = await response.json();

            const teams = jsonResponse.teams;
            const zohocalendar = jsonResponse.zohocalendar;
            const googlecalendar = jsonResponse.googlecalendar;
            const outlookcalendar = jsonResponse.outlookcalendar;
            const calendly = jsonResponse.calendly;
            const cal = jsonResponse.cal;
            const applecalendar = jsonResponse.applecalendar;
            const zoom = jsonResponse.zoom;

            
            //console.log("name:", name);
            //console.log("email2:", email2);
            //console.log("phone:", phone);
            return { teams, zohocalendar, googlecalendar, outlookcalendar, calendly, cal, applecalendar, zoom}; 
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
            return null; // Or throw the error further
        }
    };

    
   
    const handleIconClick = async () => {
      
           
           
        setcalendarShowContents(true);

       
       
        const socialData = localStorage.getItem('calendarData');
        if (socialData) {
            const socialDataJSON = JSON.parse(socialData);

            setTeams(socialDataJSON.teams);
            setZohocalendar(socialDataJSON.zohocalendar);
            setGooglecalendar(socialDataJSON.googlecalendar);
            setOutlookcalendar(socialDataJSON.outlookcalendar);
            setCalendly(socialDataJSON.calendly);
            setCal(socialDataJSON.cal);
            setApplecalendar(socialDataJSON.applecalendar);
            setZoom(socialDataJSON.zoom);

        }
    };

    const handleCloseButtonClick = () => {
        setcalendarShowContents(false);
    };


    const handleAddButtonClick = async () => {
        try {
            // Call addFunction with the provided data
            await addFunction(username, teams, zohocalendar, googlecalendar, outlookcalendar, calendly, cal, applecalendar, zoom);
            

            console.log(teams);
            // After addFunction completes successfully, update local storage
            const updatedSocialData = await getFunction(username);
          
            localStorage.setItem('calendarData', JSON.stringify(updatedSocialData));
    
            // Call handleSocialAdd with the updated social data
            handleCalendarAdd(updatedSocialData);
    
            // Set UI state to hide some contents
            setcalendarShowContents(false);
    
        } catch (error) {
            console.error("Error adding or updating social data: ", error);
        }
    };
    


    
    
   
    const handleDeleteButtonClick = async () => {
       
        
   
  
        
        addFunction(username, " ", " ", " ", " ", " ", " ", " "," "," "," ", " ", " ", " " )
        .then(async () => {
           
           // Update local storage after addFunction has completed
           const updatedSocialData = await getFunction(username);
           localStorage.setItem('calendarData', JSON.stringify(updatedSocialData));
        })
        .catch((error) => {
            console.error("Error updating local storage: ", error);
        });


        setcalendarShowContents(false);
    };
    
    

    const addFunction = async (username, teams, zohocalendar, googlecalendar, outlookcalendar, calendly, cal, applecalendar, zoom) => {
        try {
            // Check if username is provided
            if (!username) {
                console.error("Username is required.");
                return;
            }
    
            // Check if any field is undefined or null and set a default value
            const fields = { username, teams, zohocalendar, googlecalendar, outlookcalendar, calendly, cal, applecalendar, zoom };
    
            // Replace undefined or null fields with a default value, e.g., empty string
            for (const key in fields) {
                if (fields[key] === undefined || fields[key] === null) {
                    fields[key] = ''; // or any default value you prefer
                }
            }
    
            const response = await fetch(calendarDataURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fields),
            });
    
            const responseData = await response.json();
            console.log('Response from server:', responseData);
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
        }
    };
    
    

    
    
    
    return (
        <div>
            {/* Conditionally render the icon based on the isVisible prop */}
            {isVisible  && (
               <div style={{ display: 'flex'}}>
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                   <img
                       src={Picture}
                       alt="Icon"
                       className=  {flexpopdeckimage}
                       onClick={handleIconClick}
                   />
                   <div className={flexpoplabel}>Calendar</div>
               </div>
           </div>
           
            )}

            {!isVisible && calendarcardImage && (
                <div className=  {flexdeck}>
                <img
                    src={Picture}
                    alt="Icon"
                    className=  {flexdeckimage}

                    onClick={handleIconClick}
                />
                <div className={flexlabel}  >Calendar</div>

            </div>
            )}




            {/* Conditionally render the input fields and add button based on visibility */}
            {isVisible && showcalendarContents && (
                 <div className="popup212">
                  
                   
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className="flexpoptopcontactimage"

                            />
                            <div className="flexpoptopcontactlabel">Calendar</div>
                        </div>
                

                        <div className="popup-content2">
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={teams} 
                                onChange={(e) => setTeams(e.target.value)}
                            />
                            <label className="custom-placeholder">Teams</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={zohocalendar}
                                onChange={(e) => setZohocalendar(e.target.value)}
                            />
                            <label className="custom-placeholder">Zoho Calendar</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={googlecalendar}
                                onChange={(e) => setGooglecalendar(e.target.value)}
                            />
                            <label className="custom-placeholder">Google Calendar</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={outlookcalendar}
                                onChange={(e) => setOutlookcalendar(e.target.value)}
                            />
                            <label className="custom-placeholder">Outlook Calendar</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={calendly}
                                onChange={(e) => setCalendly(e.target.value)}
                            />
                            <label className="custom-placeholder">Calendly</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={cal}
                                onChange={(e) => setCal(e.target.value)}
                            />
                            <label className="custom-placeholder">Cal</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={applecalendar}
                                onChange={(e) => setApplecalendar(e.target.value)}
                            />
                            <label className="custom-placeholder">Apple Calendar</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={zoom}
                                onChange={(e) => setZoom(e.target.value)}
                            />
                            <label className="custom-placeholder">Zoom</label>
                                           
                        </div>

       

                     
                        </div> 
                        <div className="actionbar">
                           <button className="popupadd-button2" onClick={handleAddButtonClick}>Add</button>
                           <button className="popupdelete-button2" onClick={handleDeleteButtonClick}>Delete</button>
                           <button className="popupclose-button2" onClick={handleCloseButtonClick}>Close</button>
                        </div>
                    </div>
            )}

            { !isVisible && showcalendarContents && (


                 
                <div className={popup51} >
                      <div className='contactnotposi'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <img
                                    src={Picture}
                                    alt="Icon"
                                    className='contactbuttonnot'
                                    onClick={handleIconClick}
                                />
                                <div   className='noticonlabel'>Calendar</div>
                            </div>
                        </div>
               
                    <div className="popupcontent53" >
                      
                        
                        <div >
                       
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={teams} 
                                onChange={(e) => setTeams(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Teams</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={zohocalendar}
                                onChange={(e) => setZohocalendar(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Zoho Calendar</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={googlecalendar}
                                onChange={(e) => setGooglecalendar(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Google Calendar</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={outlookcalendar}
                                onChange={(e) => setOutlookcalendar(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Outlook Calendar</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={calendly}
                                onChange={(e) => setCalendly(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Calendly</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={cal}
                                onChange={(e) => setCal(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Cal</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={applecalendar}
                                onChange={(e) => setApplecalendar(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Apple Calendar</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={zoom}
                                onChange={(e) => setZoom(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Zoom</label>
                                           
                        </div>
                     
                        
                        </div>
                        {/*<button onClick={() => handleAddButtonClick(name, email2, phone)}>Add</button>
                        <button onClick={handleDeleteButtonClick}>Delete</button>*/}
                        
                    </div>
                    <button className="popupclose-button51" onClick={handleCloseButtonClick}>Close</button>
                </div>
              
            )}
  
        </div>
    );
};

export default CalendarCard1;