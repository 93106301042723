import React, { useState } from 'react';
import logo512 from './logo512.png';
import { verifyURL, verifycodeURL } from '../routes.mjs';
import { useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
  const [formData, setFormData] = useState({ email: '', code: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreate = () => {
    // Navigate to the Reset.js page
    navigate('/Signup1');
   };
 
   const handleSignin = () => {
     // Define the action to create account here
 
     navigate('/Login1');
   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!formData.email) {
        setError('Please enter your email');
        return;
      }

      setLoading(true);

      const response = await fetch(verifyURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (!response.ok) {
        throw new Error('Failed to send verification code');
      }

      setError('');
      setShowCodeInput(true); // Show the code input after submitting the email
    } catch (error) {
      setError('Failed to send verification code');
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      if (!formData.code) {
        setError('Please enter the verification code');
        return;
      }

      setLoading(true);

      const response = await fetch(verifycodeURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, code: formData.code }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify account');
      }

      const data = await response.json();
      if (data.message === 'Account verified') {
        alert('Account verified successfully');
        navigate(`/Newpass1?email=${formData.email}`);
        // Redirect or perform any action upon successful verification
      } else {
        setError('Invalid verification code');
      }
    } catch (error) {
      setError('Failed to verify account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '300px', padding: '20px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '5px', textAlign: 'center' }}>
        <div>
          <img src={logo512} alt="Logo" style={{ width: '90%' }} />
       
        </div>
        <p style={{ marginBottom: '20px', fontSize: '22px', textAlign: 'center' }}>Reset Password</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          {showCodeInput && (
            <>
              <input
                type="text"
                name="code"
                value={formData.code}
                onChange={handleChange}
                placeholder="Enter the code"
                style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
              />
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <button type="submit" onClick={handleVerify} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '60px', marginTop: '10px', padding: '5px', fontSize: '20px', backgroundColor: 'black', color: 'white', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
                {loading ? 'Verifying Account...' : 'Verify Account'}
              </button>
            </>
          )}
          {!showCodeInput && (
            <button type="submit" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '60px', marginTop: '10px', padding: '5px', fontSize: '20px', backgroundColor: 'black', color: 'white', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
              {loading ? 'Sending Code...' : 'Send Code'}
            </button>
          )}
        </form>

        <div style={{ marginTop: '10px' }}>
          <button onClick={handleCreate} style={{ marginRight: '40px', marginLeft:'-20px', backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Create an Account</button>
          <button onClick={handleSignin} style={{ backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Sign In</button>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;

