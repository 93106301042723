import React, { useState, useEffect, useRef } from 'react';
import { db, imageDb } from '../../../../../../../firebaseconfig.js';
import Picture from './Profile.png';
import Placeholder from './placeholder.png';
import './background42.scss';
import AvatarEditor from 'react-avatar-editor';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, query, where, getDocs } from "firebase/firestore";

const ProfileImage1 = ({ flexpoptopimage, flexpoptoplabel, handleProfileImageChange, isVisible, username, flexpopdeckimage, flexpoplabel }) => {
    const [showContents, setShowContents] = useState(false);
    const [userID, setUserID] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageForCrop, setImageForCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [scale, setScale] = useState(1.2);
    const [editorSize, setEditorSize] = useState({ width: 200, height: 200 });
    const [imageForCropFromLocalStorage, setImageForCropFromLocalStorage] = useState(null);
    const [croppedImageFromLocalStorage, setCroppedImageFromLocalStorage] = useState(null);
    const editorRef = useRef(null);

    useEffect(() => {
        const fetchUserID = async () => {
            const usersRef = collection(db, 'Users');
            const q = query(usersRef, where("username", "==", username));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setUserID(doc.id);
            });
        };
        fetchUserID();
    }, [username]);

    useEffect(() => {
        const fetchProfileImage = async () => {
            if (userID) {
                try {
                    const imgRef = ref(imageDb, `Files/Profile/${userID}/${userID}`);
                    const imageUrl = await getDownloadURL(imgRef);
                    setProfileImageUrl(imageUrl);
                    setImageForCrop(imageUrl);
                } catch (error) {
                    console.error("Error fetching profile image:", error);
                    setProfileImageUrl(null);
                    setImageForCrop(null);
                }
            }
        };
        fetchProfileImage();
    }, [userID]);

    useEffect(() => {
        const storedImageForCrop = localStorage.getItem('imageForCrop');
        const storedCroppedImage = localStorage.getItem('croppedImage');
        if (storedImageForCrop) {
            setImageForCropFromLocalStorage(storedImageForCrop);
        }
        if (storedCroppedImage) {
            setCroppedImageFromLocalStorage(storedCroppedImage);
        }
    }, []);

    useEffect(() => {
        const checkLocalStorage = () => {
            const updatedImageForCrop = localStorage.getItem('imageForCrop');
            const updatedCroppedImage = localStorage.getItem('croppedImage');
            if (updatedImageForCrop !== imageForCropFromLocalStorage) {
                setImageForCropFromLocalStorage(updatedImageForCrop);
            }
            if (updatedCroppedImage !== croppedImageFromLocalStorage) {
                setCroppedImageFromLocalStorage(updatedCroppedImage);
            }
        };

        const intervalId = setInterval(checkLocalStorage, 1000); // Check every second

        return () => {
            clearInterval(intervalId);
        };
    }, [imageForCropFromLocalStorage, croppedImageFromLocalStorage]);

    const handleImageLoaded = () => {
        setImageLoaded(true);
    };

    const handleIconClick = () => {
        setShowContents(true);
    };

    const handleCloseButtonClick = () => {
        setShowContents(false);
        setCroppedImage(null);
    };

    const handleInputChange = async (e) => {
        setShowContents(true);
        const file = e.target.files[0];
        if (userID && file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Image = reader.result;
                setImageForCrop(base64Image);
                localStorage.setItem('imageForCrop', base64Image);
            };
            reader.readAsDataURL(file);

            try {
                const originalImgRef = ref(imageDb, `Files/Profile/${userID}/${userID}_original`);
                await uploadBytes(originalImgRef, file);
                console.log("Original image saved successfully");
            } catch (error) {
                console.error("Error uploading original image:", error);
            }
        }
    };

    const handleSaveCroppedImage = async () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImage(); // Get the canvas element from AvatarEditor
            try {
                const base64Image = canvas.toDataURL('image/png'); // Convert canvas to Base64-encoded image data
                localStorage.setItem('croppedImage', base64Image); // Store the Base64 image data in localStorage

                // Optionally, upload the image to Firebase if needed
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        const imgRef = ref(imageDb, `Files/Profile/${userID}/${userID}`);
                        await uploadBytes(imgRef, blob);
                        const imageUrl = await getDownloadURL(imgRef);
                        setCroppedImage(imageUrl); // Update croppedImage state with new image URL

                        handleProfileImageChange({ target: { files: [blob] } }); // Pass a synthetic event
                        console.log("Image saved successfully");
                    } else {
                        console.error('Failed to create blob from canvas');
                    }
                }, 'image/png');
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        } else {
            console.error('Avatar editor ref is not available.');
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const updateEditorSize = () => {
        if (window.innerWidth <= 600) {
            setEditorSize({ width: 200, height: 200 });
        } else if (window.innerWidth <= 1100) {
            setEditorSize({ width: 270, height: 270 });
        } else if (window.innerWidth <= 2000) {
            setEditorSize({ width: 150, height: 150 });
        } else {
            setEditorSize({ width: 210, height: 210 });
        }
    };

    useEffect(() => {
        updateEditorSize();
        window.addEventListener('resize', updateEditorSize);
        return () => {
            window.removeEventListener('resize', updateEditorSize);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img
                            src={Picture}
                            alt="Icon"
                            className={flexpopdeckimage}
                            onClick={handleIconClick}
                        />
                        <div className={flexpoplabel}>Profile</div>
                    </div>
                </div>
            )}

            {showContents && (
                <div className="popupprofile42">
                    <div style={{ display: 'flex', marginLeft: '43vw', marginTop: '4vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className={flexpoptopimage}
                            />
                            <div className={flexpoptoplabel}>Profile</div>
                        </div>
                    </div>

                    <div className="avatar">
                        <AvatarEditor
                            ref={editorRef}
                            image={imageForCropFromLocalStorage || imageForCrop || Placeholder}
                            width={editorSize.width}
                            height={editorSize.height}
                            border={5}
                            color={[255, 255, 255, 0.6]}
                            scale={scale}
                            rotate={0}
                        />
                        <div>
                            <input
                                type="range"
                                min="1"
                                max="2"
                                step="0.01"
                                value={scale}
                                onChange={(e) => setScale(parseFloat(e.target.value))}
                            />
                        </div>
                        <div className="inputprofile" onClick={triggerFileInput}>
                        <input type="file" onChange={(e) => { handleProfileImageChange(e); handleInputChange(e); }} accept="image/*" id="fileInput" style={{ display: 'none' }} />
                        Choose File
                    </div>
                        <button className="save-crop-button" onClick={handleSaveCroppedImage}>Save</button>
                    </div>

                    
                    <button className="popupprofileclose-button42" onClick={handleCloseButtonClick}>Close</button>
                </div>
            )}

            {!isVisible && (
                <div>
                    <img
                        src={croppedImageFromLocalStorage || croppedImage || profileImageUrl || Placeholder}
                        alt=""
                        onLoad={handleImageLoaded}
                        className={imageLoaded ? 'image-loaded' : ''}
                    />
                </div>
            )}
        </div>
    );
};

export default ProfileImage1;
