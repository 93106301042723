import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

const QrImage2 = ({ username, isVisible }) => {
    const url = `https://www.smartcardai.com/MyBusinesscard/${username}`;

    // State to hold the size of the QR code
    const [qrSize, setQrSize] = useState('4vw'); // Default size for larger screens
    const [qrImageData, setQrImageData] = useState(null); // State to hold QR code image data

    // Function to update QR code size based on screen width
    const updateQrSize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 0 && screenWidth < 506) {
            setQrSize('16vw'); // Adjust size for smaller mobile screens
        } else if (screenWidth >= 507 && screenWidth < 1033) {
            setQrSize('12vh'); // Adjust size for laptops
        } else if (screenWidth >= 1034 && screenWidth < 1440) {
            setQrSize('9vh'); // Adjust size for larger laptops
        } else if (screenWidth >= 1440 && screenWidth < 3000) {
            setQrSize('8vh'); // Adjust size for 4K screens
        } else if (screenWidth >= 3001) {
            setQrSize('8vw'); // Adjust size for very large screens (4K+)
        }
    };

    // Function to generate QR code image and save in localStorage
    const generateQrCodeImage = () => {
        const canvas = document.querySelector('canvas'); // Assuming qrcode.react renders to a canvas element
        if (canvas) {
            const imageData = canvas.toDataURL(); // Get data URL of the canvas
            localStorage.setItem('qrImageData', imageData); // Save image data in localStorage
            setQrImageData(imageData); // Update state with image data
        }
    };

    // Effect to update size on component mount and screen resize
    useEffect(() => {
        updateQrSize();
        window.addEventListener('resize', updateQrSize);

        // Check if offline and retrieve QR code image from localStorage
        if (navigator.onLine) {
            const savedImageData = localStorage.getItem('qrImageData');
            if (savedImageData) {
                setQrImageData(savedImageData);
            } else {
                generateQrCodeImage(); // Generate QR code image if not saved
            }
        }

        return () => window.removeEventListener('resize', updateQrSize);
    }, []);

    return (
        <div>
            {/* Conditionally render the QR code */}
            {!isVisible && qrImageData && (
                <div>
                    <img src={qrImageData} alt="QR Code" style={{ width: qrSize, height: qrSize, padding: '0.9vh', borderRadius:'0.6vh',background: 'white', boxShadow: '0px 3px 5px rgba(52, 85, 249, 0.6)'}} />
                </div>
            )}
            {!isVisible && !qrImageData && (
                <div>
                    <QRCode
                        value={url}
                        style={{
                            boxShadow: '0px 3px 5px rgba(52, 85, 249, 0.6)',
                            width: qrSize,
                            height: qrSize,
                            background: 'white',
                            padding: '0.9vh', 
                            borderRadius:'0.6vh'
                            // Add any additional styles like padding and border radius here
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default QrImage2;

