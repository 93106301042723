import React, { useState} from 'react';
import Picture from './Socialcard1.png'; // Import your picture
//import './background5.css'; // Import the CSS file
import './background2.scss'; // Import the CSS file
import { socialDataURL } from '../../../../../../../routes.mjs';
import { getsocialDataURL } from '../../../../../../../routes.mjs';
//import Details1 from '../details/details1.js';
//import KeywordGenerator from './KeywordGenerator';

const SocialCard1 = ({ username,  socialcardImage, handleSocialAdd, isVisible, flexdeck , flexdeckimage, flexlabel, flexpopdeckimage, flexpoplabel, flexpoptopimage, flexpoptoplabel,  popupcontent51, popup51, showsocialContents,setsocialShowContents  }) => {
   


    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedln, setLinkedln] = useState('');
    const [snapchat, setSnapchat] = useState('');
    const [youtube, setYoutube] = useState('');
    const [twitter, setTwitter] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [twitch, setTwitch] = useState('');
    const [telegram, setTelegram] = useState('');
    const [discord, setDiscord] = useState('');
    const [tumblr, setTumblr] = useState('');
    const [reddit, setReddit] = useState('');
    const [clubhouse, setClubhouse] = useState('');

    const getFunction = async (username) => {
        try {
            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);

            // Construct the URL with the username as a parameter
            const url = `${getsocialDataURL}?username=${encodeURIComponent(username)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                    
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }


         

            const jsonResponse = await response.json();
            const facebook = jsonResponse.facebook;
            const instagram = jsonResponse.instagram;
            const linkedln = jsonResponse.linkedln;
            const snapchat = jsonResponse.snapchat;
            const youtube = jsonResponse.youtube;
            const twitter = jsonResponse.twitter;
            const pinterest = jsonResponse.pinterest;
            const twitch = jsonResponse.twitch;
            const telegram = jsonResponse.telegram;
            const discord = jsonResponse.discord;
            const tumblr = jsonResponse.tumblr;
            const reddit = jsonResponse.reddit;
            const clubhouse = jsonResponse.clubhouse;
            
            //console.log("name:", name);
            //console.log("email2:", email2);
            //console.log("phone:", phone);
            return { facebook, instagram, linkedln, snapchat, youtube, twitter, pinterest,twitch,telegram,discord, tumblr, reddit, clubhouse}; 
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
            return null; // Or throw the error further
        }
    };

    
   
    const handleIconClick = async () => {
      
           
           
        setsocialShowContents(true);

       
       
        const socialData = localStorage.getItem('socialData');
        if (socialData) {
            const socialDataJSON = JSON.parse(socialData);
            setFacebook(socialDataJSON.facebook);
            setInstagram(socialDataJSON.instagram);
            setLinkedln(socialDataJSON.linkedln);
            setSnapchat(socialDataJSON.snapchat);
            setYoutube(socialDataJSON.youtube);
            setTwitter(socialDataJSON.twitter);
            setPinterest(socialDataJSON.pinterest);
            setTwitch(socialDataJSON.twitch);
            setTelegram(socialDataJSON.telegram);
            setDiscord(socialDataJSON.discord);
            setTumblr(socialDataJSON.tumblr);
            setReddit(socialDataJSON.reddit);
            setClubhouse(socialDataJSON.clubhouse);
        }
    };

    const handleCloseButtonClick = () => {
        setsocialShowContents(false);
    };


    const handleAddButtonClick = async () => {
        try {
            // Call addFunction with the provided data
            await addFunction(username, facebook, instagram, linkedln, snapchat, youtube, twitter, pinterest, twitch, telegram, discord, tumblr, reddit, clubhouse);
            
            // After addFunction completes successfully, update local storage
            const updatedSocialData = await getFunction(username);
            console.log("socialData2 ",updatedSocialData.facebook);
            localStorage.setItem('socialData', JSON.stringify(updatedSocialData));
    
            // Call handleSocialAdd with the updated social data
            handleSocialAdd(updatedSocialData);
    
            // Set UI state to hide some contents
            setsocialShowContents(false);
    
        } catch (error) {
            console.error("Error adding or updating social data: ", error);
        }
    };
    


    
    
   
    const handleDeleteButtonClick = async () => {
       
        
   
  
        
        addFunction(username, " ", " ", " ", " ", " ", " ", " "," "," "," ", " ", " ", " " )
        .then(async () => {
           
           // Update local storage after addFunction has completed
           const updatedSocialData = await getFunction(username);
           localStorage.setItem('socialData', JSON.stringify(updatedSocialData));
        })
        .catch((error) => {
            console.error("Error updating local storage: ", error);
        });


        setsocialShowContents(false);
    };
    
    

    const addFunction = async (username, facebook, instagram, linkedln, snapchat, youtube, twitter, pinterest,twitch,telegram,discord, tumblr, reddit, clubhouse) => {
       
       
       
        try {
            //console.log(`Adding Contact Data: ${name}${email2}${phone}`);

            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);
            console.log("socialData1 ",facebook);
            const response = await fetch(socialDataURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                   
                },
                body: JSON.stringify({ username, facebook, instagram, linkedln, snapchat, youtube, twitter, pinterest,twitch,telegram,discord, tumblr, reddit, clubhouse}),
               
           
            });

            const responseData = await response.json();
            console.log('Response from server:', responseData);
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
        }
    };

    

    
    
    
    return (
        <div>
            {/* Conditionally render the icon based on the isVisible prop */}
            {isVisible  && (
               <div style={{ display: 'flex'}}>
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                   <img
                       src={Picture}
                       alt="Icon"
                       className=  {flexpopdeckimage}
                       onClick={handleIconClick}
                   />
                   <div className={flexpoplabel}>Social</div>
               </div>
           </div>
           
            )}

            {!isVisible && socialcardImage && (
                <div className=  {flexdeck}>
                <img
                    src={Picture}
                    alt="Icon"
                    className=  {flexdeckimage}

                    onClick={handleIconClick}
                />
                <div className={flexlabel}  >Social</div>

            </div>
            )}




            {/* Conditionally render the input fields and add button based on visibility */}
            {isVisible && showsocialContents && (
                 <div className="popup212">
                  
                   
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className="flexpoptopcontactimage"

                            />
                            <div className="flexpoptopcontactlabel">Social</div>
                        </div>
                

                        <div className="popup-content2">
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={facebook} 
                                onChange={(e) => setFacebook(e.target.value)}
                            />
                            <label className="custom-placeholder">Facebook</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                            />
                            <label className="custom-placeholder">Instagram</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={linkedln}
                                onChange={(e) => setLinkedln(e.target.value)}
                            />
                            <label className="custom-placeholder">Linkedln</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={snapchat}
                                onChange={(e) => setSnapchat(e.target.value)}
                            />
                            <label className="custom-placeholder">Snapchat</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                            />
                            <label className="custom-placeholder">Youtube</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={twitter}
                                onChange={(e) => setTwitter(e.target.value)}
                            />
                            <label className="custom-placeholder">Twitter</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={pinterest}
                                onChange={(e) => setPinterest(e.target.value)}
                            />
                            <label className="custom-placeholder">Pinterest</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={twitch}
                                onChange={(e) => setTwitch(e.target.value)}
                            />
                            <label className="custom-placeholder">Twitch</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={telegram}
                                onChange={(e) => setTelegram(e.target.value)}
                            />
                            <label className="custom-placeholder">Telegram</label>
                                           
                        </div>

                       

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={discord}
                                onChange={(e) => setDiscord(e.target.value)}
                            />
                            <label className="custom-placeholder">Discord</label>
                                           
                        </div>

                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={tumblr}
                                onChange={(e) => setTumblr(e.target.value)}
                            />
                            <label className="custom-placeholder">Tumblr</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={reddit}
                                onChange={(e) => setReddit(e.target.value)}
                            />
                            <label className="custom-placeholder">Reddit</label>
                                           
                        </div>


                    

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={clubhouse}
                                onChange={(e) => setClubhouse(e.target.value)}
                            />
                            <label className="custom-placeholder">Clubhouse</label>
                                           
                        </div>

                     
                        </div> 
                        <div className="actionbar">
                           <button className="popupadd-button2" onClick={handleAddButtonClick}>Add</button>
                           <button className="popupdelete-button2" onClick={handleDeleteButtonClick}>Delete</button>
                           <button className="popupclose-button2" onClick={handleCloseButtonClick}>Close</button>
                        </div>
                    </div>
            )}

            { !isVisible && showsocialContents && (


                 
                <div className={popup51} >
                      <div className='contactnotposi'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <img
                                    src={Picture}
                                    alt="Icon"
                                    className='contactbuttonnot'
                                    onClick={handleIconClick}
                                />
                                <div   className='noticonlabel'>Social</div>
                            </div>
                        </div>
               
                    <div className="popupcontent53" >
                      
                        
                        <div >
                       
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={facebook}
                                onChange={(e) => setFacebook(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Facebook</label>
                        </div>

                       

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Instagram</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={linkedln}
                                onChange={(e) => setLinkedln(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Linkedln</label>
                        </div>

                        

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={snapchat}
                                onChange={(e) => setSnapchat(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Snapchat</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Youtube</label>
                        </div>


                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={twitter}
                                onChange={(e) => setTwitter(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Twitter</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={pinterest}
                                onChange={(e) => setPinterest(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Pinterest</label>
                        </div>


                        

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={twitch}
                                onChange={(e) => setTwitch(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Twitch</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={telegram}
                                onChange={(e) => setTelegram(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Telegram</label>
                                           
                        </div>

                       

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={discord}
                                onChange={(e) => setDiscord(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Discord</label>
                                           
                        </div>

                      

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={tumblr}
                                onChange={(e) => setTumblr(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Tumblr</label>
                                           
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={reddit}
                                onChange={(e) => setReddit(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Reddit</label>
                                           
                        </div>


                    

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={clubhouse}
                                onChange={(e) => setClubhouse(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Clubhouse</label>
                                           
                        </div>

                     
                        
                        </div>
                        {/*<button onClick={() => handleAddButtonClick(name, email2, phone)}>Add</button>
                        <button onClick={handleDeleteButtonClick}>Delete</button>*/}
                        
                    </div>
                    <button className="popupclose-button51" onClick={handleCloseButtonClick}>Close</button>
                </div>
              
            )}
  
        </div>
    );
};

export default SocialCard1;