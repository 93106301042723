import React from 'react';
import styled from 'styled-components';

import contactboard from './1.jpeg';
import socialboard from './2.jpeg';
import reviewboard from './3.jpeg';
import bookameetingboard from './4.jpeg';
import keywordsboard from './7.jpeg';

// Define styled components
const CardContainerwhite = styled.div`
  align-items: center;
  justify-content: center;
  min-height: calc(130vh - 5cm);
  background: white;
  background-size: 150% 150%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between; // Ensure right content stays on the right
  }
`;

const LeftContent = styled.div`
  flex: 1;
  @media (min-width: 600px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const RightContent = styled.div`
  flex: 1;
  margin-top: 10px; // Add margin-top to create space between LeftContent and RightContent

  @media (max-width: 600px) {
    margin-top: 0; // Remove margin-top for smaller screens
  }
`;

const Usecases = () => {
  return (
    <>

  


       {/* Big screen Board */}
       <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '3rem' }}>
          Effortless post presentation connections
          </h2>
          <h2 style={{ fontWeight: '310', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '2rem' }}>
          Seamlessly transform passive listeners into active participants
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
               
Engaging your audience has never been easier with the power of SmartCard AI. By adding a tab of SmartCard AI on your post presentation, you can effortlessly transform passive listeners into active participants. Attendees can scan  SmartCard AI dispalyed on screen to book a meeting, leave a review, connect on social media, or access a virtual business card, all with a simple scan. This seamless interaction eliminates barriers and fosters immediate, meaningful connections, making post presentation follow ups and networking smooth and efficient. With SmartCard AI, you turn every presentation into an opportunity for continued engagement and relationship building, enhancing the overall impact of your message.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>

        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={socialboard} alt="Social Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>
      </CardContainerwhite>

      
      <CardContainerwhite style={{
    paddingTop: '2px',
    marginBottom: '20px',
 }}>

         {/* Right side content */}
  {/* Right side content */}
  <RightContent style={{ padding: '4vw', marginTop: '6vh' , marginBottom: '5vh'}}>
 
    <img src={bookameetingboard} alt="" style={{ marginLeft:'1%',width: '110%', height: 'auto' }} />
   
  </RightContent>
       

       {/* Left side content */}

        
   <LeftContent style={{ padding: '5vw', marginTop: '6vh' , marginBottom: '5vh'}}>
        <h2 style={{ fontWeight: '400' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'3rem' }}>Out of cards? That’s old news. Embrace a new game.</h2>
        <h2 style={{ fontWeight: '310' ,marginLeft:'3vw',textAlign: 'left', color: 'black', fontSize:'2rem' }}>Add SmartCard AI for quick networking</h2>
        <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div className="custom-scrollbar" style={{
           backgroundColor: 'rgba(76, 207, 183, 0.9)',

        width: '80%',
        maxHeight: '50vh',
        padding: '20px',
        overflowY: 'scroll',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}>
        <div style={{ textAlign: 'justify' , color:'white'}}>
          {/* Add your text here */}
        
          <p style={{ textAlign: 'justify' , color:'white' , fontSize:'1.2rem'}}> With QR Virtual Card, you can revolutionize the way you network at expos and events. Say goodbye to the hassle of carrying and distributing physical business cards. Our service offers unlimited virtual cards that make it easier than ever to stay connected. Simply share your QR code, and with a quick scan, your new contacts will have instant access to your digital business card. Never miss a connection again. With QR Virtual Card, you’ll ensure that every interaction is seamless and effortlessly integrated into your online networ</p>
          {/* Repeat the text or add more text to demonstrate scrolling */}
        </div>
      </div>
    </div>
    </LeftContent>




   
</CardContainerwhite>




       {/* Recruiter Board */}
       <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '3rem' }}>
          Say goodbye to NFC and paper cards for new recruits. 
          </h2>
          <h2 style={{ fontWeight: '310', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '2rem' }}>
          Employee onboarding and 
offboarding redefined with virtual business cards!
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                 backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
               
                Welcome a new era in employee onboarding and offboarding with virtual business cards! Imagine grabbing a coffee with a new recruit and effortlessly transferring their virtual business card with a simple tap or click. This streamlined approach not only eliminates the hassle of physical cards but also ensures that employee onboarding is seamless and efficient. With virtual business cards, you can effortlessly manage contact information, update details in real-time, and ensure that every new hire has the information they need from day one. Embrace the future of workplace efficiency and make onboarding as smooth as a coffee break!
                </p>
              </div>
            </div>
          </div>
        </LeftContent>

        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={reviewboard} alt="Social Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>
      </CardContainerwhite>


      
      {/* Standup Board */}
      <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={contactboard} alt="Contact Board" style={{ marginLeft: '1%', width: '110%', height: 'auto' }} />
        </RightContent>

        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '3rem' }}>
          Distribute & Update Virtual Business Cards for Remote Sales Teams. No Cards & No Hassle!
          </h2>
          <h2 style={{ fontWeight: '300', marginLeft: '3vw', textAlign: 'left', color: 'black', fontSize: '2rem' }}>
          Enhance Remote Sales with Effortless Virtual Business Cards: Streamline Distribution for HR and Make Global Virtual Card Distribution Easy
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
                Transform the way you connect with remote sales teams by leveraging the power of virtual business cards. With our innovative solution, HR departments can easily and efficiently distribute virtual business cards to team members, regardless of their location. This seamless process ensures that every remote salesperson has instant access to their contact details and networking tools, fostering stronger connections and enhancing collaboration. Say goodbye to the complications of physical card distribution and embrace a more streamlined, global approach that supports your team’s success and drives business growth.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>
      </CardContainerwhite>

   

       {/* Recruiter Board */}
       <CardContainerwhite style={{ paddingTop: '2px', marginBottom: '20px' }}>
        {/* Left side content */}
        <LeftContent style={{ padding: '5vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <h2 style={{ fontWeight: '400', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '3rem' }}>
          Gaduates can 
learn about 
reviews of your
Company 
          </h2>
          <h2 style={{ fontWeight: '310', marginRight: '3vw', textAlign: 'right', color: 'black', fontSize: '2rem' }}>
          Build Trust with Graduates: Integrate Review Platforms Like Trustpilot, Glassdoor, and AmbitionBox
          </h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="custom-scrollbar" style={{
                 backgroundColor: 'rgba(76, 207, 183, 0.9)',
              width: '80%',
              maxHeight: '50vh',
              padding: '20px',
              overflowY: 'scroll',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
              <div style={{ textAlign: 'justify', color: 'white' }}>
                <p style={{ textAlign: 'justify', color: 'white', fontSize: '1.2rem' }}>
               
                Graduates increasingly turn to review platforms like Trustpilot, Glassdoor, and AmbitionBox to gauge the credibility and reputation of potential employers. These reviews provide a window into the company culture, work environment, and employee satisfaction, offering an authentic glimpse into what it’s like to work at the organization. When a company consistently receives positive feedback and high ratings, it signals to graduates that the organization is a trustworthy and desirable place to build a career. This transparency not only enhances the company's employer branding but also attracts top talent who are eager to join a reputable and supportive workplace. In essence, leveraging and promoting positive reviews helps to solidify a company’s image as a leading employer and fosters trust among prospective employees.
                </p>
              </div>
            </div>
          </div>
        </LeftContent>

        {/* Right side content */}
        <RightContent style={{ padding: '4vw', marginTop: '6vh', marginBottom: '5vh' }}>
          <img src={keywordsboard} alt="Social Board" style={{ marginLeft: '-11%', width: '110%', height: 'auto' }} />
        </RightContent>
      </CardContainerwhite>




  
    </>
  );
};

export default Usecases;
