import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import YourFormComponent from './CalendlyWidget.js';




const getHyperlink = (index) => {
    switch (index) {
        case 0:
            return 'https://www.figma.com/';
        case 1:
            return 'https://firebase.google.com/';
        case 2:
            return 'https://cloud.google.com/';
        case 3:
            return 'https://nodejs.org/';
        case 4:
            return 'https://react.dev/';
        case 5: 
            return 'https://www.javascript.com/';
        default:
            return '#'; // Default case, you can set it to some fallback link or handle as needed
    }
};



const images = [
    '/assets/Bizcardai/figma.png',
    '/assets/Bizcardai/firestore.png',
    '/assets/Bizcardai/gcp.png',
    '/assets/Bizcardai/nodejs.png',
    '/assets/Bizcardai/react%20js.png',
    '/assets/Bizcardai/js1.png',
];



const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 5cm);
  background-color: #0000FF;
 
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const CardContainer = styled.div`
  background-color: white;
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between; // Ensure right content stays on the right
  }
`;

const CardContainer2 = styled.div`
background-color: white;
padding: ${({ padding }) => padding};
display: flex;
flex-direction: column;

@media (min-width: 600px) {
  flex-direction: row;
  justify-content: space-between; // Ensure right content stays on the right
}
`;

const LeftContent = styled.div`
  flex: 1;
  @media (min-width: 600px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const RightContent = styled.div`
  flex: 1;
  margin-top: 10px; // Add margin-top to create space between LeftContent and RightContent

  @media (max-width: 600px) {
    margin-top: 0; // Remove margin-top for smaller screens
  }
`;

const Smartscan2 = () => {
  const [padding, setPadding] = useState('20px');
  

  useEffect(() => {
    const handleResize = () => {
      const screenWidthCm = window.innerWidth / window.devicePixelRatio / 2.54;
      if (screenWidthCm < 10) {
        setPadding('20px');
      } else if (screenWidthCm >= 10 && screenWidthCm <= 20) {
        setPadding('60px');
      } else {
        setPadding('80px');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
          <MainContainer>
              <ContentContainer>
                  <h1 style={{ fontSize: '1.2rem', marginTop: '80px', marginBottom: '8px', color: 'white', fontWeight: 'bold', letterSpacing: '2px' }}>
                      Bizcard Ai
                  </h1>
                  <p style={{ fontSize: '2.9rem', color: 'white', fontWeight: 300 }}>
                      Business cards to Salesforce within seconds
                  </p>
                  <img src="./assets/Bizcardai/Bizcardai2.png" alt="Card Background" style={{ width: '80%', height: 'auto', borderRadius: '8px' }} />
              </ContentContainer>
          </MainContainer>


      {/* Card 1 */}
      <CardContainer padding={padding}>
        <div style={{ justifyContent: 'space-between' }}>
          <h1 style={{ color: '#000', fontSize: '1.5rem', fontWeight: 330 }}>
            [SAAS Name] is specifically crafted to address the challenges faced by sales representatives in managing and utilizing business cards effectively. This innovative solution streamlines the entire process, offering a seamless and efficient way to capture, organize, and leverage valuable contact information.
          </h1>
        </div>
      </CardContainer>

      {/* Card 2 */}
      <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Industry
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Advertising and Marketing
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Project Duration
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            4 months
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Location
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Sweden
          </p>
        </LeftContent>

        {/* Right side content */}
        <RightContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Problem
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Businesscard management isn't easy
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          Sales reps struggle with business card management, hindering networking and lead generation. [SAAS Name] offers a solution by streamlining the process, making it efficient and seamless. The platform enables easy capture, organization, and leverage of crucial contact information. This innovation enhances sales reps' productivity and success by overcoming current challenges. [SAAS Name] ultimately facilitates effective relationship-building and lead generation.
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Solution
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Seamless business card management: OCR, integration, analytics for networking efficiency.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          [SAAS Name] employs OCR technology for precise business card contact detail extraction, facilitating an organized and customizable database. Seamless integration with CRM systems ensures a continuous data flow, complemented by real-time updates and mobile accessibility. Emphasizing security, the system follows industry-standard encryption and undergoes regular audits. [SAAS Name] streamlines business card management, providing valuable networking insights through analytics to empower sales reps in building and nurturing client relationships.
          </p>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Results
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Scanner for businesscards 
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '10px' }}>
            The business card scanner yields efficient and precise results in extracting contact details. Its seamless integration with CRM systems ensures swift data transfer, complemented by real-time updates. The mobile accessibility of the scanner enhances on-the-go usage. Industry-standard encryption guarantees secure handling of sensitive information. Overall, the business card scanner delivers streamlined results for efficient business card management.
          </p>
        </RightContent>
      </CardContainer>

      {/* Card 3 */}
      <CardContainer padding={padding} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {images.map((image, index) => (
          <a key={index} href={getHyperlink(index)} target="_blank" rel="noopener noreferrer">
            <img
              src={image}
              alt={`Bizcard ${index + 1}`}
              style={{
                width: '200px',
                height: '150px',
                objectFit: 'contain',
                background: '#f0f0f0',
                padding: '10px',
                margin: '10px',
                borderRadius: '8px', // Optional: Add border radius for rounded corners
              }}
            />
          </a>
        ))}
      </CardContainer>





      {/* Card 4 */}

      <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Add your image here */}
          <img
            src={'./assets/Bizcardai/Bizcard6.png'}
            alt=""
            style={{ width: '190%', height: 'auto', borderRadius: '8px' }}
          />
        </LeftContent>

        {/* Right side content */}
        <RightContent style={{ marginTop:'190px'}}>
          <h2 style={{ marginBottom: '10px' }}>
            <span style={{ color: '#989a98', fontSize: '2.5rem', fontWeight: '400', marginRight: '6px' }}>01</span>
            <span style={{ color: 'black', fontSize: '2.3rem', fontWeight: '600', letterSpacing: '2px' }}>Challenges</span>
          </h2>


          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            Time Efficiency and Accuracy
          </p>



          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
            The business card scanner streamlines contact management by efficiently extracting details, eliminating manual data entry, and saving time for more strategic tasks. It offers a precise solution, reducing errors associated with manual typing or copying from business cards. This efficiency enhances productivity and allows employees to focus on strategic activities.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            CRM Integration and Data Transfer Speed
          </p>



          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>

            The business card scanner seamlessly integrates with CRM systems, solving the challenge of manual data transfer and reducing delays and errors associated with traditional methods. Its swift data transfer and real-time updates ensure prompt and accurate reflection of contact information in the CRM system, enhancing overall efficiency in customer relationship management.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            On-the-Go Accessibility
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '5px' }}>
            The scanner's mobile accessibility tackles the challenge of managing contacts in a fast-paced environment, offering immediate access to crucial details. With on-the-go usage, users can capture and store business card information directly from their mobile devices, ensuring availability anywhere. This convenience enhances networking and creates opportunities for improved business connections.
          </p>

        </RightContent>
      </CardContainer>


      {/* Card 5 */}
      <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent style={{ marginTop: '30px', position: 'relative' }}>
          <h2 style={{ marginBottom: '30px' }}>
            <span style={{ color: '#989a98', fontSize: '2.5rem', fontWeight: '400', marginRight: '6px' }}>02</span>
            <span style={{ color: 'black', fontSize: '2.3rem', fontWeight: '600', letterSpacing: '2px' }}>Workflow</span>
          </h2>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, display: 'flex', alignItems: 'center', marginBottom: '40px' }}>
            <span style={{
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}>
             <img
                src="/assets/icons/lightbulb.png"  // Update the path accordingly
                alt="Lightbulb Icon"
                style={{ width: '70%', height: '70%', objectFit: 'contain', filter: 'brightness(0) invert(1)' }}
            />
        </span>
            Product Scope
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
          We directed our attention to the business vision, constructing a comprehensive project brief by synthesizing research on the market, competitor benchmarks, and other pertinent information. Subsequently, in a collaborative session with the client, we developed a compelling value proposition and identified key assumptions to be validated. Finally, we compiled an exhaustive list of user stories and essential features required to substantiate those key assumptions within the MVP.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, display: 'flex', alignItems: 'center', marginBottom: '40px' }}>
            <span style={{
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}>
             <img
                src="/assets/icons/scope.png"  // Update the path accordingly
                alt="Lightbulb Icon"
                style={{ width: '65%', height: '65%', objectFit: 'contain', filter: 'brightness(0) invert(1)' }}
            />
            </span>
            Product Logic & Technical Scope
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
          Following an in-depth session involving all stakeholders, we gathered the requisite information to formulate a comprehensive list of User Stories and features essential for validating the primary assumptions in a Proof of Concept (POC). Our experts in Product, UX/UI, and Tech Architecture diligently worked to address all inquiries that arose during the preparation of the project scope.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, display: 'flex', alignItems: 'center', marginBottom: '40px' }}>
            <span style={{
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}>
              <img
                src="/assets/icons/Uxui.png"  // Update the path accordingly
                alt="Lightbulb Icon"
                style={{ width: '65%', height: '65%', objectFit: 'contain', filter: 'brightness(0) invert(1)' }}
            />
            </span>
            Interactive Prototype
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
          We created a non-functional prototype to mimic user interaction. The experience with the clickable prototype closely resembled that of the final product, making it an appropriate phase for testing the information architecture, assessing the user experience (UX), and, most importantly, presenting a tangible outcome to all involved stakeholders.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, display: 'flex', alignItems: 'center', marginBottom: '40px' }}>
            <span style={{
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}>
              <img
                src="/assets/icons/prototype.png"  // Update the path accordingly
                alt="Lightbulb Icon"
                style={{ width: '65%', height: '65%', objectFit: 'contain', filter: 'brightness(0) invert(1)' }}
            />
            </span>
            Final Product Development 
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
          Having concluded decisions in the preceding steps, we proceeded to advance product development through agile cycles.
          </p>
         
        </LeftContent>


        {/* Right side content */}
        <RightContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Add your image here */}
          <img
            src={'./assets/Bizcardai/Bizcard1.png'}
            alt=""
            style={{ width: '180%', height: 'auto', borderRadius: '8px' }}
          />

        </RightContent>
      </CardContainer>


  {/* Card 6 */}

      <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Add your image here */}
          <img
            src={'./assets/Bizcardai/Bizcard12.png'}
            alt=""
            style={{ width: '180%', height: 'auto', borderRadius: '8px'}}
          />
        </LeftContent>


        {/* Right side content */}
        <RightContent style={{ marginTop:'10px'}}>
          <h2 style={{ marginBottom: '10px' }}>
            <span style={{ color: '#989a98', fontSize: '2.5rem', fontWeight: '400', marginRight: '6px' }}>03</span>
            <span style={{ color: 'black', fontSize: '2.3rem', fontWeight: '600', letterSpacing: '2px' }}>Challenges</span>
          </h2>


          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            Time Efficiency and Accuracy
          </p>



          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>
            The business card scanner streamlines contact management by efficiently extracting details, eliminating manual data entry, and saving time for more strategic tasks. It offers a precise solution, reducing errors associated with manual typing or copying from business cards. This efficiency enhances productivity and allows employees to focus on strategic activities.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            CRM Integration and Data Transfer Speed
          </p>



          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '40px' }}>

            The business card scanner seamlessly integrates with CRM systems, solving the challenge of manual data transfer and reducing delays and errors associated with traditional methods. Its swift data transfer and real-time updates ensure prompt and accurate reflection of contact information in the CRM system, enhancing overall efficiency in customer relationship management.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginLeft: '60px', marginBottom: '40px', position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '-50px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: '#0000FF',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <span style={{ color: 'white', fontSize: '1.2rem', fontWeight: '10' }}>➜</span>

            </span>
            On-the-Go Accessibility
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', marginLeft: '60px', fontWeight: 200, marginBottom: '5px' }}>
            The scanner's mobile accessibility tackles the challenge of managing contacts in a fast-paced environment, offering immediate access to crucial details. With on-the-go usage, users can capture and store business card information directly from their mobile devices, ensuring availability anywhere. This convenience enhances networking and creates opportunities for improved business connections.
          </p>

        </RightContent>
      </CardContainer>


      {/* Card 7 */}
      <CardContainer style={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }}>
        <h2 style={{ marginBottom: '20px', color: 'black', fontSize: '2.3rem', fontWeight: '600', letterSpacing: '2px' }}>
          Reach out to us, and let's propel your project forward!
        </h2>
      </CardContainer>

    
      <CardContainer2 >
        {/* Left side content */}
        <LeftContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Add your image here */}
          <img
            src={'./assets/Bizcardai/Bizcard12.png'}
            alt=""
            style={{ width: '180%', height: 'auto', borderRadius: '8px'}}
          />
        </LeftContent>
   

       {/* Right side content */}
  
      <RightContent style={{ marginTop: '10px' }}>
         
          <div style={{ marginLeft: '60px' }}>
            <YourFormComponent />
          
          </div>
        </RightContent>
      </CardContainer2>

      <div>
    
    
  </div>
       {/* Card 8 */}
       <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Industry
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Advertising and Marketing
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Project Duration
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            4 months
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Location
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Sweden
          </p>
        </LeftContent>

        {/* Right side content */}
        <RightContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Problem
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Businesscard management isn't easy
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          Sales reps struggle with business card management, hindering networking and lead generation. [SAAS Name] offers a solution by streamlining the process, making it efficient and seamless. The platform enables easy capture, organization, and leverage of crucial contact information. This innovation enhances sales reps' productivity and success by overcoming current challenges. [SAAS Name] ultimately facilitates effective relationship-building and lead generation.
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Solution
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Seamless business card management: OCR, integration, analytics for networking efficiency.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          [SAAS Name] employs OCR technology for precise business card contact detail extraction, facilitating an organized and customizable database. Seamless integration with CRM systems ensures a continuous data flow, complemented by real-time updates and mobile accessibility. Emphasizing security, the system follows industry-standard encryption and undergoes regular audits. [SAAS Name] streamlines business card management, providing valuable networking insights through analytics to empower sales reps in building and nurturing client relationships.
          </p>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Results
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Scanner for businesscards 
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '10px' }}>
            The business card scanner yields efficient and precise results in extracting contact details. Its seamless integration with CRM systems ensures swift data transfer, complemented by real-time updates. The mobile accessibility of the scanner enhances on-the-go usage. Industry-standard encryption guarantees secure handling of sensitive information. Overall, the business card scanner delivers streamlined results for efficient business card management.
          </p>
        </RightContent>
      </CardContainer>

       {/* Card 9 */}
       <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Industry
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Advertising and Marketing
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Project Duration
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            4 months
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Location
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Sweden
          </p>
        </LeftContent>

        {/* Right side content */}
        <RightContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Problem
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Businesscard management isn't easy
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          Sales reps struggle with business card management, hindering networking and lead generation. [SAAS Name] offers a solution by streamlining the process, making it efficient and seamless. The platform enables easy capture, organization, and leverage of crucial contact information. This innovation enhances sales reps' productivity and success by overcoming current challenges. [SAAS Name] ultimately facilitates effective relationship-building and lead generation.
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Solution
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Seamless business card management: OCR, integration, analytics for networking efficiency.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
          [SAAS Name] employs OCR technology for precise business card contact detail extraction, facilitating an organized and customizable database. Seamless integration with CRM systems ensures a continuous data flow, complemented by real-time updates and mobile accessibility. Emphasizing security, the system follows industry-standard encryption and undergoes regular audits. [SAAS Name] streamlines business card management, providing valuable networking insights through analytics to empower sales reps in building and nurturing client relationships.
          </p>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Results
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Scanner for businesscards 
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '10px' }}>
            The business card scanner yields efficient and precise results in extracting contact details. Its seamless integration with CRM systems ensures swift data transfer, complemented by real-time updates. The mobile accessibility of the scanner enhances on-the-go usage. Industry-standard encryption guarantees secure handling of sensitive information. Overall, the business card scanner delivers streamlined results for efficient business card management.
          </p>
        </RightContent>
      </CardContainer>

      {/* Card 10 */}
      <CardContainer padding={padding}>
        {/* Left side content */}
        <LeftContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Industry
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Advertising and Marketing
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Project Duration
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            4 months
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Location
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '0.8rem', fontWeight: 600, marginBottom: '40px' }}>
            Sweden
          </p>
        </LeftContent>

        {/* Right side content */}
        <RightContent>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Problem
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Businesscard management isn't easy
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
            Sales reps struggle with business card management, hindering networking and lead generation. [SAAS Name] offers a solution by streamlining the process, making it efficient and seamless. The platform enables easy capture, organization, and leverage of crucial contact information. This innovation enhances sales reps' productivity and success by overcoming current challenges. [SAAS Name] ultimately facilitates effective relationship-building and lead generation.
          </p>

          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Solution
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Seamless business card management: OCR, integration, analytics for networking efficiency.
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '40px' }}>
            [SAAS Name] employs OCR technology for precise business card contact detail extraction, facilitating an organized and customizable database. Seamless integration with CRM systems ensures a continuous data flow, complemented by real-time updates and mobile accessibility. Emphasizing security, the system follows industry-standard encryption and undergoes regular audits. [SAAS Name] streamlines business card management, providing valuable networking insights through analytics to empower sales reps in building and nurturing client relationships.
          </p>
          <h2 style={{ color: '#989a98', letterSpacing: '1px', textTransform: 'uppercase', fontSize: '0.8rem', fontWeight: 'bold' }}>
            Results
          </h2>
          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '1.2rem', fontWeight: 600, marginBottom: '40px' }}>
            Scanner for businesscards
          </p>

          <p style={{ color: 'black', letterSpacing: '1px', fontSize: '.9rem', fontWeight: 200, marginBottom: '10px' }}>
            The business card scanner yields efficient and precise results in extracting contact details. Its seamless integration with CRM systems ensures swift data transfer, complemented by real-time updates. The mobile accessibility of the scanner enhances on-the-go usage. Industry-standard encryption guarantees secure handling of sensitive information. Overall, the business card scanner delivers streamlined results for efficient business card management.
          </p>
        </RightContent>
      </CardContainer>


    </>
  );
};

export default Smartscan2;