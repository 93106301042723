import React, { useState, useEffect } from 'react';
import { db, imageDb } from '../../../../../../../firebaseconfig.js';
import Picture from './picture.png';
import Placeholder from './placeholder.png';
import './background2.scss';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";

const BackgroundImage1 = ({ isVisible, username, handleBackgroundImageChange }) => {
    const [showContents, setShowContents] = useState(false);
    const [userID, setUserID] = useState(null);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const [backgroundImageBase64, setBackgroundImageBase64] = useState(null); // State for base64 image data

    useEffect(() => {
        const fetchUserID = async () => {
            const usersRef = collection(db, 'Users');
            const q = query(usersRef, where("username", "==", username));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setUserID(doc.id);
            });
        };
        fetchUserID();
    }, [username]);

    useEffect(() => {
        const fetchBackgroundImage = async () => {
            if (userID) {
                const imgRef = ref(imageDb, `Files/Background/${userID}/${userID}`);
                try {
                    const imageUrl = await getDownloadURL(imgRef);
                    setBackgroundImageUrl(imageUrl);
                    localStorage.setItem('backgroundImageUrl', imageUrl); // Save to localStorage
                } catch (error) {
                    console.error("Error fetching background image:", error);
                    setBackgroundImageUrl(null); // or set default image
                }
            }
        };
        fetchBackgroundImage();
    }, [userID]);

    // Refactored useEffect to address eslint warning
    const localStorageBackgroundImageBase64 = localStorage.getItem('backgroundImageBase64');

    useEffect(() => {
        if (localStorageBackgroundImageBase64) {
            setBackgroundImageBase64(localStorageBackgroundImageBase64);
        }
    }, [localStorageBackgroundImageBase64]);

    const handleInputChange = async (e) => {
        setShowContents(false);
        const file = e.target.files[0];
        if (userID && file) {
            const reader = new FileReader();

            // Upload image to Firebase Storage
            const storageRef = ref(imageDb, `Files/Background/${userID}/${userID}`);
            try {
                await uploadBytes(storageRef, file);
                console.log('File uploaded successfully.');

               
                await updateDoc(doc(db, 'Users', userID), {
                    backgroundImage: `Files/Background/${userID}/${userID}`
                });

                // Read file as data URL to get base64 data
                reader.onloadend = async () => {
                    const base64data = reader.result;
                    const imageDataUrl = `data:${file.type};base64,${base64data.split(',')[1]}`;
                    localStorage.setItem('backgroundImageBase64', imageDataUrl);
                    setBackgroundImageBase64(imageDataUrl);
                    handleBackgroundImageChange(e);
                };
                reader.readAsDataURL(file);
            } catch (error) {
                console.error('Error uploading file or updating Firestore:', error);
            }
        }
    };


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const handleIconClick = () => {
        setShowContents(true);
    };

    const handleCloseButtonClick = () => {
        setShowContents(false);
    };

    return (
        <div>
            {isVisible && (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img
                            src={Picture}
                            alt="Icon"
                            className="flexpopdeckimage"
                            onClick={handleIconClick}
                        />
                        <div className="flexpoplabel">Background</div>
                    </div>
                </div>
            )}

            {showContents && (
                <div className="popupback2">
                    <div style={{ display: 'flex', marginLeft: '40vw', marginTop: '4vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className="flexpopbackimage"
                            />
                            <div className="flexpopbacklabel">Background</div>
                        </div>
                    </div>
                    <div className="inputback" onClick={triggerFileInput}>
                        <input type="file" onChange={handleInputChange} accept="image/*" id="fileInput" style={{ display: 'none' }} />
                        Choose File
                    </div>
                    <button className="popupbackclose-button2" onClick={handleCloseButtonClick}>Close</button>
                </div>
            )}

            {!isVisible && (
                <img
                    src={backgroundImageBase64 || backgroundImageUrl || Placeholder}
                    alt=""
                    className="background"
                />
            )}
        </div>
    );
};

export default BackgroundImage1;
