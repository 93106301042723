import React, { useState} from 'react';
import Picture from './Contactcard1.png'; // Import your picture
//import './background5.css'; // Import the CSS file
import './background2.scss'; // Import the CSS file
import { contactDataURL } from '../../../../../../../routes.mjs';
import { getcontactDataURL } from '../../../../../../../routes.mjs';
//import Details1 from '../details/details1.js';
import KeywordGenerator from './KeywordGenerator';

const ContactCard1 = ({ username,  contactcardImage, handleContactAdd, isVisible, flexdeck , flexdeckimage, flexlabel, flexpopdeckimage, flexpoplabel, flexpoptopimage, flexpoptoplabel,  popupcontent51, popup51, showContents,setShowContents  }) => {
    
    const [name, setName] = useState('Name');
    const [email2, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('Title');
    const [company, setCompany] = useState('Company');
    const [address, setAddress] = useState('');
    const [web, setWeb] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [bio, setBio] = useState('');

    const getFunction = async (username) => {
        try {
            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);

            // Construct the URL with the username as a parameter
            const url = `${getcontactDataURL}?username=${encodeURIComponent(username)}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'contact': 'true'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const jsonResponse = await response.json();
            const name = jsonResponse.name;
            const email2 = jsonResponse.email2;
            const phone = jsonResponse.phone;
            const title = jsonResponse.title;
            const company = jsonResponse.company;
            const bio = jsonResponse.bio;
            const web = jsonResponse.web;
            const whatsapp = jsonResponse.whatsapp;
            const address = jsonResponse.address;
            //console.log("name:", name);
            //console.log("email2:", email2);
            //console.log("phone:", phone);
            return { name, email2, phone, title, company, bio, whatsapp, web, address };
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
            return null; // Or throw the error further
        }
    };

    
   
    const handleIconClick = async () => {
      
           
           
        setShowContents(true);
       
        const contactData = localStorage.getItem('contactData');
        if (contactData) {
            const contactDataJSON = JSON.parse(contactData);
            setName(contactDataJSON.name);
            setEmail(contactDataJSON.email2);
            setPhone(contactDataJSON.phone);
            setTitle(contactDataJSON.title);
            setCompany(contactDataJSON.company);
            setBio(contactDataJSON.bio);
            setWeb(contactDataJSON.web);
            setAddress(contactDataJSON.address);
            setWhatsapp(contactDataJSON.whatsapp);
        }
    };

    const handleCloseButtonClick = () => {
        setShowContents(false);
    };


    const handleAddButtonClick = async () => {
        // Pass the entered values to the parent component
        const contactData = await getFunction(username);
       
        handleContactAdd(contactData);

          // Add the keywords array to the bio field
  const bio2 = JSON.parse(localStorage.getItem('keywords'));
  console.log(bio2);
  
        
        addFunction(username, name, email2, phone,title, company, bio2, whatsapp, web,address )
        .then(async () => {
            // Update local storage after addFunction has completed
            const updatedContactData = await getFunction(username);
            localStorage.setItem('contactData', JSON.stringify(updatedContactData));
        })
        .catch((error) => {
            console.error("Error updating local storage: ", error);
        });


        setShowContents(false);
    };
    
    

    const handleDeleteButtonClick = async () => {
        // Reset all state values to null
        setName('');
        setEmail('');
        setPhone('');
        setTitle('');
        setCompany('');
        setBio('');
        setWeb('');
        setWhatsapp('');
        setAddress('');

       
    
        
        // Call addFunction with null values to delete the contact data
        await addFunction(username, null, null, null, null, null, null, null, null, null);
       
      
        // Update contactCardImage based on whether the contact data exists after deletion
       

        // Remove the contact data from local storage
       localStorage.removeItem('contactData');
    
        setShowContents(false);
    };
    

    

    const addFunction = async (username, name, email2, phone, title, company, bio, whatsapp, web, address) => {
       
         
       
        try {
            //console.log(`Adding Contact Data: ${name}${email2}${phone}`);

            if (!username) {
                //console.log("User is not logged in.");
                return;
            }

            //console.log(`Logged in user: ${username}`);

            const response = await fetch(contactDataURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'contact': 'true'
                },
                body: JSON.stringify({ username, name, email2, phone, title, company, bio , whatsapp, web, address}),
            });

            const responseData = await response.json();
            console.log('Response from server:', responseData);
        } catch (error) {
            console.error('An error occurred:', error);
            // Handle the error here
        }
    };

    // Function to update bio state based on input text
     // Function to update bio state based on input text
     const handleInputBlur = (e) => {
        const text = e.currentTarget.textContent.trim();
        const bioArray = text.split(/\s+/).filter(item => item !== '');  // Split by whitespace and filter out empty items
        setBio(bioArray);
    };

    const renderBioElements = () => {
        let savedKeywords;
      
        // Check if the item exists in localStorage and is not "undefined"
        const storedKeywords = localStorage.getItem('keywords');
        if (storedKeywords && storedKeywords !== "undefined") {
          try {
            savedKeywords = JSON.parse(storedKeywords);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            savedKeywords = null;
          }
        }
      
        // Return early if bio is not defined and there are no valid saved keywords
        if (!bio) {
          if (!savedKeywords || savedKeywords.length === 0) {
            return null;
          }
      
          // Render saved keywords if they exist
          return (
            <div className="bio-container">
              {savedKeywords.map((element, index) => (
                <div key={index} className="span" contentEditable={false}>
                  {element}
                </div>
              ))}
            </div>
          );
        }
      
        // Render bio elements
        return (
          <div className="bio-container">
            {bio.map((element, index) => (
              <div key={index} className="span" contentEditable={false}>
                {element}
              </div>
            ))}
          </div>
        );
      };
      
      
    
    
    
    return (
        <div>
            {/* Conditionally render the icon based on the isVisible prop */}
            {isVisible  && (
               <div style={{ display: 'flex'}}>
               <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                   <img
                       src={Picture}
                       alt="Icon"
                       className=  {flexpopdeckimage}
                       onClick={handleIconClick}
                   />
                   <div className={flexpoplabel}>Contact</div>
               </div>
           </div>
           
            )}

            {!isVisible && contactcardImage && (
                <div className=  {flexdeck}>
                <img
                    src={Picture}
                    alt="Icon"
                    className=  {flexdeckimage}

                    onClick={handleIconClick}
                />
                <div className={flexlabel}  >Contact</div>

            </div>
            )}




            {/* Conditionally render the input fields and add button based on visibility */}
            {isVisible && showContents && (
                 <div className="popup212">
                  
                   
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                            <img
                                src={Picture}
                                alt="Icon"
                                className="flexpoptopcontactimage"

                            />
                            <div className="flexpoptopcontactlabel">Contact Card</div>
                        </div>
                

                        <div className="popup-content2">
                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label className="custom-placeholder">Name</label>
                        </div>

                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <label className="custom-placeholder">Title</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                            <label className="custom-placeholder">Company</label>
                        </div>

                       
                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={email2}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label className="custom-placeholder">Email</label>
                        </div>
                         <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="tel"
                                placeholder=""
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <label className="custom-placeholder">Phone</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="tel"
                                placeholder=""
                                value={whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                            />
                            <label className="custom-placeholder">Whatsapp</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={web}
                                onChange={(e) => setWeb(e.target.value)}
                            />
                            <label className="custom-placeholder">Web</label>
                        </div>

                        <div className="custom-input-container">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <label className="custom-placeholder">Address</label>
                                           
                        </div>

                        <div className="custom-input-container">
                        
               
                        <KeywordGenerator text={bio} />
          
                       
                          </div> 
                        </div> 
                        <div className="actionbar">
                           <button className="popupadd-button2"  onClick={() => handleAddButtonClick(name, email2, phone)}>Add</button>
                           <button className="popupdelete-button2" onClick={handleDeleteButtonClick}>Delete</button>
                           <button className="popupclose-button2" onClick={handleCloseButtonClick}>Close</button>
                        </div>
                    </div>
            )}

            { !isVisible && showContents && (


                 
                <div className={popup51} >
                      <div className='contactnotposi'>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <img
                                    src={Picture}
                                    alt="Icon"
                                    className='contactbuttonnot'
                                    onClick={handleIconClick}
                                />
                                <div   className='noticonlabel'>Contact Card</div>
                            </div>
                        </div>
               
                    <div className={popupcontent51} >
                      
                        
                        <div >
                       
                       
                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Name</label>
                        </div>

                         <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Title</label>
                        </div>

                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Company</label>
                        </div>
                            <div className="custom-input-container2">
                                <div
                                    className="inputkey"
                                    contentEditable="true"

                                    onBlur={handleInputBlur}
                                >
                                    {/* Display bio elements with dynamically adjusted grid layout */}
                                    {renderBioElements()}
                                </div>
                                <label className="key-placeholder">Keywords</label>
                            </div>


                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={email2}
                                onChange={(e) => setEmail(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Email</label>
                        </div>
                         <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="tel"
                                placeholder=""
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Phone</label>
                        </div>

                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="tel"
                                placeholder=""
                                value={whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Whatsapp</label>
                        </div>

                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                                type="text"
                                placeholder=""
                                value={web}
                                onChange={(e) => setWeb(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Web</label>
                        </div>

                        <div className="custom-input-container2">
                            <input
                                className="custom-input"
                               type="text"
                                placeholder=""
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                readOnly
                            />
                            <label className="custom-placeholder">Address</label>
                                           
                        </div>
                        </div>
                        {/*<button onClick={() => handleAddButtonClick(name, email2, phone)}>Add</button>
                        <button onClick={handleDeleteButtonClick}>Delete</button>*/}
                        
                    </div>
                    <button className="popupclose-button51" onClick={handleCloseButtonClick}>Close</button>
                </div>
              
            )}
  
        </div>
    );
};

export default ContactCard1;