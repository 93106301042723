import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Bookme = () => {
  const formRef = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        'service_cyq0dv1',
        'template_lf19ycu',
        formRef.current,
        'olZH-fkewc9MLTuqA'
      );

      console.log(result.text);
    } catch (error) {
      console.error(error.text);
    }
  };

  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const inputStyles = {
    margin: '5px 0',
    padding: '8px',
    width: '100%',
  };

  return (
    <div style={formStyles}>
      <form ref={formRef} onSubmit={sendEmail} style={{ width: '300px' }}>
        <label>Client Name</label>
        <input type="text" name="client_name" style={inputStyles} />

        <label>Company Name</label>
        <input type="text" name="company_name" style={inputStyles} />

        <label>Project Description</label>
        <input type="text" name="project_description" style={inputStyles} />

        <label>Budget</label>
        <input type="text" name="budget" style={inputStyles} />

        <label>Name</label>
        <input type="text" name="name" style={inputStyles} />

        <label>Email</label>
        <input type="text" name="email" style={inputStyles} />

        <label>Here About</label>
        <input type="text" name="here_about" style={inputStyles} />

        <label>NDA (Yes/No)</label>
        <input type="text" name="nda_yes_or_no" style={inputStyles} />

        <label>Message</label>
        <textarea name="message" style={inputStyles} />

        <input type="submit" value="Send" style={inputStyles} />
      </form>
    </div>
  );
};

export default Bookme;
