import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../usercontext';
import { authURL } from '../routes.mjs';
//import { baseURLweb } from '../routes.mjs';
import logo512 from './logo512.png';
import goog from './goog.png';
import { collection, doc, setDoc, query, where, getDocs } from "firebase/firestore";
import { db } from '../firebaseconfig.js'; 
//import { googlesubscribeURL } from '../routes.mjs'; 
import { v4 as uuidv4 } from 'uuid';
import {getUsernameToLocalStorage} from './localstorage.js';


const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setUserName } = useUser();
  const navigate = useNavigate();
  
  //const [username, setUsername] = useState(''); // State variable to store username

  

  useEffect(() => {
    setError('');
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlelogin = async (e) =>{

    setLoading(true);

     // Check if either username or password is empty
  if (!formData.username || !formData.password) {
    setError('Please enter username and password');
    return;
  }

    try {
      const response = await fetch(authURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        handleSuccessfulLogin(formData.username);
      } else {
        setError(data.error || 'Login failed');
      }
    } catch (error) {
      //console.error('Error during login', error);
      setError('Login failed');
    } finally {
      setLoading(true);
    }

  };

  const handleSuccessfulLogin = useCallback((username) => {
    setError('');
    sessionStorage.setItem('username', username);
    setUserName(username);
    
    getUsernameToLocalStorage(username); // Save username to localStorage
    
  setTimeout(() => {
   
    navigate('/smartconsole1');
  }, 3000); // 10000 milliseconds = 5 seconds
 
  }, [navigate, setUserName]);

  const handleGoogleLogin = () => {
    const googleClientId = '614102256594-fbh6epolajbh468ldki2ueg1pd8sb10u.apps.googleusercontent.com';
    const googleAuthEndpoint = 'https://accounts.google.com/o/oauth2/auth';

    const redirectUri = 'https://www.smartcardai.com/Login1';

    const authUrl = `${googleAuthEndpoint}?client_id=${googleClientId}&redirect_uri=${redirectUri}&response_type=code&scope=email%20profile`;
    window.location.assign(authUrl);
  };

  const fetchUserID = useCallback(async (username) => {
    const usersRef = collection(db, 'Users');
    const q = query(usersRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      handleSuccessfulLogin(userData.username);
    } else {
      const newUUID = uuidv4();
      
      const newUserRef = doc(db, 'Users', newUUID);
      await setDoc(newUserRef, { username: username });

      const newSubscriptionRef = doc(db, 'Subscriptions', newUUID);
       // Get the current date
       const now = new Date();

       // Calculate the end date by adding 30 days (converted to hours)
       const daysToAdd = 30;
       const hoursToAdd = daysToAdd * 24;
       const endDate = new Date(now.getTime() + hoursToAdd * 60 * 60 * 1000);
 
       // Calculate the days left
       const daysLeft = Math.ceil((endDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
 
       // Set the start and end dates
       const subscription_start_date = now;
       const subscription_end_date = endDate;
      await setDoc(newSubscriptionRef, {
        start_date: subscription_start_date,
        end_date: subscription_end_date,
        days_left: daysLeft, // Add days_left to the subscription details
        plan: 'Free'
      });

      const newuserdataRef = doc(db, 'userdata', newUUID);
      await setDoc(newuserdataRef,   {
        usertype: 'admin',
        cardpermission: 'edit'
      });
   
      handleSuccessfulLogin(username);
    }
  }, [handleSuccessfulLogin]);
  

  useEffect(() => {
    const fetchGoogleUser = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
              code: code,

              client_id: '614102256594-fbh6epolajbh468ldki2ueg1pd8sb10u.apps.googleusercontent.com',
              client_secret: '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',
              redirect_uri: 'https://www.smartcardai.com/Login1',

              grant_type: 'authorization_code'
            })
          });

          if (!response.ok) {
            throw new Error(`Failed to exchange code for token: ${response.status} ${response.statusText}`);
          }
  
          const data = await response.json();
          const accessToken = data.access_token;
  
          // Fetch user info
          const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
  
          if (!userInfoResponse.ok) {
            throw new Error(`Failed to fetch user info: ${userInfoResponse.status} ${userInfoResponse.statusText}`);
          }
  
          const userInfo = await userInfoResponse.json();
          const userEmail = userInfo.email;
          const username = userEmail;
  
          setUserName(username);
          sessionStorage.setItem('username', username);
          fetchUserID(username);
  
         
        } catch (error) {
          console.error('Error during Google login:', error.message);
        }
      }
    };
  
    fetchGoogleUser();
  }, [setUserName, fetchUserID]);
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.username || !formData.password) {
      setError('Please enter both username and password');
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(authURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        handleSuccessfulLogin(formData.username);
      } else {
        setError(data.error || 'Login failed');
      }
    } catch (error) {
      //console.error('Error during login', error);
      setError('Login failed');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = () => {
   // Navigate to the Reset.js page
   navigate('/Reset1');
  };

  const handleCreateAccount = () => {
    // Define the action to create account here

    navigate('/Signup1');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '300px', padding: '20px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '5px', textAlign: 'center' }}>
        <div>
          <img src={logo512} alt="Logo" style={{ width: '90%' }} />
          
        </div>
        <p style={{ marginBottom: '20px', fontSize: '22px', textAlign: 'center' }}>Login</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your username"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            style={{ width: '90%', padding: '10px', marginBottom: '10px', border: 'none', borderBottom: '1px solid #ccc', backgroundColor: 'transparent', outline: 'none' }}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button onClick={handlelogin} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '60px', marginTop: '10px', padding: '5px', fontSize: '20px', backgroundColor: 'black', color: 'white', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
            {loading ? 'Logging in...' : 'Login'}
          </button>


        </form>
        <button onClick={handleGoogleLogin} style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px', padding: '10px',height: '60px',fontSize: '16px', backgroundColor: 'transparent', border: '1px solid #ccc', borderRadius: '40px', cursor: 'pointer' }}>
          <img src={goog} alt="Google Logo" style={{ width: '80px', marginLeft: '-15px', marginRight : '15px' }} />
          Sign in with Google
        </button>


        <div style={{ marginTop: '10px' }}>
          <button onClick={handleResetPassword} style={{ marginRight: '10px', backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Reset Password</button>
          <button onClick={handleCreateAccount} style={{ backgroundColor: 'transparent', border: 'none', color: 'black', cursor: 'pointer' }}>Create an Account</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
