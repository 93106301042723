// smartconsole1.js
import React, { useEffect, useState } from 'react';
import { useUser } from '../../../../usercontext';
import MyProfile2 from './myprofile/myprofile2';

function SmartConsole1() {
  const { username, loggedIn } = useUser();
  const [storedUsername, setStoredUsername] = useState('');

  useEffect(() => {
    // Check if username exists in session storage
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername) {
      setStoredUsername(storedUsername);
    } else {
      setStoredUsername(username); // Use default username if session storage is empty
    }
  }, [username]);

  // Update session storage whenever username changes
  useEffect(() => {
    if (username) {
      sessionStorage.setItem('username', username);


      
    }
  }, [username]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '100vh',
  };

  const contentStyle = {
    padding: '20px', // reduced padding for example
    overflowY: 'hidden',
    overflowX: 'hidden',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        {loggedIn && (
          <>
            <MyProfile2 username={storedUsername} />
          </>
        )}
      </div>
    </div>
  );
}

export default SmartConsole1;
