import React, { useState, useCallback, useEffect } from 'react';
import './keywordgen.scss'; // Import the CSS file
import { baseURL } from '../../../../../../../routes.mjs';

function KeywordGenerator( ) {
  const [inputText, setInputText] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  // Define a state variable to control the effect
const [fetchKeywords, setFetchKeywords] = useState(true);
const [loading, setLoading] = useState(false);


  const maxWordLimit = 30;




  // Function to calculate word count
  const calculateWordCount = useCallback((text) => {
    setFetchKeywords(true);
    const words = text.trim().split(/\s+/);
    return words.filter(word => word !== '').length;
  }, []);


  // Function to generate keywords from the input text
  const generateKeywords = (text) => {

    setFetchKeywords(true);
    // Split the text into keywords by spaces
    const words = text.split(' ');
    // Filter out empty strings
    const filteredWords = words.filter(word => word !== '').slice(0, maxWordLimit);

     
    // Remove verbs from the keywords
    const Keywords = filteredWords;
    return Keywords;
  };

// Event handler for input change
const handleInputChange = (e) => {
  setFetchKeywords(true);
  const text = e.target.value;
  const currentWordCount = calculateWordCount(text);
 
  if (currentWordCount <= maxWordLimit) {
    setInputText(text);
    const generatedKeywords = generateKeywords(text);
    setKeywords(generatedKeywords);
    setWordCount(currentWordCount);
  }
};


  // Function to remove a keyword
const removeKeyword = (indexToRemove) => {
  setKeywords(keywords.filter((keyword, index) => index !== indexToRemove));
  // Disable the effect after removing a keyword
  setFetchKeywords(false);
};

const handleSaveButtonClick = () => {
  setFetchKeywords(true);
  localStorage.setItem('keywords', JSON.stringify(keywords));
  
  
  setInputText(''); // Clear the input text
  setWordCount('0');
};

     // Function to handle the "Save" button click
     const handleRefreshButtonClick = () => {
      // Save keywords to local storage
      localStorage.setItem('keywords', JSON.stringify([]));
      setKeywords([]);
      setInputText(''); // Clear the input text
      setWordCount('0');
      setLoading(false);
    };


 
  

  // Effect hook to load keywords from local storage only if text area is blank
  useEffect(() => {
    if (inputText.trim() === '') {
      const savedKeywords = localStorage.getItem('keywords');
      if (savedKeywords) {
        try {
          setKeywords(JSON.parse(savedKeywords));
        } catch (error) {
          console.error("Error parsing saved keywords:", error);
          localStorage.removeItem('keywords'); // Clear corrupted data
        }
      }
    }
  }, [inputText]);
  


  // Function to make the GET request
  const handleButtonClick = useCallback(() => {
    setLoading(true);
    const prompt = `I need short keywords and I don't need long tail keywords from this sentence. Give me in field format so that I can use the response directly as keywords on the website. It should be headless: ${inputText}`;
  
    // Construct the URL with the text input as a query parameter
    const constructedUrl = `${baseURL}/keywords?prompt=${encodeURIComponent(prompt)}`;
  
    // Make the GET request
    fetch(constructedUrl, {
      method: 'GET', // Specify the request method
      mode: 'cors', // Enable CORS
    })
      .then(response => {
        // Check if the response is successful
        if (response.ok) {
          setLoading(false);
          // Parse the JSON response
          response.json().then(data => {
            // Extract keywords from data.keywords and split by ', '
            
            if (data.keywords) {
              const keywordsArray = data.keywords.split(', ').map(keyword => keyword.trim());
              setKeywords(keywordsArray);
              
            } else {
              console.error("No keywords found in response:", data);
            }
          });
        } else {
          // Handle any errors
          console.error("Error:", response.status);
        }
      })
      .catch(error => {
        // Handle any network errors
        console.error("Network Error:", error);
      });
  }, [inputText]);
  
  useEffect(() => {
    let timer = null;
    
    if (fetchKeywords) {
      timer = setInterval(() => {
      
      }, 10000);
    }
  
    return () => {
      clearInterval(timer);
    };
  }, );

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text/plain');
    const currentWordCount = calculateWordCount(pastedText);
    
    if (currentWordCount <= maxWordLimit) {
      const generatedKeywords = generateKeywords(pastedText);
      setKeywords(generatedKeywords);
      setFetchKeywords(true);
    }
  
    setInputText(pastedText);
    setWordCount(currentWordCount);
    setFetchKeywords(false);
  };

  return (
    <div className="maincontainer">
      
        {keywords.map((keyword, index) => (
          <div key={index} className="keyword">
            <span>{keyword}</span>
            <button onClick={() => removeKeyword(index)} className="remove">×</button>
          </div>
        ))}
     
      <div style={{ position: 'relative'}}>
        <textarea
          value={inputText}
          onPaste={handlePaste}
          onChange={handleInputChange}
          placeholder="Text to Keywords"
          className="textarea"
          spellcheck="false"
        />
         <div className="wordcount" style={{  color: wordCount >= maxWordLimit  ? 'red' : 'green' }}>{wordCount}/{maxWordLimit}</div>
        
      </div>

          {loading ? (
               <button
               className="loaderout"
               style={{
                 cursor: 'pointer',
                 pointerEvents: loading ? 'none' : 'auto'
               }}
             >
            <div className="loader"></div>
            </button>
          ) : (

            <button
            onClick={handleButtonClick}
            className="Aisuggestions"
            style={{
              
              cursor: 'pointer',
              pointerEvents: loading ? 'none' : 'auto'
            }}
          >
            Ai Suggestions
            </button>
          )}
        

        <button
          onClick={handleSaveButtonClick}
        
         className="save"
        >
         Save
        </button>

        <button onClick={handleRefreshButtonClick} className="refresh">&#x21bb;</button>
    </div>
  );
}

export default KeywordGenerator;
