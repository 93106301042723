

import Picture from './Whatsapp.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import React  from 'react';

const Whatsapp1 = ({ username, whatsappImage, flexdeck, flexdeckimage, flexlabel }) => {
  

  const handleImageClick = () => {
    const whatsappData = localStorage.getItem('contactData');
    const whatsappDataJSON = JSON.parse(whatsappData);

    // Determine the device type and open WhatsApp accordingly
    if (window.innerWidth <= '320px') {
      // For small mobile devices
      window.open(`whatsapp://send?phone=${whatsappDataJSON.whatsapp}`, '_blank');
    } else if (window.innerWidth <= '375px') {
      // For medium mobile devices
      window.open(`whatsapp://send?phone=${whatsappDataJSON.whatsapp}`, '_blank');
    } else if (window.innerWidth <= '825px') {
      // For large mobile devices
      window.open(`whatsapp://send?phone=${whatsappDataJSON.whatsapp}`, '_blank');
    } else if (window.innerWidth <= '1024px') {
      // For tablets
      window.open(`https://web.whatsapp.com/send?phone=${whatsappDataJSON.whatsapp}`, '_blank');
    } else {
      // For laptops
      window.open(`https://web.whatsapp.com/send?phone=${whatsappDataJSON.whatsapp}`, '_blank');
    }
  };

  return (
    <div>
      {/* Conditionally render the icon based on the isVisible prop */}
      {whatsappImage && (
        <div className={flexdeck} onClick={handleImageClick}>
          <img src={Picture} alt="Icon" className={flexdeckimage} />
          <div className={flexlabel}>Whatsapp</div>
        </div>
      )}
    </div>
  );
};

export default Whatsapp1;