import Picture from './Discord.png'; // Import your picture
import './background2.scss'; // Import the CSS file
import React from 'react';

const Discord1 = ({ username, discordImage,  flexdeck, flexdeckimage, flexlabel }) => {
  

  const handleImageClick = () => {
    const socialData = localStorage.getItem('socialData');
    const socialDataJSON = JSON.parse(socialData);
    
    if (socialDataJSON && socialDataJSON.discord) {
      let link = socialDataJSON.discord.trim(); // Ensure URL is trimmed of any whitespace
  
      // Check if the link starts with a valid protocol, otherwise assume http://
      if (!/^https?:\/\//i.test(link)) {
        link = 'http://' + link;
      }
  
      // Open the URL in a new tab
      window.open(link, '_blank');
    } else {
      // Handle case where web URL is not available
      console.error('Web URL is missing or invalid');
    }
  };

    
  return (
    <div>
      {/* Conditionally render the icon based on the isVisible prop */}
      { discordImage && (
         <div className={flexdeck} onClick={handleImageClick}>
        
          <img
            src={Picture}
            alt="Icon"
            className={flexdeckimage}
          />
          <div className={flexlabel}>Discord</div>
        </div>
      )}
    </div>
  );
};

export default Discord1;