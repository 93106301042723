// Localstorage.js

import { getcontactDataURL } from '../routes.mjs';

   
// Function to retrieve username from localStorage
export const getUsernameToLocalStorage = async (username) => {
    try {

        // Update local storage after getFunction has completed
        const updatedContactData = await getFunction(username);
        if (updatedContactData) {
            localStorage.setItem('contactData', JSON.stringify(updatedContactData));
            localStorage.setItem('keywords', JSON.stringify(updatedContactData.bio));
        } else {
            // Handle case where getFunction returns null (error occurred)
            console.error('Failed to fetch user data.');
        }
    } catch (error) {
        console.error('An error occurred:', error);
        // Handle the error here, e.g., show an error message to the user
    }
};

const getFunction = async (username) => {
    try {
        if (!username) {
            // Handle case where username is not provided
            throw new Error("Username is required.");
        }

        // Construct the URL with the username as a parameter
        const url = `${getcontactDataURL}?username=${encodeURIComponent(username)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'contact': 'true'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        const { name, email2, phone, title, company, bio, web, whatsapp, address } = jsonResponse;
        
        return { name, email2, phone, title, company, bio, web, whatsapp, address };
    } catch (error) {
        console.error('An error occurred:', error);
        // Handle the error here
        return null; // Or throw the error further
    }
};
